var VideoModel = Backbone.Model.extend({
  /**
     Sync the model data to the server.

     Note: overrides Backbone's default sync method.
   */
  sync: function(method, model, options) {
    var request;

    switch(method) {
    case 'create':
      request = this.createVideo();
      break;
    case 'update':
      request = this.editVideo();
      break;
    case 'delete':
      request = this.deleteVideo();
      break;
    default:
      request = $.Deferred();
      request.reject();
    }

    request.then(_.bind(this.loadResponseValues, this));

    return request;
  },

  /**
     Send a create album request to the server.
   */
  createVideo: function() {
    var data = this.assembleFormData();

    return $.ajax({
      type: "POST",
      url: '/videos/add_video/',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=UTF-8'
    });
  },

  /**
     Send an edit video request to the server.
   */
  editVideo: function() {
    var data = this.assembleFormData();

    return $.ajax({
      type: 'POST',
      url: '/api/videos/' + this.get('id') + '/',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=UTF-8'
    });
  },

  /**
     Assemble the form data into the object sent to server.
   */
  assembleFormData: function() {
    var data = {
      title: this.get('title'),
      description: this.get('description'),
      privacy: this.get('privacy'),
      block_tagging: this.get('block_tagging'),
      tags: this.get('tags'),
      videos: []
    };

    var fpfiles = this.get('fpfiles');

    if (fpfiles) {
      for (var i = 0; i < fpfiles.length; i++) {
        video = {};
        video["fpfile"] = JSON.stringify(fpfiles[i]);
        data['videos'].push(video);
      }
    }

    var photos = this.get('photos');

    if (photos != undefined) {
      data['photos'] = [];

      _.each(this.attributes.photos, function(photo) {
        data['photos'].push({
          id: photo.get('id'),
          album_photo_id: photo.get('album_photo_id'),
          caption: photo.get('caption'),
          number: photo.get('number'),
          isCover: photo.get('isCover'),
          delete: photo.get('delete'),
          fpfilekey: photo.get('key')
        });
      });
    }

    return data;
  },

  /**
     Load server sync response values back into the model.
   */
  loadResponseValues: function(data) {
    if (!data.id) {
      return;
    }

    this.set({id: data.id});
  },

  /**
     Delete the video on the server.
   */
  deleteVideo: function() {
    return $.ajax({
      type: 'POST',
      url: '/videos/' + this.get('id') + '/delete/'
    });
  }
});
