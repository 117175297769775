var CreateCommentView = EditCommentView.extend({
  events: {
    'focus .comment:not(.testimonial)': 'focusEvent',
    'focusout .comment:not(.testimonial)': 'blurEvent'
  },

  spinnerOnSave: true,

  /**
     Initialize the view.
   */
  initialize: function(options) {
    EditCommentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(CreateCommentView);
  },

  /**
     User clicks on the textarea to start making a new thread.
   */
  focusEvent: function(e) {
    e.preventDefault();
    this.activate();
  },

  /**
     Blur event when the user clicks outside of the editor.
   */
  blurEvent: function() {
    if (!this.editor.getText()) {
      this.deactivate();
    }
    SG.reLayout();
  },

  /**
     Deactivates the comments text box, returning it to its default state.
   */
  deactivate: function () {
    this.$el.removeClass('active');
    this.$el.find('.comment-count').text(this.$el.data('commentCount'));
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function (response, textStatus, jqXHR) {
    var $newComment = $(response).addClass('collapsed');
    var $contentBox = this.$el.closest('.content-box');
    var $commentCounts = $contentBox.find('.comment-count');
    var $viewing = $contentBox.find('.viewing-count');
    var remainderCount = parseInt($viewing.text());
    var viewingCount = parseInt($viewing.attr("data-viewing-count"));
    var commentCount = parseInt($commentCounts.data('commentCount'));

    commentCount++;
    viewingCount++;
    remainderCount++;

    this.$el.siblings('.comments').append($newComment);

    setTimeout(function() {
      $newComment.addClass('uncollapsed');
    }, 0);

    this.$el.find('input').val('');
    this.$el.find('[contenteditable]').text('');

    SG.reLayout();

    $commentCounts.text(commentCount).data('commentCount', commentCount);
    $viewing.attr("data-viewing-count", viewingCount);

    if (commentCount == remainderCount) {
      $viewing.text(remainderCount);
    }

    this.$el.siblings('.comments').removeClass('no-comments');
    this.$el.find('.comment').blur();

    SG.createTextLinks($newComment.find('div.comment-text')); //at mentions and urls to links

    this.deactivate(this.$el);
    this.$el.find('textarea').redactor('destroy');
    $('.redactor_comment').empty();
    this.$el.find('textarea').val('');
    this.$el.find('.progress').removeClass('visible');
    this.updateGroupMemberUI();
  },

  /**
     Updates the UI to show that the user is now a member of the group,
     if they have posted to a public group they are not a member of.
   */
  updateGroupMemberUI: function() {
    var $joinButton = $('body.group-single .hero-subheader a.join-group.call-to-action:visible');
    if ($joinButton.length) {
      $joinButton.hide();
      $('body.group-single .hero-subheader a.leave-group').show();
      var $membersCount = $('body.group-single .hero-subheader .hero-subheader-title p > span.highlight > a');
      $membersCount.html(parseInt($membersCount.html())+1);
    }
  }
});
