/*global $ */
/*global _ */
/*global console */

var SG = SG || {};

/**
 Handles loading comments when the select comments to show pulldown is changed.
*/
SG.LoadComments = {
    /**
     Binds the event handlers for the select comments to show pulldown.
     */
    bindEvents: function () {
        $('.article-feed .content-box.group.thread .horizontal-nav .option').on('click', _.bind(this.loadEvent, this));
    },

    /**
     Event handler when the user changes the comments to show selection.

     @param {object} e - JQuery event data.
     */
    loadEvent: function (e) {
        console.log('load the comments');
	e.preventDefault();

        var url = $(e.target).attr('href');
        var comments_container = $(e.target).parent().parent().parent().parent().parent().parent().parent().find('.comments');

	$('body').spinner(SG.spinnerSettings);
        $('#shield').data('auto-init', false);
        comments_container.data('auto-init', false);
        comments_container.load(url, _.bind(this.commentsLoaded, this));
    },

    /**
     Callback after the new comment HTML has been loaded into the DOM.
     */
    commentsLoaded: function () {
	$('body').clearSpinner();
        SG.createTextLinks($('.comments .comment-text'));
        SG.lazyInitImages('.comments .comment-thumb');
        this.loadLazyImages();
    },

    loadLazyImages: function () {
        var args = {
	    data_attribute: 'lazy',
	    threshold: 1000
	};

        $('.comments img.lazy').lazyload(args);
    }
};

SG.LoadComments.bindEvents();
