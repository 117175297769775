var PhotoCollection = Backbone.Collection.extend({
  model: Photo,

  /**
   Initialize the collection.
   */
  initialize: function(models, options) {
    this.options = options;

    this.failedPhotos = [];
    this.pollingUploadJobStatus = false;
    this.pollUploadJobStatusInterval = 2000;
    this.pollUploadJobStatusErrorInterval = 10000;
    this.uploadJobNumbers = [];
    this.uploadJobStatusTimer = null;
    this.hadNonHDPhotosWhenLoaded = false;

    this.secret_key = options['secret_key'] || SG.generateUUID();
  },

  /**
   Loads photos from album id into the collection.
   */
  loadFromAlbum: function(album_id) {
    var albumInfoURL = '/api/album_photos/' + album_id + '/';
    $.get(albumInfoURL)
      .then(_.bind(this.getAlbumInfoSuccessHandler, this))
      .fail(_.bind(this.getAlbumInfoFailedHandler, this));
  },

  /**
   Get album info success handler.
   */
  getAlbumInfoSuccessHandler: function(photos, textStatus, jqXHR) {
    var i;
    var model;
    var models = [];

    for (i = 0; i < photos.length; i++) {
      model = new Photo({
        id: photos[i].photo.id,
        album_photo_id: photos[i].album_photo_id,
        filename: photos[i].filename,
        key: photos[i].fpfilekey,
        size: photos[i].size,
        number: photos[i].number,
        isCover: photos[i].is_cover,
        isNew: false,
        caption: photos[i].caption,
        isHD: photos[i].photo.is_hd,
        urls: photos[i].photo.urls,
        url: photos[i].photo.urls['original']
      });

      models.push(model);
    }

    this.add(models);
    this.hadNonHDPhotosWhenLoaded = this.hasNonHDPhotos();
    this.trigger('loaded');
  },

  /**
   Get album info failed handler.
   */
  getAlbumInfoFailedHandler: function(data, textStatus, jqXHR) {
    SG.userError('Error loading album info');
  },

  /**
     Add a single file uploaded with Filepicker to the collection.

     @param fpfile {Object} - The file details from Filepicker
     @param minNumber {Number} - The minimum position number for the new photo
     @returns {Object} The new photo model that was added to the collection
   */
  addFPFile: function(fpfile, minNumber) {
    var photo = new Photo({
      fpId: fpfile['id'],
      filename: fpfile['filename'],
      isNew: true,
      number: this.getPositionForNewFPFile(fpfile, minNumber),
      size: fpfile['size'],
      uploadProgress: fpfile['progress']
    });

    this.addAndRenumber(photo);
    this.sort();

    return photo;
  },

  /**
     Gets a position number for a new fpfile being added to the photo collection.

     Note: we need to use natural sort order for new files, but also respect any
     custom sort order the user has created with drag & drop functionality.

     @param fpfile {Object} - The file details from Filepicker
     @param minNumber {Number} - The minimum position number for the new photo
   */
  getPositionForNewFPFile: function(fpfile, minNumber) {
    var i;
    var filename;
    var compare;
    var number = null;

    if (this.models.length === 0) {
      return 1;
    }

    for (i = minNumber; i < this.models.length; i++) {
      filename = this.models[i].get('filename');

      if (!filename) {
        continue;
      }

      compare = SG.naturalSorter(fpfile, {filename: filename});

      if (compare <= 0) {
        number = this.models[i].get('number');
        break;
      }
    }

    if (number === null) {
      number = this.models.length + 1;
    }

    return number;
  },

  /**
     Find the model in the collection for fpfile and update it.

     @param fpfile {Object} - The file details from Filepicker
   */
  updateFPFile: function(fpfile) {
    var model = this.findByFPId(fpfile['id']);

    if (!model) {
      return;
    }

    model.set('uploadProgress', fpfile['progress']);
  },

  /**
     Find the model for fpfile and update it with complete fpfile info after upload finished.

     @param fpfile {Object} - The file details from Filepicker
     @param policyData {Object} - Policy data from Filepicker
   */
  updateFinishedFPFile: function(fpfile, policyData) {
    var model = this.findByFPId(fpfile['id']);

    if (!model) {
      return;
    }

    model.set({
      filename: fpfile.filename,
      fpfile: fpfile,
      fpId: null,
      key: fpfile.key,
      size: fpfile.size,
      url: fpfile.url,
      policy: policyData.policy,
      signature: policyData.signature,
      isCover: this.models.length == 0 && i == 0 ? true : false,
      uploadJobNumber: fpfile.uploadJobNumber
    });

    var self = this;
    this.preprocessFPFile(fpfile, policyData)
      .then(function() {
      // Wait a few milliseconds in case last FP progress update event fires after the
      // SG.Filepicker promise resolves, to ensure all upload jobs are in uploadJobNumbers,
      // and then start polling for upload job status.
      setTimeout(function() {
        self.startPollingUploadJobStatus();
        self.trigger('fpfile_added');
      }, 250);
    });
  },

  /**
   Add files uploaded with Filepicker to the collection.
   */
  addFPFiles: function(fpfiles, policyData, addToServer) {
    var photo;
    var photos = [];

    for (var i in fpfiles) {
      photo = new Photo({
        filename: fpfiles[i].filename,
        fpfile: fpfiles[i],
        key: fpfiles[i].key,
        size: fpfiles[i].size,
        url: fpfiles[i].url,
        number: this.models.length + photos.length,
        policy: policyData.policy,
        signature: policyData.signature,
        isCover: this.models.length == 0 && i == 0 ? true : false,
        uploadJobNumber: fpfiles[i].uploadJobNumber,
        isNew: true
      });

      photos.push(photo);
    }

    this.add(photos);

    var promise;

    if (addToServer == undefined || addToServer) {
      promise = this.addPhotosToServer(photos);
    } else {
      promise = new $.Deferred().resolve();
    }

    var self = this;
    promise.then(function() {
      // Wait a few milliseconds in case last FP progress update event fires after the
      // SG.Filepicker promise resolves, to ensure all upload jobs are in uploadJobNumbers,
      // and then start polling for upload job status.
      setTimeout(function() {
        self.startPollingUploadJobStatus();
        self.trigger('fpfiles_added');
      }, 250);
    });

    return promise;
  },

  /**
    Preprocess a file uploaded with Filepicker.

    Note: this function is adding the fpfile to the server immediately after
    it has been received by Filepicker, before Filepicker has even closed, and
    while other files could still be uploading.

    This causes the thumbnail generation to kick off immediately, as soon as the
    photo has been saved to the S3 bucket. But we do not add it to the collection
    yet, because we don't want it to get rendered or anything like that yet, until
    the Filepicker dialog has closed. We also need to wait so that the photos
    can be added sorted by filename, not added in order of which file finished
    uploading first.

    We save the upload job number onto the fpfile object, and the regular/old
    add photos to server method (which is storing a reference to the same fpfile
    object) has been hacked to recognize that this fpfile has already been added
    to the server.

    @deprecated?
  */
  preprocessFPFile: function(fpfile, policyData) {
    var postData = {
      album_type: this.options.albumType,
      photos: [{fpfile: JSON.stringify(fpfile)}],
      secret_key: this.secret_key
    };

    var self = this;

    return $.ajax({
      type: "POST",
      url: '/api/add_photos/',
      data: JSON.stringify(postData),
      contentType: 'application/json; charset=utf-8'
    })
      .then(function(data, textStatus, jqXHR) {
        console.log('Adding upload job number:' + data['upload_job_number']);
        self.uploadJobNumbers.push(data['upload_job_number']);
        return data;
      });
  },

  /**
   Send the add photos request to the SG server, to import the photos from Filepicker.

   Note: adding the photos ensures they have a photo record, but it does not assign them to an album.
   */
  addPhotosToServer: function(photos) {
    var postData = {
      album_type: this.options.albumType,
      photos: [],
      secret_key: this.secret_key
    };

    for (var idx in photos) {
      postData['photos'].push({fpfile: JSON.stringify(photos[idx].get('fpfile'))});
    }

    var self = this;

    var xhr = $.ajax({
      type: "POST",
      url: '/api/add_photos/',
      data: JSON.stringify(postData),
      contentType: 'application/json; charset=utf-8'
    })
      .then(function(data, textStatus, jqXHR) {
        console.log('Adding upload job number:' + data['upload_job_number']);
        self.uploadJobNumbers.push(data['upload_job_number']);
        return data;
      })
      .fail(function() {
        SG.userError('Could not import photos');
      });

    return xhr;
  },

  /**
   Start polling the server for upload job status.
   */
  startPollingUploadJobStatus: function() {
    this.pollingUploadJobStatus = true;
    if (!this.uploadJobStatusTimer) {
      this.pollUploadJobStatus();
    }
  },

  /**
   Poll the server for the status of photo upload jobs.
   */
  pollUploadJobStatus: function () {
    if (!this.hasUploadJobs()) {
      this.stopPollingUploadJobStatus();
      return;
    }

    var postData = JSON.stringify({
      upload_job_numbers: this.uploadJobNumbers,
      return_successful_photos: true,
      return_album_type: this.options.albumType,
      secret_key: this.secret_key
    });

    $.ajax({
      type: "POST",
      url: '/api/photo_upload_progress_multi/',
      data: postData,
      contentType: 'application/json; charset=utf-8'
    })
      .then(_.bind(this.processJobStatusUpdate, this))
      .fail(_.bind(this.jobStatusFailedHandler, this));
  },

  /**
   Processes the job status update from the photo upload progress API call.
   */
  processJobStatusUpdate: function (data) {
    for (var jobNumber in data) {
      var jobAndThumbsComplete = data[jobNumber].job_complete && data[jobNumber].thumbs_generated;
      var jobCompleteNoThumbs = data[jobNumber].job_complete && !data[jobNumber].thumbs_generated;

      if (jobAndThumbsComplete) {
        this.processFailedPhotos(data[jobNumber].upload_job_number, data[jobNumber].failed_photos);
        this.removeUploadJob(data[jobNumber].upload_job_number);
        this.processSuccessfulPhotos(data[jobNumber].successful_photos);
        this.trigger('fpfiles_successful');
      } else if (jobCompleteNoThumbs) {
        this.processFailedPhotos(data[jobNumber].upload_job_number, data[jobNumber].failed_photos);
        this.removeUploadJob(data[jobNumber].upload_job_number);
      } else {
        this.processSuccessfulPhotos(data[jobNumber].successful_photos);
      }
    }

    if (!this.hasUploadJobs()) {
      this.stopPollingUploadJobStatus();
    }

    if (this.pollingUploadJobStatus) {
      this.uploadJobStatusTimer = setTimeout(_.bind(this.pollUploadJobStatus, this), this.pollUploadJobStatusInterval);
    }
  },

  /**
   Polling for upload job status failed handler.
   */
  jobStatusFailedHandler: function(jqXHR, textStatus, errorThrown) {
    if ((jqXHR.status == 0 || jqXHR.status == 503) && this.pollingUploadJobStatus) {
      console.log('Recoverable error polling server, retrying');
      this.uploadJobStatusTimer = setTimeout(_.bind(this.pollUploadJobStatus, this), this.pollUploadJobStatusErrorInterval);
    }
  },

  /**
   Checks if the status of upload jobs is currently being polled.
   */
  pollingUploadJobStatus: function () {
    return !!this.uploadJobStatusTimer || this.config.pollingUploadJobStatus;
  },

  /**
   Stops polling the server for status of upload jobs.
   */
  stopPollingUploadJobStatus: function () {
    if (this.uploadJobStatusTimer) {
      clearTimeout(this.uploadJobStatusTimer);
      this.uploadJobStatusTimer = null;
    }

    this.pollingUploadJobStatus = false;
  },

  /**
   Restarts polling the server for status of upload jobs.
  */
  restartPollingUploadJobStatus: function () {
    this.stopPollingUploadJobStatus();
    this.startPollingUploadJobStatus();
  },

  /**
   Removes data about an upload job after it is complete.

   @param {Number} upload job number from add photos API call
   */
  removeUploadJob: function (uploadJobNumber) {
    this.uploadJobNumbers = _.without(this.uploadJobNumbers, uploadJobNumber);
  },

  /**
   Checks if there are outstanding upload jobs.
   */
  hasUploadJobs: function() {
    return !!this.uploadJobNumbers.length > 0;
  },

  /**
     Checks if all photos in the collection have been successful on the server.
   */
  checkAllPhotosComplete: function() {
    var allComplete = true;
    var i;

    for (i = 0; i < this.models.length; i++) {
      if (!this.models[i].get('isNew')) {
        continue;
      }

      if (!(!!this.models[i].get('id')) || !(!!this.models[i].get('thumbs_generated'))) {
        allComplete = false;
        break;
      }
    };

    return allComplete;
  },

  /**
   Loads the data from a successful upload job status back into the collection.
   */
  processSuccessfulPhotos: function(photos) {
    var i;
    var fpfilekey;

    for (i = 0; i < photos.length; i++) {
      if (!photos[i].thumbs_generated)
        continue;

      _.each(this.models, function(model) {
        var fpfile = model.get('fpfile');

        if (!fpfile) {
          return;
        }

        if (fpfile.key != photos[i].fpfilekey) {
          return;
        }

        model.set({
          id: photos[i].id,
          height: photos[i].height,
          isHD: photos[i].is_hd,
          original_filename: photos[i].filename,
          size: photos[i].size,
          width: photos[i].width,
          url: null,
          urls: photos[i].urls,
          thumbs_generated: photos[i].thumbs_generated
        });
      });
    }
  },

  /**
   Process failed photos, by finding corresponding models and triggering an event.
   */
  processFailedPhotos: function(uploadJobNumber, failedPhotos) {
    var i;
    var failedModel;
    var failedModels = [];

    for (i = 0; i < failedPhotos.length; i++) {
      failedModel = this.findByFPFileKey(failedPhotos[i].fpfilekey);

      if (failedModel) {
        failedModels.push(failedModel);
      }
    }

    var hasNewFailures = failedModels.length > this.failedPhotos.length;
    this.failedPhotos = failedModels;

    if (hasNewFailures) {
      this.trigger('fpfiles_failed');
    }
  },

  /**
   Finds the model with the given fpfilekey.
   */
  findByFPFileKey: function(fpfilekey) {
    var match = null;

    for (var i = 0; i < this.models.length; i++) {
      if (this.models[i].get('key') == fpfilekey) {
        match = this.models[i];
        break;
      }
    }

    return match;
  },

  /**
     Finds the model for the given Filepicker ID.

     @param fpId {Number} - The ID passed to the callbacks by Filepicker, local to this launch of Filepicker only
     @returns {Object} - The matching photo model in the collection with this fpId
   */
  findByFPId: function(fpId) {
    var match = null;

    for (var i = 0; i < this.models.length; i++) {
      if (this.models[i].get('fpId') == fpId) {
        match = this.models[i];
        break;
      }
    }

    return match;
  },

  /**
     Checks if the model corresponding to the fpfile details still exists in the collection.

     @param fpfile {Object} - The file details from Filepicker
     @returns {Boolean} whether or not the model for fpfile is still in the collection
   */
  checkStillHasFPFile: function(fpfile) {
    return !!this.findByFPId(fpfile['id']);
  },

  /**
     Add photo to collection, renumbering any photos that come after it.

     @param photo {Object} - Photo model to add to the collection
   */
  addAndRenumber: function(photo) {
    var newPhotoNumber = photo.get('number');
    this.add(photo);

    for (var i in this.models) {
      var number = this.models[i].get('number');

      if (this.models[i] == photo) {
        continue;
      }

      if (number >= newPhotoNumber) {
        this.models[i].attributes.number = number + 1;
      }
    }
  },

  /**
   Remove photo from collection, renumbering the remaining photos that came after it.
   */
  removeAndRenumber: function(photo) {
    var deleted_number = photo.get('number');
    this.remove(photo);

    for (var i in this.models) {
      var number = this.models[i].get('number');

      if (number > deleted_number) {
        this.models[i].set('number', number-1);
      }
    }
  },

  /**
   Check to see if the collection has non-HD photos in it.
   */
  hasNonHDPhotos: function() {
    var idx;
    var hasNonHDPhotos = false;

    for (idx in this.models) {
      if (!this.models[idx].get('isHD')) {
        hasNonHDPhotos = true;
        break;
      }
    }

    return hasNonHDPhotos;
  },

  /**
   Sets the cover photo to the last photo in the collection.
   */
  setCoverToLastPhoto: function() {
    var i;

    if (!this.models.length) {
      return;
    }

    this.sort();

    var maxNumber = this.models[this.models.length-1].get('number');

    for (i = 0; i < this.models.length; i++) {
      this.models[i].set('isCover', this.models[i].get('number') == maxNumber);
    }
  },

  /**
   Sets the URL attribute on the photo in the collection representing the cover photo.
   */
  setURLOnCoverPhoto: function(url) {
    var i;
    var cover = null;

    for (i = 0; i < this.models.length; i++) {
      if (this.models[i].get('isCover')) {
        cover = this.models[i];
        break;
      }
    }

    if (cover) {
      cover.set('url', url);
    }
  },

  /**
   Comparator to sort the photos in the collection by number.
   */
  comparator: function(model) {
    return model.get('number');
  }
});
