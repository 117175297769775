<div class="stat-groups">
  <div>
    <h1>Today</h1>
    <strong>Amount Tipped:</strong> ${{ today_tipped }}<br>
    <strong>Credits Purchased:</strong> ${{ today_purchased }}<br>
    <strong>Affiliates:</strong> ${{ today_affiliates }}<br>
  </div>

  <div>
    <h1>This Month</h1>
    <strong>Amount Tipped:</strong> ${{ month_tipped }}<br>
    <strong>Credits Purchased:</strong> ${{ month_purchased }}<br>
    <strong>Affiliates:</strong> ${{ month_affiliates }}<br>
    <strong>Payout Amount:</strong> ${{ month_payout }}<br>
    <br>
    <strong>Most Tipped:</strong> {{#if most_tipped }}{{ most_tipped }}{{else}}-{{/if}}<br>
    <strong>Top Affiliate:</strong> {{#if top_affiliate }}{{ top_affiliate }}{{else}}-{{/if}}<br>
    <strong>Top Tipper:</strong> {{#if top_tipper}}{{ top_tipper }}{{else}}-{{/if}}<br>
  </div>
</div>
