/* global SwipeView, PinchZoom */
SG.Gallery = function ($element) { // eslint-disable-line func-names
  this.albumTypeGeometries = {
    applicant: ['600', '1200', '1800', 'original'],
    blog: ['320', '600', '1200', 'original'],
    blog_attachments: ['320', '600', '1216', '2432'],
    blog_multi_photo: ['320', '600', '1216', '2432'],
    group: ['320', '600', '1200', 'original'],
    hopeful: ['320', '768', '1216', '2432'],
    instagram: ['320', '600', '1216', '2432'],
    profile: ['460x460', '607x441', '1214x882', '1821x1323'],
    sg: ['320', '768', '1216', '2432'],
  };
  this.defaultGeometries = ['original'];
  this.steps = 0;

  this.config = {
    $thumbContainer: null,
    albumType: null,
    geometries: [],

    // The resolution quality options for each screen type
    sizeRangeDictionary: {
      retina: ['320', '768', '1216', '2432'], // TODO: retina is broken on legacy images (thumbs aren't generated)
      full: ['320', '768', '1216'],
      ipad: ['320', '768'],
      mobile: ['320'],
    },

    // The default quality step to use for each screen type from sizeRangeDictionary
    sizeDefault: {
      retina: 2,
      full: 2,
      ipad: 1,
      mobile: 0,
    },

    isIos: Boolean(navigator.userAgent.match(/(iPad|iPhone|iPod)/g)),
    stageRatio: $(window).width() / $(window).height(),
    isTouch: 'ontouchend' in window,
    preloadNumber: 3,
    placeholder: $('body').attr('sg-gallery_placeholder'),
    startSlide: 0,
    thumbnailGeometry: '230x230', // Photo geometry from get album info photos array
    thumbnailLeftOffset: 225, // Distance between start of screen and first thumbnail
    thumbnailRightOffset: 150, // Distance between last thumbnail and end of screen
    thumbnailWidth: 75, // Displayed width
    showLikeButton: true,
  };

  if ($element) {
    $element.data('Gallery', this);
  }
};

SG.Gallery.prototype = {
  /**
     Initialize gallery object with the given configuration.

     @param {object} config - Configuration key/value pairs.
   */
  init(config) {
    $.extend(this.config, config);
    this.state = 'closed';
    this.setGeometriesForAlbumType(this.config.albumType);
  },

  setGeometriesForAlbumType(albumType) {
    if (albumType in this.albumTypeGeometries) {
      this.config.geometries = this.albumTypeGeometries[albumType];
    } else {
      this.config.geometries = this.defaultGeometries;
    }
  },

  /**
     Opens the gallery and begins loading the album into it.
   */
  load() {
    this.open();
    this.resetProgressBar();
    this.showProgressBar();
    const geometries = (
      `${this.config.geometries.join()},${this.config.thumbnailGeometry}`
    );
    const baseUrl = `/api/get_album_info/${this.config.album_id}/`;
    const albumInfoUrl = `${baseUrl}?geometries=${geometries}`;
    this.albumInfoRequest = $.get(albumInfoUrl);
    this.albumInfoRequest.then(_.bind(this.loadAlbum, this));
  },

  /**
     Opens the gallery and binds the controls.
   */
  open() {
    this.hideTipButton();
    $('#gallery').show();
    $('body').attr('gallery', '1');
    this.openScrollTop = $(window).scrollTop();
    this.disableScrolling();
    this.bindControls();
    this.state = 'open';
    this.pinchZooms = [];
  },

  /**
     Disable scrolling of the page while gallery is open.

     Note: overflow hidden is enough on desktop, but some devices, including iPhone X,
     still scroll without position fixed, height 100%.
   */
  disableScrolling() {
    $('body').css('overflow', 'hidden');

    if (SG.isTouch()) {
      $('body').css('height', '100%');
      $('body').css('position', 'fixed');
    }
  },

  /**
     Binds the event handlers for the gallery controls.
   */
  bindControls() {
    $('#carousel-prev').on('click', _.bind(this.selectPrevEvent, this));
    $('#carousel-next').on('click', _.bind(this.selectNextEvent, this));

    // Syncing events from SwipeView and PinchZoom
    this.touchStart = false;
    this.touchMove = false;
    this.pinchZoomIsWorking = false;

    const self = this;

    $('html.touch')
      .on({
        touchstart() {
          self.touchStart = true;
          self.touchMove = false;
        },
        touchmove() {
          self.touchMove = true;
          self.hideControls();
        },
        touchend() {
          setTimeout(() => {
            if (self.touchStart && !self.touchMove && !self.pinchZoomIsWorking) {
              const showingControls = $('#gallery').hasClass('touch-active');
              if (showingControls) {
                self.hideControls();
              } else {
                self.showControls();
              }
            }

            self.touchStart = false;
            self.touchMove = false;
            self.pinchZoomIsWorking = false;
          }, 303);
        },
      }, '#swipeview-slider > div')
      // Handling PinchZoom events
      .on('pz_zoomstart pz_dragstart pz_doubletap', '.pinch-zoom-container', () => {
        self.pinchZoomIsWorking = true;
      });

    $('.carousel-nav').on('click', (e) => {
      e.preventDefault();
    });

    $('#quality-adjust').on('click', () => {
      $('#quality-adjuster').toggleClass('active');
    });

    $('#quality-adjuster').on('change', _.bind(this.qualityChangedEvent, this));

    $(document).on('keydown.gallery', _.bind(this.keyClickedEvent, this));
    $(document).on('click', '#gallery-thumbnails li', _.bind(this.selectThumbEvent, this));
    $('#play').on('click', _.bind(this.togglePlayEvent, this));
    $('#full-screen').on('click', _.bind(this.toggleFullScreenEvent, this));
    $('#gallery-close').on('click', _.bind(this.close, this));
    $('#btn-download-original').on('click', _.bind(this.downloadOriginal, this));
    $('body').on('click', () => {
      self.stop();
    });

    $(window).on('resize.gallery', () => {
      self.config.stageRatio = $(window).width() / $(window).height();
      // TODO: check images (and their containers) sizes when resizing.
    });
  },

  /**
     Show gallery controls in UI.
   */
  showControls() {
    $('#gallery').addClass('touch-active');
  },

  /**
     Loads the given album info into the gallery.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  loadAlbum(albumInfo) {
    if (this.state !== 'open') {
      return null;
    }

    if (this.config.album_id && (Number(this.config.album_id) !== albumInfo.id)) {
      // Check to ensure the sg album id passed in #gallery-tip- is right for the album id
      // that is being loaded. This is to ensure no hacker is trying to link people to a
      // gallery with a tip dialog open for another photoset.
      window.location.href = window.location.href.split('#')[0]; // eslint-disable-line prefer-destructuring
    }

    this.padAlbumPhotosToMinimumCount(albumInfo);
    this.config.album_info = albumInfo;
    this.configureQuality();

    const gallery = this.initGallery(albumInfo);
    this.generateMarkup(albumInfo);
    this.bindLoadedAlbumEvents();

    const $tipButton = $('#gallery-tip button.tip');
    const isTipRecipient = SG.user && SG.user.logged_in_username
      && albumInfo.tip_recipients
      && ($.inArray(SG.user.logged_in_username, albumInfo.tip_recipients) !== -1);

    if ((albumInfo.type_of_album === 'sg' || albumInfo.type_of_album === 'hopeful') && !isTipRecipient) {
      $tipButton.attr('data-object_id', albumInfo.id);
      $tipButton.attr('data-tip-recipients', albumInfo.tip_recipients.join(','));
      $tipButton.attr('data-gallery-id', albumInfo.id);
      $tipButton.show();
    } else {
      $tipButton.attr('data-object_id', '');
      $tipButton.attr('data-tip-recipients', '');
      $tipButton.hide();
    }

    gallery.done(() => {
      // Load after progress bar animation is done
      setTimeout(_.bind(this.showLoadedGallery, this), 501);
    });

    return gallery;
  },

  /**
     Loads the gallery into the DOM.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  generateMarkup(albumInfo) {
    if (!this.isMobile() && this.albumContainsThumbnails(albumInfo)) {
      this.createThumbnails(albumInfo);
    }

    // Clone the like and share buttons from the content box.
    // Note: this does nothing if there is no content box.
    const $contentBox = this.config.origin.closest('.content-box');
    const $originalLike = $contentBox.find('.youLike');
    const $like = $originalLike.clone(true);
    const $share = $contentBox.find('.icon-share').clone(true);
    const $shareLinks = $contentBox.find('.share-menu').clone();
    const $galleryShare = $('#gallery-share');
    const $galleryLike = $('#gallery-like');

    $like.addClass('btn-icon').text('');

    $share.attr('id', 'gallery-share-link').addClass('btn-icon');
    $share.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.toggleShareMenu();
    });

    $shareLinks.attr('id', 'gallery-share-menu');

    $like.on('click', () => {
      $originalLike.trigger('click');
    });

    $galleryShare.append($share);

    if (this.config.showLikeButton) {
      $galleryLike.append($like);
    }

    $('#gallery').append($shareLinks);
  },

  /**
     Checks if the album contains thumbnail images.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  albumContainsThumbnails(albumInfo) {
    return (albumInfo.photos.length > 0
      && albumInfo.photos[0].urls[this.config.thumbnailGeometry] !== undefined);
  },

  /**
     Creates the thumbnail widget and loads the thumbnails into it.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  createThumbnails(albumInfo) {
    const template = SG.templates.gallery_thumbs;

    const thumbs = this.getThumbnailURLs(albumInfo);
    const context = {
      thumbnails: [],
    };

    const capacity = this.getVisibleThumbnailCapacity();
    const numberToPreload = capacity + 1;
    let i;
    let thumbnail;

    for (i = 0; i < thumbs.length; i += 1) {
      if (i < numberToPreload) {
        thumbnail = { src: thumbs[i], original: '' };
      } else {
        thumbnail = { src: '', original: thumbs[i] };
      }

      context.thumbnails.push(thumbnail);
    }

    $('#gallery').append(template(context));
    $('#gallery-thumbnails img').mouseenter(_.bind(this.mouseEnterThumbnailEvent, this));
  },

  /**
     Gets the thumbnail URLs for the album.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  getThumbnailURLs(albumInfo) {
    const thumbs = [];
    let i;
    let len;

    for (i = 0, len = albumInfo.photos.length; i < len; i += 1) {
      thumbs.push(albumInfo.photos[i].urls[this.config.thumbnailGeometry]);
    }

    return thumbs;
  },

  /**
     Checks if the gallery is running in mobile mode or not.

     @returns {boolean}
   */
  isMobile() {
    return $(window).width() < 500;
  },

  /**
     Determines the active geometry to use for gallery images and initializes the slider widget.
   */
  configureQuality() {
    const screenType = this.getScreenType();
    const preferredQuality = this.getUserPreferredQuality();

    this.steps = this.config.sizeRangeDictionary[screenType].length;

    let step;

    if (preferredQuality !== null) {
      step = preferredQuality;
    } else {
      step = this.getDefaultQualityForScreenType(screenType);
    }

    if (step > this.steps) {
      step = this.steps.length - 1;
    }

    let activeGeometry = this.config.sizeRangeDictionary[screenType][step]
      || this.config.sizeRangeDictionary[screenType][
        this.config.sizeRangeDictionary[screenType].length - 1];

    if (!this.config.album_info.photos[0].urls[activeGeometry]) {
      activeGeometry = 'original';

      if (!this.config.album_info.photos[0].urls[activeGeometry]) {
        const geometries = Object.keys(this.config.album_info.photos[0].urls);
        activeGeometry = geometries[geometries.length - 1];
      }
    }

    this.preferredQuality = preferredQuality !== null ? preferredQuality : step;
    this.activeGeometry = activeGeometry;
    this.initializeQualitySlider(this.steps, step);
  },

  /**
     Gets the screen type that the gallery is being displayed on.

     @returns {string} Screen type - retina, full, ipad or mobile)
   */
  getScreenType() {
    const width = this.getWidth();
    let screenType;

    if (width > 1216) {
      screenType = 'retina';
    } else if (width > 768) {
      screenType = 'full';
    } else if (width > 320) {
      screenType = 'ipad';
    } else {
      screenType = 'mobile';
    }

    return screenType;
  },

  /**
     Gets the width of the gallery.
   */
  getWidth() {
    let width = $(window).width();

    if (window.devicePixelRatio) {
      width *= window.devicePixelRatio;
    }

    return width;
  },

  /**
     Gets the user's preferred quality setting.

     Note: This is passing around the step number in the slider/sizeRangeDictionary.
   */
  getUserPreferredQuality() {
    let preferredQuality = null;

    if ($.cookie('preferredQuality')) {
      preferredQuality = $.cookie('preferredQuality');
    }

    return preferredQuality;
  },

  /**
     Gets the default quality setting to use for the given screen type.

     @param {string} screenType - The screen type to get the default for.
     @returns {number} The step number for the quality slider.
   */
  getDefaultQualityForScreenType(screenType) {
    return this.config.sizeDefault[screenType];
  },

  /**
     Initializes the select image quality widget.

     @param {number} numberOfSteps - Number of quality options to provide on the slider.
     @param {number} initialStep - The quality option to preselect on the slider.
   */
  initializeQualitySlider(numberOfSteps, initialStep) {
    if (this.UiSlider && this.UiSlider.length > 0) {
      this.UiSlider.remove();
    }

    this.UiSlider = $('<div class="noUiSlider" />').appendTo('#quality-adjuster');

    this.UiSlider.noUiSlider({
      range: [0, numberOfSteps - 1],
      start: initialStep,
      handles: 1,
    });
  },

  /**
     Event handler for when the user changes the quality setting with the slider.
   */
  qualityChangedEvent() {
    setTimeout(() => {
      $('#quality-adjuster').removeClass('active');
    }, 1500);

    const storedQualityPreference = Math.round(this.UiSlider.val());
    if (typeof (storedQualityPreference) === 'number' && storedQualityPreference < this.steps) {
      this.setQualityPreference(storedQualityPreference);
    } else {
      this.setQualityPreference(2);
    }

    this.configureQuality(); // Set picture size

    // Re-initialize with new sizes and go back to the same slide
    this.init({ startSlide: this.swipeView.pageIndex });
    this.swipeView.destroy(); // Destroy, remove
    $('#swipeview-slider').remove();
    $('#gallery').removeClass('loaded');

    const newGallery = this.initGallery(this.config.album_info);

    newGallery.done(() => {
      setTimeout(_.bind(this.showLoadedGallery, this), 501);
    });
  },

  /**
    Set the user's quality preference.
  */
  setQualityPreference(quality) {
    this.preferredQuality = quality;

    $.cookie(
      'preferredQuality',
      this.preferredQuality,
      {
        expires: 365,
        path: '/',
      },
    );
  },

  /**
     Shows the gallery loading progress bar.
   */
  showProgressBar() {
    $('#gallery-progress').show();
  },

  /**
     Hides the gallery loading progress bar.
   */
  hideProgressBar() {
    $('#gallery-progress').hide();
  },

  /**
     Resets the gallery loading progress bar back to the start position.
   */
  resetProgressBar() {
    $('#gallery-progress').find('.bar').width('10%');
  },

  /**
     Updates the progress bar with the current status.
   */
  updateProgressBar() {
    const progress = (this.loadedCount / this.config.preloadNumber) * 100;
    $('#gallery-progress').find('.bar').width(`${progress}%`);
  },

  /**
     Shows the loaded gallery interface.

     Note: showing the gallery does not show the loaded images until method is called.
  */
  showLoadedGallery() {
    $('#gallery').addClass('loaded');
  },

  /**
     Bind event handlers for the loaded album interface.
  */
  bindLoadedAlbumEvents() {
    $('#gallery').on('click', '.swipeview-active > .image_container', _.bind(this.mainImageContainerClickEvent, this));
  },

  /**
     Shows the gallery loading progress bar.
   */
  showBanner(albumInfo) {
    if ($('#gallery-banner').length && albumInfo.privacy === 'public') {
      $('.gallery-banner').removeClass('hidden').addClass('visible');
    }
  },

  toggleShareMenu() {
    $('#gallery-share-menu').toggleClass('active-bar');
  },

  /**
    Shows the tip button.
  */
  showTipButton() {
    $('#gallery-tip').show();
  },

  /**
    Hides the tip button.
  */
  hideTipButton() {
    $('#gallery-tip').hide();
  },

  /**
    Changes UI to show photoset was tipped */
  markTipped() {
    $('#gallery-tip button.tip').addClass('tipped');
  },

  /**
    Changes UI to show photoset has not been tipped.
  */
  markUntipped() {
    $('#gallery-tip button.tip').removeClass('tipped');
  },

  /**
     Initializes the gallery interface.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  initGallery(albumInfo) {
    this.galleryPreload = new $.Deferred();
    this.photos = albumInfo.photos;
    window.photos = albumInfo.photos;
    this.loadedCount = 0;
    this.showProgressBar();

    this.galleryPreload.done(() => {
      setTimeout(() => {
        this.resetProgressBar();
        this.hideProgressBar();
      }, 500);
      this.showBanner(albumInfo);
      this.centerThumb(this.config.startSlide);
    });

    const swipeViewParams = {
      numberOfPages: this.photos.length,
      hastyPageFlip: true,
      loop: true,
    };

    this.swipeView = new SwipeView('#gallery', swipeViewParams);

    this.preloadInitialImages();

    this.swipeView.onMoveIn(_.bind(this.onMoveInEvent, this));
    this.swipeView.onMoveOut(_.bind(this.onMoveOutEvent, this));
    this.swipeView.onFlip(_.bind(this.onFlipEvent, this));
    this.swipeView.goToPage(parseInt(this.config.startSlide, 10));

    if (window.history.pushState) {
      this.previousLocationHash = window.location.hash;
      window.history.pushState({}, 'Gallery', '#gallery');
    }

    // Closes the gallery when the user presses the back button.
    setTimeout(() => {
      $(window).one('popstate', (e) => {
        e.preventDefault();
        if (this.state !== 'closed') {
          this.close();
        }
      });
    }, 500);

    return this.galleryPreload;
  },

  /**
     Preloads the first, second and last full sized images into gallery.

     Note: preloading these three images is necessary so that the user can swipe
     left or right as soon as the first image has appeared. The SwipeView will not
     work at all without having three images, so we are employing a strategy of
     preloading the first visible image, and lazy loading the previous and next images,
     after the first image has been loaded, using a placeholder image until then.

     Note: SwipeView does something different when startIdx = 1. For ever other startIdx,
     putting the start image in master page, next in master page 2, and previous in
     masterpage 0 works fine. But it does something different with startIdx 1, requiring
     that the start image be loaded into masterpage 2.

     TODO: Figure out why SwipeView is doing that and / or fix it.
   */
  preloadInitialImages() {
    const startIdx = this.config.startSlide;
    const lastIdx = this.photos.length - 1;
    const prevIdx = startIdx - 1 >= 0 ? startIdx - 1 : lastIdx;
    const nextIdx = startIdx + 1 <= lastIdx ? startIdx + 1 : lastIdx;

    if (this.photos.length === 1) {
      this.preloadImage(1, startIdx, false);
    } else if (this.photos.length === 2) {
      this.preloadImage(0, prevIdx, true);
      this.preloadImage(1, startIdx, false);
    } else if (startIdx === 1) {
      this.preloadImage(0, nextIdx, true);
      this.preloadImage(1, prevIdx, true);
      this.preloadImage(2, startIdx, false);
    } else {
      this.preloadImage(0, prevIdx, true);
      this.preloadImage(1, startIdx, false);
      this.preloadImage(2, nextIdx, true);
    }
  },

  /**
     Preloads one of the full sized images into the gallery.

     @param {number} swipeviewPage - Swipeview master page number,
     0 (left), 1 (visible), 2 (right).
     @param {number} photoIndex - The photo number to load into the swipe view page,
     starting from 0.
     @param {Boolean} lazy - Whether to lazy load the image after preloading is done,
     or right away.
   */
  preloadImage(swipeviewPage, photoIndex, lazy) {
    const img = document.createElement('img');
    $(img).load(_.bind(this.preloadedImageReady, this));
    img.className = 'loading';
    $(img).attr('draggable', false);
    $(img).on('mousedown', _.bind(this.ignoreRightClickEvent, this));

    if (lazy) {
      $(img).attr('data-original', this.photos[photoIndex].urls[this.activeGeometry]);
      $(img).addClass('initial_lazy_load');
      img.src = this.config.placeholder;
    } else {
      img.src = this.photos[photoIndex].urls[this.activeGeometry];
    }
    const $container = $('<div/>');
    $container.addClass('image_container');
    $container.append(img);
    $(this.swipeView.masterPages[swipeviewPage]).append($container);

    // Add PinchZoom for touch devices
    if (this.config.isTouch) {
      const pinchZoom = new PinchZoom(img, {
        draggableUnzoomed: false,
        setOffsetsOnce: false,
      });
      this.pinchZooms.push(pinchZoom);
    }
  },

  /**
     Callback run when one of the main preloaded gallery images has finished loading.

     @param {object} e - JQuery event data.
   */
  preloadedImageReady(e) {
    this.loadedCount += 1;

    if (this.galleryPreload.state() === 'pending') {
      this.updateProgressBar();
    }

    if (this.allPreloadingFinished()) {
      this.endPreloadingState();
    }

    $(e.target).removeClass('loading').parent().removeClass('loading');
    $(e.target).removeClass('portrait landscape').addClass(this.getImageOrientation(e.target));
    if (this.config.isTouch) {
      this.resetPinchZooms();
    }
  },

  /**
     Determines if all data necessary for the preloading state to end has finished loading.
   */
  allPreloadingFinished() {
    return this.loadedCount === this.config.preloadNumber;
  },

  /**
     Ends the gallery preloading state.
   */
  endPreloadingState() {
    this.loadLazyInitialImages();
    this.galleryPreload.resolve();
  },

  /**
     Loads the lazy initial full size images.

     Note: this effectively means loading in the images to the left and right of the start
     image, and is intended to happen immediately after the first image is done loading.
   */
  loadLazyInitialImages() {
    $('img.initial_lazy_load').each((index, img) => {
      const timeout = index === 0 ? 500 : index * 250;
      setTimeout(() => {
        this.loadLazyInitialImage(img);
      }, timeout);
    });
  },

  loadLazyInitialImage(img) {
    const originalOnload = img.onload;
    img.onload = () => { // eslint-disable-line no-param-reassign
      $(img).removeClass('loading').parent().removeClass('loading');
      $(img).addClass(this.getImageOrientation(img));
      img.onload = originalOnload; // eslint-disable-line no-param-reassign
    };
    $(img).attr('src', img.getAttribute('data-original'));
  },

  /**
     Gets the orientation of an image element.
   */
  getImageOrientation(img) {
    return img.width / img.height > 1 ? 'landscape' : 'portrait';
  },

  /**
     Event handler for when the user clicks the play button.

     @param {object} e - JQuery event data.
   */
  togglePlayEvent(e) {
    e.stopPropagation();

    if (this.isPlaying) {
      this.stop();
    } else {
      this.play();
    }
  },

  /**
     Starts the showing in gallery images in slideshow mode.
   */
  play() {
    this.isPlaying = true;
    $('#play').addClass('playing');
    this.playing = setInterval(() => {
      this.swipeView.next();
    }, 5500);
  },

  /**
     Stops showing the gallery images in slideshow mode.
   */
  stop() {
    $('#play').removeClass('playing');
    this.isPlaying = false;
    clearInterval(this.playing);
  },

  /**
     Event handler when user clicks on a thumbnail.

     @param {object} e - JQuery event data.
   */
  selectThumbEvent(e) {
    const selectedIndex = $(e.target).parent().index();
    this.selectThumb(selectedIndex);
    this.swipeView.goToPage(selectedIndex);
  },

  /**
     Selects a thumbnail in the gallery.

     @param {number} index - The thumbnail number to select, starting from zero.
  */
  selectThumb(index) {
    this.centerThumb(index);
    this.highlightThumb(index);
    this.loadThumbnailImagesAroundIndex(index);
  },

  /**
     Centers the thumbnail image at the given index in the visible area.

     @param {number} index - The thumbnail number to center, starting from zero.
   */
  centerThumb(index) {
    if (this.isMobile()) {
      return;
    }

    const $li = $('#gallery-thumbnails').find('li').eq(index);
    $li.siblings().removeClass('selected');
    $li.addClass('selected');

    let left = ($(window).width() / 2) - (index * this.config.thumbnailWidth + 37);
    left = left > this.config.thumbnailLeftOffset ? this.config.thumbnailLeftOffset : left;
    $li.parent().css({ left });
  },

  /**
     Loads all of the thumbnails around the thumbnail at the given index.

     Note: this loads the thumbnails which would be visible at the same time if the
     thumbnail at the given index were centered in the visible area.
   */
  loadThumbnailImagesAroundIndex(index) {
    const capacity = this.getVisibleThumbnailCapacity();
    const numberEachSide = Math.ceil((capacity - 1) / 2);
    let startIndex;
    let lastIndex;

    if (index - numberEachSide > 0) {
      startIndex = index - numberEachSide;
    } else {
      startIndex = 0;
    }

    if (index + numberEachSide + 1 <= this.photos.length - 1) {
      lastIndex = index + numberEachSide + 1;
    } else {
      lastIndex = this.photos.length - 1;
    }

    let i;
    for (i = startIndex; i <= lastIndex; i += 1) {
      this.loadThumbnailImage(i);
    }
  },

  /**
     Calculates the maximum number of thumbnails that will fit on the screen at once.

     @returns {number} The number of thumbnails that will fit, including the last partially
     visible one.
   */
  getVisibleThumbnailCapacity() {
    const thumbnailContainerWidth = ($(window).width()
      - this.config.thumbnailLeftOffset
      - this.config.thumbnailRightOffset);
    const thumbnailContainerCapacity = thumbnailContainerWidth / this.config.thumbnailWidth;
    return Math.ceil(thumbnailContainerCapacity);
  },

  /**
     Loads the image for the thumbnail at the given index if it was lazy loaded.

     Note: this method can get called indirectly from the swipeview onflip event before
     the thumbnails have been created, which is why the thumbnail may not be in the DOM yet.

     @param {number} index - The thumbnail number to load, starting from zero.
  */
  loadThumbnailImage(index) {
    const nthChild = index + 1;
    const $img = $(`#gallery-thumbnails li:nth-child(${nthChild}) img`)[0]; // eslint-disable-line prefer-destructuring

    if ($img === undefined) {
      return;
    }

    const src = $img.src; // eslint-disable-line prefer-destructuring

    const original = $img.getAttribute('data-original');

    if (original && original !== src) {
      $img.src = original;
    }
  },

  /**
     Highlights the indicated thumbnail in the gallery.

     @param {number} index - The thumbnail number to highlight, starting from zero.
  */
  highlightThumb(index) {
    $('#gallery-thumbnails').children().removeClass('selected');
    $('#gallery-thumbnails').children().eq(index).addClass('selected');
  },

  /**
     Pads the number of photos to the minimum required for swipe view to work correctly.

     Note: this is necessary because swipe view currently requires a minimum of three images.

     TODO: Fix this terrible hack.

     @param {object} albumInfo - An object containing album info
     (response from get_album_info API).
   */
  padAlbumPhotosToMinimumCount(albumInfo) {
    if (albumInfo.photos.length === 1) {
      albumInfo.photos.push(albumInfo.photos[0]);
      albumInfo.photos.push(albumInfo.photos[0]);
      $('#gallery-thumbnails').hide();
    } else if (albumInfo.photos.length === 2) {
      albumInfo.photos.push(albumInfo.photos[0]);
      albumInfo.photos.push(albumInfo.photos[1]);
      $('#gallery-thumbnails').hide();
    }
  },

  /**
     Event handler triggered when the user clicks the fullscreen button.

     @param {object} e - JQuery event data.
   */
  toggleFullScreenEvent() {
    if (this.isFullScreenMode()) {
      this.closeFullScreenMode();
    } else {
      this.openFullScreenMode();
    }
  },

  /**
     Checks if the gallery is currently in fullscreen mode.
   */
  isFullScreenMode() {
    return this.runPrefixMethod(document, 'FullScreen') || this.runPrefixMethod(document, 'IsFullScreen');
  },

  /**
     Closes full screen mode.
   */
  closeFullScreenMode() {
    this.runPrefixMethod(document, 'CancelFullScreen');
  },

  /**
     Switches the gallery to full screen mode.
  */
  openFullScreenMode() {
    this.runPrefixMethod(document.documentElement, 'RequestFullScreen');
  },

  /**
     Runs vendor specific variants of the given Javascript method name.

     @param {object} object - An object to be passed to the vendor specific methods
     when they get run.
     @param {string} method - The base method name to add the vendor specific prefixes to.

     @see http://www.sitepoint.com/html5-full-screen-api/
  */
  runPrefixMethod(obj, method) {
    let vendorPrefix = ['webkit', 'moz', 'ms', 'o', ''];
    let m;
    let t;
    let i;

    for (i = 0; i < vendorPrefix.length && !obj[m]; i += 1) {
      m = method;

      if (vendorPrefix[i] === '') {
        m = m.substr(0, 1).toLowerCase() + m.substr(1);
      }

      m = vendorPrefix[i] + m;
      t = typeof obj[m];

      if (t !== 'undefined') {
        vendorPrefix = [vendorPrefix[i]];
        return (t === 'function' ? obj[m]() : obj[m]);
      }
    }

    return null;
  },

  /**
     Event handler for keyboard clicks in the gallery.

     @param {object} e - JQuery event data.
   */
  keyClickedEvent(e) {
    const map = {
      27: _.bind(this.close, this),
      37: _.bind(this.swipeView.prev, this.swipeView),
      39: _.bind(this.swipeView.next, this.swipeView),
    };

    if (e.which in map) {
      e.preventDefault();
      map[e.which]();
    }
  },

  /**
     Event handler for clicks on the previous image button.

     @param {object} e - JQuery event data.
   */
  selectPrevEvent(e) {
    e.preventDefault();
    this.swipeView.prev();
  },

  /**
     Event handler for clicks on the next image button.

     @param {object} e - JQuery event data.
   */
  selectNextEvent(e) {
    e.preventDefault();
    this.swipeView.next();
  },

  /**
     Event handler triggered by SwipeView when the main gallery image is changed.
   */
  onFlipEvent() {
    let currentImage;
    let upcoming;
    let i;

    this.selectThumb(parseInt(this.swipeView.pageIndex, 10));

    for (i = 0; i < 3; i += 1) {
      upcoming = this.swipeView.masterPages[i].dataset.upcomingPageIndex;

      if (upcoming !== this.swipeView.masterPages[i].dataset.pageIndex) {
        currentImage = this.swipeView.masterPages[i].querySelector('img');
        currentImage.className = 'loading'; // Should already be cached.
        $(currentImage).parent().addClass('loading');

        const photoObject = this.photos[upcoming];
        currentImage.onload = () => { // eslint-disable-line no-loop-func
          $(currentImage).removeClass('loading').parent().removeClass('loading');
          $(currentImage).addClass(this.getImageOrientation(currentImage));
        };
        $(currentImage).attr('src', photoObject.urls[this.activeGeometry]);
      }
    }

    // Figure out next master page

    const nextMasterPage = this.swipeView.currentMasterPage // eslint-disable-line no-nested-ternary
      === 1 ? 2 : this.swipeView.currentMasterPage === 0 ? 1 : 0;

    // Pre-cache next picture.
    SG.debugGroup('Cache...');
    SG.debug('current master page: ', this.swipeView.currentMasterPage);
    SG.debug('next: ', nextMasterPage);
    SG.debug(
      'masterpage pageindex: ',
      this.swipeView.masterPages[this.swipeView.currentMasterPage].dataset.upcomingPageIndex,
    );
    SG.debug('preCache pageindex: ', parseInt(this.swipeView.masterPages[nextMasterPage].dataset.upcomingPageIndex, 10) + 1);

    let preCacheIndex = parseInt(
      this.swipeView.masterPages[nextMasterPage].dataset.upcomingPageIndex,
      10,
    ) + 1;

    // Loop around
    preCacheIndex = preCacheIndex > this.photos.length - 1 ? 0 : preCacheIndex;

    // Make a ghost image with "next next" image, caching it.
    const cacheImage = new Image();
    cacheImage.src = this.photos[preCacheIndex].urls[this.activeGeometry];
    SG.debugGroupEnd('Cache...');
    setTimeout(_.bind(this.resetPinchZooms, this), 400);

    if (this.config.album_info.tippable) {
      this.showTipButton();
    } else {
      this.hideTipButton();
    }

    if (this.config.album_info.tippable && this.config.album_info.tipped) {
      this.markTipped();
    } else {
      this.markUntipped();
    }
  },

  /**
     Reset the Pinch Zooms.
   */
  resetPinchZooms() {
    $.each(this.pinchZooms, (idx, pinchZoom) => {
      this.resetPinchZoom(pinchZoom);
    });
  },

  /**
     Reset a PinchZoom instance.

     The PinchZoom library doesn't expect the image inside a PinchZoom container
     to be changing. It does listen for resize and load events, but it doesn't fully
     reset itself on those events.

     Here we are taking extra steps to force the PinchZoom to reset itself more fully,
     allowing us to keep reusing the same PinchZoom container inside each SwipeView
     master page, without having to create a new PinchZoom everytime the image flips
     to the next one.
   */
  resetPinchZoom(pinchZoom) {
    pinchZoom.zoomOutAnimation();
    pinchZoom.end();
    pinchZoom.offset = { x: 0, y: 0 }; // eslint-disable-line no-param-reassign
    pinchZoom.updateAspectRatio();
    pinchZoom.setupOffsets();
  },

  /**
     Event handler triggered by SwipeView when an image is moved in. (??)
   */
  onMoveInEvent() {
    const className = this.swipeView.masterPages[this.swipeView.currentMasterPage].className; // eslint-disable-line
    /(^|\s)swipeview-active(\s|$)/.test(className) || (this.swipeView.masterPages[this.swipeView.currentMasterPage].className = !className ? 'swipeview-active' : className + ' swipeview-active'); // eslint-disable-line
  },

  /**
     Event handler triggered by SwipeView when the image is moved out. (??)
   */
  onMoveOutEvent() {
    const masterPage = this.swipeView.masterPages[this.swipeView.currentMasterPage];
    masterPage.className = masterPage.className.replace(/(^|\s)swipeview-active(\s|$)/, '');
  },

  /**
     Event handler to catch and ignore right clicks.

     Note: This Fixes touch getting stuck when save as.

     @param {object} e - JQuery event data.
   */
  ignoreRightClickEvent(e) {
    if (e.which === 3) {
      e.stopImmediatePropagation();
    }
  },

  /**
     Event handler triggered when the mouse moves over a thumbnail.

     @param {object} e - JQuery event data.
  */
  mouseEnterThumbnailEvent(e) {
    const selectedIndex = $(e.target).parent().index();
    this.loadThumbnailImagesAroundIndex(selectedIndex);
  },

  /**
     Main Image Area Click Event Handler

     Note: This closes the gallery if the user clicked somewhere around the image, but does nothing
     if the click was on the main gallery image being displayed itself.

     @param {object} e - JQuery event data.
  */
  mainImageContainerClickEvent(e) {
    if (e.target === $('.swipeview-active .image_container')[0]) {
      this.close();
    }
  },

  /**
     Closes the gallery.

     Removes, hides and destroys it, stops timers, etc.
   */
  close() {
    $('#gallery').hide();
    this.hideTipButton();
    this.cancelXHRs();
    this.isPlaying ? this.stop() : () => {}; // eslint-disable-line no-unused-expressions
    this.unbindEvents();
    if (this.swipeView) {
      this.swipeView.destroy();
    }
    this.enableScrolling();
    $(window).scrollTop(this.openScrollTop);
    $('#gallery-nav').remove();
    $('#swipeview-slider').remove();
    $('#quality-adjuster').find('.noUiSlider').remove();
    $('#quality-adjuster').removeClass('active');
    $('#gallery').find('.youLike').remove();
    $('#gallery').find('.icon-share').remove();
    $('#gallery-share-menu').remove();
    $(window).off('resize.gallery');
    $('#gallery').removeClass('loaded');
    $('body').attr('gallery', '0');
    this.state = 'closed';
    const newLocationHash = this.previousLocationHash ? this.previousLocationHash : '#';
    window.history.replaceState({}, '', newLocationHash);
  },

  cancelXHRs() {
    if (this.albumInfoRequest && this.albumInfoRequest.state() === 'pending') {
      this.albumInfoRequest.abort();
    }
  },

  /**
     Unbinds the event listeners for the gallery user interface.
   */
  unbindEvents() {
    $('html.touch').off('touchstart touchmove touchend');
    $('#carousel-prev').unbind('touchend');
    $('#carousel-prev').unbind('click');
    $('#carousel-next').unbind('touchend');
    $('#carousel-next').unbind('click');
    $('#quality-adjuster').unbind('change');
    $('#quality-adjust').unbind('click');
    $(document).unbind('keydown.gallery');
    $('#play').unbind('click');
    $('#gallery-close').unbind('click');
    $('#full-screen').unbind('click');
    $('#gallery-thumbnails img').unbind('mouseenter');
    $(document).off('click', '#gallery-thumbnails li');
    $('#gallery').off('click', '.swipeview-active > .image_container');
  },

  enableScrolling() {
    $('body').css('overflow', 'auto');

    if (SG.isTouch()) {
      $('body').css('position', 'static');
      $('body').css('height', 'inherit');
    }
  },

  /**
     Hide the gallery controls in the UI.
   */
  hideControls() {
    $('#gallery').removeClass('touch-active');
    $('#quality-adjuster').removeClass('active');
    $('#gallery-share-menu').removeClass('active-bar');
  },

  downloadOriginal() {
    const downloadUrl = '/api/download_original/';
    const photoId = this.photos[this.swipeView.pageIndex].album_photo_id;
    window.location.href = downloadUrl + photoId;
  },
};

// Bind event handlers on site pages that open the gallery

$(() => {
  $('.photos-container').on('click', 'a', (e) => {
    // Clicks on photo thumbnails on album page, open directly to clicked photo.
    e.preventDefault();
    if (e.metaKey || e.ctrlKey) {
      window.open($(e.target).attr('href'), '_blank');
      return;
    }

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.currentTarget),
      startSlide: $(e.currentTarget).parent().index(),
      album_id: $(e.currentTarget).closest('.album-view').data('albumId'),
      origin: $(e.currentTarget),
      albumType: $(e.target).closest('.album-container').data('album-type'),
    });

    SG.gallery.load();
  });

  // On Click Handler for Open Gallery Hexagon Icon
  $(document).on('click', '.gallery-view', (e) => {
    e.preventDefault();

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.currentTarget),
      album_id: $(e.currentTarget).data('albumId'),
      origin: $(e.currentTarget),
      albumType: $(e.currentTarget).data('albumType'),
    });

    SG.gallery.load();
  });

  $('.hero-subheader-image').find('a[data-album-id]').on('click', (e) => {
    e.preventDefault();

    if ($(e.target).closest('form').hasClass('profile-owner')) {
      return;
    }

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.currentTarget),
      startSlide: 1,
      album_id: $(e.currentTarget).data('albumId'),
      origin: $(e.currentTarget),
      albumType: $(e.currentTarget).data('albumType'),
    });

    SG.gallery.load();
  });

  $('.content-box').on('click', 'a.photo', (e) => {
    e.preventDefault();

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.target),
      startSlide: $(e.target).closest('.content-box-content').find('a.photo').index($(e.target)),
      album_id: $(e.target).closest('[data-album-id]').data('albumId'),
      origin: $(e.target),
      albumType: $(e.target).closest('[data-album-type]').data('albumType'),
    });

    SG.gallery.load();
  });

  // On Click Handler to open the images in a message
  $('.content-box').on('click', 'img[data-original]', (e) => {
    e.preventDefault();

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.target),
      startSlide: $(e.target).closest('[data-album-id]').find('img[data-original]').index($(e.target)),
      album_id: $(e.target).closest('[data-album-id]').data('albumId'),
      origin: $(e.target),
      showLikeButton: false,
      albumType: $(e.target).closest('[data-album-type]').data('albumType'),
    });

    SG.gallery.load();
  });

  // On Click Handler to open the preview gallery on the join and unauthenticated album pages
  $('#member-join').on('click', '.join-gallery', (e) => {
    e.preventDefault();

    SG.gallery = new SG.Gallery();

    SG.gallery.init({
      $thumbContainer: $(e.currentTarget),
      album_id: $(e.currentTarget).data('albumId'), // Album id for join album is set in server settings
      origin: $(e.currentTarget),
      albumType: $(e.currentTarget).data('albumType'),
    });

    SG.gallery.load();
  });

  // Automatically open gallery if there is a gallery hash fragment in the URL with album id.
  if (window.location.hash && window.location.hash.match('^#gallery-[0-9]+$')) {
    SG.gallery = new SG.Gallery();
    const albumId = parseInt(window.location.hash.replace('#gallery-', ''), 10);

    SG.gallery.init({
      $thumbContainer: $('.album-container'),
      album_id: albumId,
      origin: $('.album-container'),
    });

    SG.gallery.load();
  }

  // Automatically open gallery to an album photo if there is an ap hash fragment in the URL.
  if (window.location.hash && window.location.hash.match('^#ap[0-9]+$')) {
    SG.gallery = new SG.Gallery();

    const albumPhotoId = parseInt(window.location.hash.replace('#ap', ''), 10);
    const startSlide = $(`li.photo-container[data-album-photo-id=${albumPhotoId}]`).index();

    SG.gallery.init({
      $thumbContainer: $('.album-container'),
      album_id: $('.album-container').data('album-id'),
      origin: $('.album-container'),
      startSlide,
    });

    SG.gallery.load();
  }
});
