var Comment = Backbone.Model.extend({
  /**
   Sync the model data to the server.

   Note: overrides Backbone's default sync method.
   */
  sync: function(method, model, options) {
    var request;

    switch(method) {
      case 'create':
      case 'update':
        request = this.sendRequest();
        break;
      case 'delete':
      case 'read':
      default:
        request = $.Deferred();
        request.reject();
    }

    return request;
  },

  /**
   Send the request to create or edit a comment to the server.
   */
  sendRequest: function() {
    var data = this.assembleFormData();

    return $.ajax({
      type: 'POST',
      url: this.attributes.action,
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: true
      }
    });
  },

  /**
   Assemble the form data into the object sent to server.
   */
  assembleFormData: function() {
    var data = {
      text: this.attributes.text,
      noteditable: this.attributes.noteditable
    };

    if (this.attributes.photos) {
      var photo_data = [];

      _.each(this.attributes.photos, function(photo) {
	photo_data.push({
          id: photo.get('id'),
          album_photo_id: photo.get('album_photo_id'),
          caption: photo.get('caption'),
          number: photo.get('number'),
          isCover: photo.get('isCover'),
          delete: photo.get('delete'),
          fpfilekey: photo.get('key')
	});
      });

      if (photo_data) {
	data['photos'] = photo_data;
      }
    }

    return data;
  }
});
