var CreatePlainTextCommentView = EditContentView.extend({
  spinnerOnSave: true,

  events: {
    'blur [contenteditable]': 'blurEvent',
    'keypress [contenteditable]': 'textChangedEvent',
    'paste [contenteditable]': 'textChangedEvent'
  },

  /**
     Initializes the view.
   */
  initialize: function(options) {
    this.model = new Comment();
    EditContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditContentView);
  },

  /**
     Activate the comment form.
   */
  activate: function() {
    this.$el.addClass('active');
    SG.reLayout();
  },

  /**
     Deactivates the comments text box, returning it to its default state.
   */
  deactivate: function () {
    this.$el.removeClass('active');
    this.$el.find('.comment-count').text(this.$el.data('commentCount'));
  },

  /**
     Blur event when the user clicks outside of the editor.
  */
  blurEvent: function(e) {
    this.syncFormToModel(this.$el, this.model);
    if (!this.model.get('text')) {
      this.deactivate();
    }
    SG.reLayout();
  },

  /**
     Event handler when the user types or pastes text into the editable area.

     @param {object} e - JQuery event data.
  */
  textChangedEvent: function(e) {
    if (SG.settings.isotope) {
      if ($(e.target).data('lastHeight') !== undefined && $(e.target).data('lastHeight') !== $(e.target).height()) {
        SG.reLayout();
      }
      if (e.type == 'paste') {
        setTimeout(SG.reLayout, 10); // Needs a timer otherwise it fires BEFORE the text is in the box.
      }
      $(e.target).data('lastHeight', $(e.target).height());
    }

    if (e.which == 13) {
      e.preventDefault();
      this.$el.find('button[type="submit"]').trigger('click');
      return false;
    }
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function($form, model) {
    model.set('action', $form.attr('action'));
    // As workaround for Edge, initial value is now &nbsp;, trim removes it.
    model.set('text', $.trim(this.$el.find('[contenteditable]').text()));
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function (response, textStatus, jqXHR) {
    var $newComment = $(response).addClass('collapsed');
    var $contentBox = this.$el.closest('.content-box');
    var $commentCounts = $contentBox.find('.comment-count');
    var $viewing = $contentBox.find('.viewing-count');
    var remainderCount = parseInt($viewing.text());
    var viewingCount = parseInt($viewing.attr("data-viewing-count"));
    var commentCount = parseInt($commentCounts.data('commentCount'));

    commentCount++;
    viewingCount++;
    remainderCount++;

    this.$el.siblings('.comments').append($newComment);

    setTimeout(function() {
      $newComment.addClass('uncollapsed');
    }, 0);

    this.$el.find('input').val('');
    // As workaround for Edge, initial value is now &nbsp;
    this.$el.find('[contenteditable]').html('&nbsp;');

    SG.reLayout();

    $commentCounts.text(commentCount).data('commentCount', commentCount);
    $viewing.attr("data-viewing-count", viewingCount);

    if (commentCount == remainderCount) {
      $viewing.text(remainderCount);
    }

    this.$el.siblings('.comments').removeClass('no-comments');
    this.$el.find('.comment').blur();

    SG.createTextLinks($newComment.find('div.comment-text')); //at mentions and urls to links

    this.deactivate(this.$el);
    this.$el.find('textarea').redactor('destroy');
    $('.redactor_comment').empty();
    this.$el.find('textarea').val('');
    this.$el.find('.progress').removeClass('visible');
  }
});
