var EditPhotoReleaseAlbumView = EditAlbumView.extend({
  /**
   Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {
    multiple: false
  },

  /**
   Thumbnail geometry size to use for showing the drag & drop thumbnails, in order of preference.
   */
  thumbnailGeometries: ['110x56', 'original'],

  /**
   Event handlers for the view.
   */
  events: {
    'click .photorelease-create': 'uploadPhotoRelease'
  },

  /**
   Initialize the view.
   */
  initialize: function(options) {
    this.events = _.extend({}, EditAlbumView.prototype.events, this.events);
    EditAlbumView.prototype.initialize.apply(this, [options]);
  },

  /**
   User clicks the upload photo release button.
   */
  uploadPhotoRelease: function(e) {
    if (this.options.checkUploadingAllowedMethod && !this.options.checkUploadingAllowedMethod()) {
      SG.userWarning('Please wait for photos to upload');
      return;
    }

    $('.button.photorelease-create').hide();
    this.launchFilepicker();
  }
});
