var SG = SG || {};

SG.Post = {
  /**
     Handles the create content modal being opened by the user.

     Note: this is run indirectly by a click handler in SG.globalBind, via SG.bindCreateContent.
   */
  modalOpenedHandler: function ($scope) {
    SG.bindCreateContent($scope);
    SG.Post.createStatusUpdateView = new CreateStatusUpdateView({el: $scope.find('.createStatusForm')});
    $scope.find('#full-blog').on('click', _.bind(this.showFullBlogClickEvent, this));

    // Activate form when user clicks on textarea
    var self = this;

    $scope.find('textarea').on('click', function (e) {
      e.preventDefault();

      SG.Post.createBlogPostView.activate();

      if (!$scope.find('input[name="title"]').first().data('focused')) {
        $scope.find('input[name="title"]').first().focus().data('focused', true);
      }
    });

    $scope.find('.photo-upload-start').on('click', _.bind(self.createPhotoPostClickEvent, self));
  },

  /**
     Handles the create content modal being closed by the user.
   */
  modalClosedHandler: function($scope) {
    if (SG.Post.createBlogPostView) {
      SG.Post.createBlogPostView.destroy();
    }

    $('#filepicker-package').appendTo('body');
    $scope.remove();
  },

  /**
     Handles click events on show full blog button
  */
  showFullBlogClickEvent: function (e) {
    e.preventDefault();
    e.stopPropagation();

    SG.Post.createStatusUpdateView.destroy();

    $('#create-content-modal .createBlogPostForm').show();

    SG.Post.createBlogPostView = new CreateBlogPostView({
      el: $('#create-content-modal .createBlogPostForm')
    });

    SG.Post.createBlogPostView.activate();
    SG.Post.createBlogPostView.editor.focus();
  },


  /**
     Handles click events on the create photo post camera icon in the sitewide template.
  */
  createPhotoPostClickEvent: function (e) {
    e.preventDefault();
    var blogAlbum = new Album();
    var creatingNewAlbum = blogAlbum.createNewAlbumSuite($(e.target));

    creatingNewAlbum.done(function (data) {
      window.location.reload();
    });

    return creatingNewAlbum;
  }
};

$(document).ready(function () {
  // If they are on the page already there is no refresh for #edit anchor
  $('.post-edit').on('click', function (e) {
    e.preventDefault();

    if (window.location.pathname == $(this).attr('href').split('#')[0]) {
      window.location.replace($(this).attr('href'));
      window.location.reload();
    } else {
      window.location.replace($(this).attr('href'));
    }
  });
});

/**
   Handles clicks on the delete option in the admin bar for
   blog post content items.
*/
$('body').on('click', '.post-delete', function () {
  if (!SG.apiPrompt()) {
    return;
  }

  var $article = $(this).closest('article');

  var model = new BlogPost({
    id: $(this).attr('post_id'),
    action: $(this).attr('action')
  });

  model.destroy()
    .done(function () {
      $article.remove();
      SG.isotope.isotope('remove', $article);
      SG.reLayout();
    });
});

$('body').on('click', '.teaser:not(.active)', function (e) {
  e.preventDefault();
  $(this).addClass('active');
  SG.reLayout();
});

$('body').on('click', '.spoiler a', function (e) {
  var $that = $(this);
  e.preventDefault();
  SG.$elements.$articleFeed.imagesLoaded(function () {
    $that.parent().find('div').show();
    $that.hide();
    SG.reLayout();
  });
});

$('body').on('focus', '.redactor_blogText:not(#editForm .redactor_blogText),.blogTitle', function() {
  SG.activeEditable = SG.Post;
});
