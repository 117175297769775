/* eslint-disable */

var SubmitPhotosetView = EditAlbumView.extend({
  // This view handles SGs and Hopeful's uploading photosets. It's used by
  // the model > application > photoset FE template, and is initialized when
  // the "Upload Photoset button is clicked".

  events: {
    'click #sgmodels .icon-close': 'removeContributor'
  },

  initialize: function(options) {
    this.events = _.extend({}, EditAlbumView.prototype.events, this.events);
    EditAlbumView.prototype.initialize.apply(this, [options]);
    this.loadAutocompletePlugins();
    this._ensureElement(); // Re-delegate events
    this.initializePhotoReleaseView();
    this.$el.parent().on('click', '#save-photoset', _.bind(this.submitPhotoset, this));
  },

  /**
   Load the autocomplete plugins for model name and photographer.
   */
  loadAutocompletePlugins: function() {
    var self = this;

    $('.sgmodel_name').autocomplete({
      source: $("#sgmodel_name_search_url").val(),
      appendTo: $('.sgmodel_name').parent(),
      minLength: 1,

      select: function(e, ui) {
        var username = ui.item.value;
        $(this).val(username).blur();
      },

      change: function(e, ui) {
        self.selectSGModel();
      },

      messages: {
        noResults: '',
        results: function() {}
      }
    })
    .data("uiAutocomplete")._renderItem = function (ul, item) { //this customizes the autocomplete render li
      var inner_html = '<a><div class="autocomplete-avatar">' + item.label + '</div></a>';

      return $("<li></li>")
        .data("item.autocomplete", item)
        .append(inner_html)
        .appendTo(ul);
    };

    $('#id_photographer').autocomplete({
      source: $("#photographer_name_search_url").val(),
      minLength: 1,
      appendTo: $('.id_photographer').parent(),

      select: function(e, ui) {
        var username = ui.item.value;
        $(this).val(username).blur();
        $('#photo-release-form').hide();
        $('label[for=id_photographer_url]').hide();
        $('#id_photographer_url').hide();
      },

      response: function(e, ui) {
        if (ui.content.length < 1) {  // In case the photographer is not matching we need to ask for photorelease
          $('#photo-release-form').show();
          $('label[for=id_photographer_url]').show();
          $('#id_photographer_url').show();
        }
      },

      messages: {
        noResults: '',
        results: function() {}
      }
    })
    .data("uiAutocomplete")._renderItem = function (ul, item) { // This customizes the autocomplete render li
      var inner_html = '<a><div class="autocomplete-avatar">' + item.label + '</div></a>';

      return $("<li></li>")
        .data("item.autocomplete", item)
        .append(inner_html)
        .appendTo(ul);
    };
  },

  /**
   User selects a model name in the existing girls autocomplete dropdown.
   */
  selectSGModel: function() {
    var username = $('.sgmodel_name').val();
    username = username.toLowerCase();

    var userCheck = SG.apiGet({
      url: $("#single_sgmodel_search_url").val() + '?term=' + username
    });

    userCheck.done(function(data) {
      // Make sure user exists
      var user = false;

      $.each(data, function(i, val) {
        if (username === val.label) {
          user = val;
        }
      });

      if (!user) {
        // Revert
        $('.sgmodel_name').focus();
        SG.userError('User ' + username + ' does not exist');
      } else {
        // Change user in left
        $('#sgmodels').append(
          '<div class="single-contributor">' +
            '<span class="img-wrap" style="float:left;">' +
              '<img src="' + user.cover_photo.urls["40x40"] + '" retina-src="' + user.cover_photo.urls["80x80"] + '" width="40" />' +
            '</span>' +
            '<span class="model_username">' + user.label + '</span>' +
            '<div class="close icon-close" data-username="'+user.label+'"></div>' +
          '</div>'
        ).show();
      }

      $("#id_contributors").val($("#id_contributors").val() + username + '|');
    });

    userCheck.fail(function() {
      SG.userError('There was an error verifying this username');
    });
  },

  /**
   User clicks the icon to remove a contributor from the set.
   */
  removeContributor: function(e) {
    var contributor = $(e.target).attr("data-username");
    $("#id_contributors").val($("#id_contributors").val().replace(contributor + '|',''));
    $(e.target).parent().remove();
  },

  /**
   User clicks button to add more photos.

   Note: overriding parent to prevent launching filepicker for photos if a release is uploading,
   since only one Filepicker instance may be active at a time (even if it is hidden).
   */
  addMorePhotos: function(e) {
    if (!this.editPhotoReleaseAlbumView.collection.checkAllPhotosComplete()) {
      SG.userWarning('Please wait for photo release to upload');
      return;
    };

    EditAlbumView.prototype.addMorePhotos.apply(this, [e]);
  },

  /**
   Initializes the photo release view.

   Note: the photo release view is a child view, a separate edit album view
   for uploading the photo release document when submitting a photoset.
   */
  initializePhotoReleaseView: function() {
    var album = new Album();

    var model = new AlbumModel({
      type: SG.ALBUM_TYPE_SGMODEL_PHOTO_RELEASE,
      privacy: 'private',
      status: 'open',
      admin_created: this.options.admin_created
    });

    var collection = new PhotoCollection([], {
      album: album,
      albumType: 'sgmodel_photo_release'
    });

    var view = new EditPhotoReleaseAlbumView({
      el: $('#photo-release-form'),
      model: model,
      collection: collection,
      type: 'sgmodel_photo_release',
      checkUploadingAllowedMethod: _.bind(this.collection.checkAllPhotosComplete, this.collection),
      album: album,
      masterDeferred: $.Deferred()
    });

    this.editPhotoReleaseAlbumView = view;
  },

  /**
   User clicks the submit photoset button.
   */
  submitPhotoset: function(e) {
    e.preventDefault();

    if (!$("#id_contributors").val()) {
      SG.userError("You must provide the SuicideGirl's name");
      return;
    }

    var self = this;

    this.validateSGModelName()
      .then(function() {
        self.validateContributors()
          .then(function() {
            self.savePhotoRelease(e)
              .then(function() {
                self.submitForm(e);
              });
          });
      });
  },

  /**
   Checks whether a photo release is required for the current selections.
   */
  requiresPhotoRelease: function() {
    return !!$("#photo-release").html();
  },

  /**
   Validate that the sgmodel name is valid and authorized to submit photosets.
   */
  validateSGModelName: function(sgmodelName) {
    var ajaxArgs = {
      async: false,
      dataType: 'json',
      data: {
        sgmodel_name: sgmodelName
      },
      url: $("#sgmodel_name_validate_url").val()
    };

    var xhr = SG.apiPost(ajaxArgs);

    xhr.fail(function(data) {
      if (data.Error) {
        SG.userError(data['message']);
      }
    });

    return xhr;
  },

  validateContributors: function() {
    var contributors_obj = $("#id_contributors");
    var sgmodel_names = contributors_obj.val();
    var valid_sgmodel_name = false;

    var ajaxArgs = {
      async: false,
      dataType: 'json',
      data: {'contributors': sgmodel_names},
      url: $("#validate_photoset_contributors").val()
    };

    var xhr = SG.apiPost(ajaxArgs).done(function(data) {
      if (data.Error) {
        SG.userError(data['message']);
      }
    });

    return xhr;
  },

  /**
   Save the photo release in the child edit album view.
   */
  savePhotoRelease: function(e) {
    var promise = $.Deferred();

    if (this.requiresPhotoRelease()) {
      this.editPhotoReleaseAlbumView.once('saved', function() {
        promise.resolve();
      });
      this.editPhotoReleaseAlbumView.submitForm(e);
    } else {
      promise.resolve();
    }

    return promise;
  },

  /**
   Sync the form fields to the model.
   */
  syncFormToModel: function(e) {
    EditAlbumView.prototype.syncFormToModel.apply(this, []);
    this.model.set('contributors', $("#id_contributors").val());
    this.model.set('photographer', $("#id_photographer").val());
    this.model.set('photographer_url', $("#id_photographer_url").val());
    this.model.set('photo_release_id', this.editPhotoReleaseAlbumView.model.get('id'));
  },

  /**
   Mark everything finished and redirect to the edit view for this photoset.
   */
  finishSuccessfully: function() {
    EditAlbumView.prototype.finishSuccessfully.apply(this, []);
    var photosetId = this.model.id;

    this.loadCropTool().then(function() {
      var sgmodelId = $("div.feed").data("sgmodel_id");
      // sgmodelId will be present if the user is uploading their own set, but
      // not if a staff member is uploading on behalf of someone else
      if (sgmodelId) {
        var url = '/model/application/' + sgmodelId + '/photoset/' + photosetId +'/edit/';
        window.location.href = url;
      } else {
        location.reload();
      }
    })
  },

  /**
   Loads the crop tool to crop the cover image.
   */
  loadCropTool: function() {
    SG.crop.init({
      albumID: this.model.get('id'),
      carousel: true
    });

    return SG.crop.modal;
  }
});
