var EditThreadView = EditRichTextContentView.extend({
  msgSaveSuccess: 'Thread successfully saved.',

  /**
     Event handlers for the view.
   */
  events: {
    'focus .redactor_edit': 'focusEvent'
  },

  /**
     Initializes the thread module.
   */
  initialize: function (options) {
    this.model = new Thread();
    this.editor = new EditorView({
      el: this.$el.find('textarea'),
      albumType: 'group',
      thumbnailGeometries: ['508'],
      savedDraftPrefix: 'thread'
    });
    EditRichTextContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditThreadView);
  },

  /**
     Loads the edit thread interface on the edit thread pages.
  */
  loadEditInterface: function() {
    this.model.set('id', this.$el.data('post-id'));
    if (this.$el.data('album-id')) {
      this.editor.collection.loadFromAlbum(this.$el.data('album-id'));
    }
    this.editor.load();
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function($form, model) {
    model.set('id', $form.data('post-id'));
    model.set('action', $form.attr('action'));
    model.set('title', $form.find('[name=title]').val());
    model.set('text', this.editor.getText());
    model.set('user', SG.user.logged_in_user_id);
    model.set('photos', this.editor.collection.models);
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function (response, textStatus, jqXHR) {
    setTimeout(function () {
      var url_to_redirect_to = window.location.href.split('edit/')[0];
      window.location.replace(url_to_redirect_to);
    }, 1000);
  }
});
