{% verbatim %}

<div class="photo-upload-error">
  <div class="question-mark">!</div>
  <p>The file you uploaded is corrupt or not supported:</p>

  <ul>
    {{#each failedFilenames}}
    <li>{{this}}</li>
    {{/each}}
  </ul>
</div>

{% endverbatim %}
