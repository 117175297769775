var SG = SG || {};

//usage so far
$(function() {
  $('#content-column, .album-view, .edit-album').on('click', '.crop-modal', function(e) {
    e.preventDefault();
    var upgrade_to_sotd = ($(this).data('upgrade-to-sotd') === 'True');
    SG.crop.init({
      stageRatio : $(this).data('ratio'),
      albumID : $(this).data('albumId'),
      carousel : true,
      upgrade : upgrade_to_sotd
    });
  });
});


SG.crop = {
  config : {
    //defaults
    stageRatio : 16/9,
    albumID : null,
    carousel : false,
    maxScale : 4,
    photoIndex : 0,
    upgrade : false
  },
  init : function(config) {
    var that = this;
    $.extend(that.config, config);

    that.initializing = new $.Deferred();
    that.$container = $('#cropper');

    //initialize modal
    this.modal = SG.initModal(that.$container, 'cropper');

    this.modal.done(function() {
      //fires when modal closes.
      $('body').append($('#cropper'));
      that.stage.destroy();
      $('#crop-slider').remove();
    });


    //allow space for slider
    this.stageWidth = $('#modal-container').find('.modal-inner').width() - 50;

    if (that.config.carousel) that.initCarousel();

    //initialize noUiSlider
    $('#crop-canvas').after('<div id="crop-slider" class=""></div>');
    this.uiSlider = $('#crop-slider').noUiSlider({
      range : [1, that.config.maxScale],
      start : that.config.maxScale,
      orientation: 'vertical',
      handles: 1,
      slide : function() {
        that.uiSliderChangeFunction();
      },
      connect : 'lower'
    }).height(that.stageWidth / that.config.stageRatio - 75); //75 less for the + / -



    //put together the stage
    this.initKinetic();

    this.bindControls();
    //spinn!!
    console.log('spinning');
    $('#cropper').spinner();
    that.initializing.done(function() {
      $('#cropper').clearSpinner();
    });
  },

  bindControls : function() {
    var that = this;
    var lastDist = 0;
    var startScale = 1;
    var uiSliderStepInterval = (that.config.maxScale - 1) / 10;
    $('#crop-scale-up').on('click', function() {

      var oldValue = that.uiSlider.val();
      that.uiSlider.val(oldValue - uiSliderStepInterval);
      that.uiSliderChangeFunction();
    });
    $('#crop-scale-down').on('click', function() {

      var oldValue = that.uiSlider.val();
      that.uiSlider.val(oldValue + uiSliderStepInterval);
      that.uiSliderChangeFunction();
    });

    $('#crop-submit').one('click', function() {
      that.submitCropRegion();
    });

    that.stage.getContent().addEventListener('touchmove', function(evt) {
      var getDistance = function(p1, p2) {
        return Math.sqrt(Math.pow((p2.x - p1.x), 2) + Math.pow((p2.y - p1.y), 2));
      };
      var touch1 = evt.touches[0];
      var touch2 = evt.touches[1];

      if(touch1 && touch2) {
        var dist = getDistance({
          x: touch1.clientX,
          y: touch1.clientY
        }, {
          x: touch2.clientX,
          y: touch2.clientY
        });

        if(!lastDist) {
          lastDist = dist;
        }

        var scale = that.kineticImage.getScale().x * dist / lastDist;

        //scale bounds 1 - maxScale;
        scale = scale < 1 ? 1 : scale > that.config.maxScale ? that.config.maxScale : scale;
        //update slider
        that.uiSlider.val(that.config.maxScale + 1 - scale);

        that.kineticImage.setScale(scale);
        that.stage.draw();
        lastDist = dist;
      }
    }, false);

    that.stage.getContent().addEventListener('touchend', function() {
      lastDist = 0;
    }, false);

  },

  uiSliderChangeFunction : function() {
    var that = this;
    var value = $('#crop-slider').find('input').val();
    //invert the slider value (bottom to top instead of opposite)
    value = 1 + that.config.maxScale - value;


    //set the scale of the imgGroup
    that.kineticImage.setScale(value, value);

    //adjust the new position if space has been made by scaling down
    //imgGroup.getX() and Y represent the initial offset.
    newPos = that.adjustPosition({
      x : SG.crop.imgGroup.getX() + SG.crop.kineticImage.getX(),
      y : SG.crop.imgGroup.getY() + SG.crop.kineticImage.getY()
    });

    that.kineticImage.setPosition({
      x : newPos.x - SG.crop.imgGroup.getX(),
      y : newPos.y - SG.crop.imgGroup.getY()
    });

    //draw
    that.stage.draw();
    if (SG.settings.DEBUG) $('#crop-debugger').trigger('changeroo');
  },
  initKinetic : function() {
    var that = this;

    //make kinetic stage
    this.stage = new Kinetic.Stage({
      container: 'crop-canvas',
      width: this.stageWidth,
      height: this.stageWidth / this.config.stageRatio
    });

    //make kinetic layer
    this.layer = new Kinetic.Layer();

    //make kinetic group
    this.imgGroup = new Kinetic.Group({
      x: 0,
      y: 0
    });

    if (that.config.albumID) {

      that.album_deferred = that.getAlbumInfo();
      var everything = that.album_deferred.then(function() {
        console.log('hhhhhhhhhhhiiiiiiiiiiiii');
        console.log(that.coverPhoto);
        return that.addImageToGroup(that.coverPhoto.urls.original);
      });
      everything.done(function() {
        that.initializing.resolve();
      });
      everything.fail(function() {
        SG.userError('There was an internal problem; please try again later.');
        that.modal.resolve();
      });
    } else {
      that.initializing.resolve();
    }




    that.layer.add(that.imgGroup);

    that.stage.add(that.layer);
  },

  addImageToGroup : function(src) {
    var deferred = new $.Deferred();
    var that = this;

    //make an image object
    var imageObj = new Image();
    imageObj.src = src;

    imageObj.onload = function() {
      //remove image if there's a previous one.
      that.originalWidth = imageObj.width;
      that.originalHeight = imageObj.height;
      that.imgGroup.destroyChildren();
      that.stage.draw();


      //when image is ready, let's add it to the group with some aspect ratio consideration
      var kineticOptions;
      that.stageHeight = that.stageWidth / that.config.stageRatio;

      that.imageRatio = this.width / this.height;

      if (that.config.stageRatio > that.imageRatio) {
        //our image has a taller aspect ratio than the stage
        console.log('Maximize width, scale height.');
        var newHeight = that.stageWidth / that.imageRatio;
        kineticOptions = {
          x : 0,
          y : 0,
          //offset sets center for scaling
          offset : [that.stageWidth / 2, newHeight / 2],
          width : that.stageWidth,
          height : newHeight
        };
        that.imgGroup.setX(that.stageWidth / 2);
        that.imgGroup.setY(that.stageHeight / 2);
        that.originalScale = that.stageWidth / this.width;

      } else {
        //our image has a wider aspect ratio
        console.log('Maximize height, scale width.');
        var newWidth = that.stageHeight * that.imageRatio;
        kineticOptions = {
          x : 0,
          y : 0,
          offset : [newWidth / 2, that.stageHeight / 2],
          width : newWidth,
          height : that.stageHeight
        };
        that.imgGroup.setX(that.stageWidth / 2);
        that.imgGroup.setY(that.stageHeight / 2);
        that.originalScale = that.stageHeight / this.height;
      }
      kineticOptions.image = imageObj;
      kineticOptions.draggable = true,
      kineticOptions.dragBoundFunc = function(pos) {
        return that.adjustPosition(pos);
      };

      that.kineticImage = new Kinetic.Image(kineticOptions);
      that.imgGroup.add(that.kineticImage);
      if (SG.settings.DEBUG) {
        that.debug();
      }
      that.stage.draw();
      deferred.resolve();
    };

    return deferred;

  },

  adjustPosition : function(pos) {
    var that = this;

    //adjust if position becomes positive (space on the left or top)
    var newY = pos.y >= that.getImageActualSize().height / 2 ? that.getImageActualSize().height / 2 : pos.y;
    var newX = pos.x >= that.getImageActualSize().width / 2 ? that.getImageActualSize().width / 2 : pos.x;

    //adjust if there's space on the right or bottom
    var rightThreshold = that.stageWidth - that.getImageActualSize().width / 2;
    if (pos.x <= rightThreshold) newX = rightThreshold;

    var bottomThreshold = that.stageHeight - that.getImageActualSize().height / 2;
    if (pos.y <= bottomThreshold) newY = bottomThreshold;

    return {
      x: newX,
      y: newY
    };
  },

  getImageActualSize : function() {
    var that = this;
    return {
      width : that.kineticImage.getWidth() * that.kineticImage.getScale().x,
      height : that.kineticImage.getHeight() * that.kineticImage.getScale().x
    };
  },

  getAlbumInfo : function() {
    var that = this;

    var getting_album_info = $.ajax({
      url : '/api/get_album_info/' + that.config.albumID + '/'
    });

    getting_album_info.done(function(data) {
      that.album_info = data;
      that.getCoverPhoto();
    });

    getting_album_info.always(function() {

    });
    return getting_album_info;
  },

  getCoverPhoto : function() {
    var that = this;
    $.each(that.album_info.photos, function(i) {
      if (that.album_info.photos[i].isCover) {
        that.coverPhoto = that.album_info.photos[i];
        that.config.photoIndex = i;
        return;
      }

    });
  },

  initCarousel : function() {
    var that = this;
    //that.album_deferred = that.getAlbumInfo();
    // Adding the carousel buttons only if they are haven't been added before
    if (that.$container.find('#crop-next').length === 0) {
      that.$container.append('<div class="carousel-nav icon-arrow-right" id="crop-next" /><div class="carousel-nav carousel-nav icon-arrow-left" id="crop-prev" />');
      $('#crop-next').on('click', function() {
        that.carouselNext();
      });
      $('#crop-prev').on('click', function() {
        that.carouselPrev();
      });
    }
  },

  carouselNext : function() {
    var that = this;
    //clear image

    console.log('spinning');
    $('#cropper').spinner();

    //make sure we have album info
    that.album_deferred.done(function(data) {
      //move the photo index, reset to 0 if it's past the end.
      if (that.album_info.photos.length <= ++that.config.photoIndex) that.config.photoIndex = 0;
      that.uiSlider.val(that.config.maxScale);
      //addImage resolves deferred when the image has loaded.
      var imageLoading = that.addImageToGroup(that.album_info.photos[that.config.photoIndex].urls.original);
      imageLoading.done(function() {
        $('#cropper').clearSpinner();
      });
    });
  },

  carouselPrev : function() {
    var that = this;
    //clear image
    that.kineticImage.destroy();
    that.stage.draw();

    $('#cropper').spinner();

    //make sure we have album info
    that.album_deferred.done(function(data) {
      //move the photo index, wrap to last image if it's at the end.
      if (0 > --that.config.photoIndex) that.config.photoIndex = that.album_info.photos.length - 1;
      that.uiSlider.val(that.config.maxScale);
      //addImage resolves deferred when the image has loaded.
      var imageLoading = that.addImageToGroup(that.album_info.photos[that.config.photoIndex].urls.original);
      imageLoading.done(function() {
        $('#cropper').clearSpinner();
      });
    });
  },

  getEndpointValues : function() {
    var that = this;
    var realScale = that.originalScale * that.kineticImage.getScale().x;

    data = {
      //for some reason getX() returns a difference rather than an offset...
      x_offset : '' + Math.round(((that.getImageActualSize().width - SG.crop.stageWidth) / 2 - SG.crop.kineticImage.getX()) / realScale)  ,
      y_offset : '' + Math.round(((that.getImageActualSize().height - SG.crop.stageHeight) / 2 - SG.crop.kineticImage.getY()) / realScale) ,
      width : '' + Math.round(that.stageWidth / (realScale)),
      height : '' + Math.round(that.stageHeight / (realScale)),
      albumphoto_id : that.album_info.photos[that.config.photoIndex].album_photo_id,
      album_id : that.config.albumID,
      upgrade_to_sotd : that.config.upgrade
    };
    return data;
  },

  submitCropRegion : function() {
    var that = this,
      data = that.getEndpointValues();



    var submitting = $.ajax({
      url : '/api/set_cover_photo/',
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=UTF-8'
    });

    $('#cropper').spinner();

    submitting.always(function() {
      $('#cropper').clearSpinner();
    }).done(function(response) {
      that.modal.resolve(response);
      SG.userMessage('Crop region and cover photo successfully submitted. Changes will not be reflected immediately.');
    }).fail(function(data) {
      that.modal.resolve(data);
      SG.userError(data.response);

    });
    return submitting;
  },

  debug : function() {

    //utility debugger
    var that = this;
    var debuggerMarkup = '' +
      '<div id="crop-debugger">' +
        '<div id="crop-debugger-width">' +
        '</div>' +
        '<div id="crop-debugger-height">' +
        '</div>' +
        '<div id="crop-debugger-oscale">' +
        '</div>' +
        '<div id="crop-debugger-kscale">' +
        '</div>' +

        '<div id="crop-debugger-scale">' +
        '</div>' +

        '<div id="crop-debugger-x">' +
        '</div>' +

        '<div id="crop-debugger-y">' +

        '</div>' +
        '<div id="crop-debugger-original">' +
        '</div>' +

      '</div>';

    $('.modal-inner').append($(debuggerMarkup));

    $('#crop-debugger').on('changeroo', function() {
      if (!SG.settings.DEBUG) return;
      var endpoint = that.getEndpointValues();
      $('#crop-debugger-width').text('width: ' + endpoint.width);
      $('#crop-debugger-height').text('height: ' + endpoint.height);
      $('#crop-debugger-oscale').text('o-scale: ' + that.originalScale);
      $('#crop-debugger-kscale').text('k-scale: ' + that.kineticImage.getScale().x);
      $('#crop-debugger-scale').text('real-scale: ' + that.originalScale * that.kineticImage.getScale().x);
      $('#crop-debugger-x').text('x: ' + endpoint.x_offset);
      $('#crop-debugger-y').text('y: ' + endpoint.y_offset);
      $('#crop-debugger-original').text('original: ' + that.originalWidth + 'x' + that.originalHeight);

    });
    that.imgGroup.on('dragmove', function() {
      $('#crop-debugger').trigger('changeroo');
    });
  }

};//SG.crop


