/**
   Abstract base view for posting rich-text based content items.

   This is intended to be the base class for posting rich-text content,
   with the basic format of a rich-text/redactor editor, with some related
   meta-data fields, such as privacy.
*/
var EditRichTextContentView = EditContentView.extend({
  spinnerOnSave: false,

  events: {
    'click .discard': 'discardDraftEvent',
    'click .dismiss': 'dismissDraftMessageEvent'
  },

  /**
     Initializes the view.
   */
  initialize: function (options) {
    this.events = _.extend({}, EditContentView.prototype.events, EditRichTextContentView.prototype.events);
    this._ensureElement();
  },

  /**
     User clicks the discard draft button.

     @param {object} e - JQuery event data.
   */
  discardDraftEvent: function(e) {
    e.preventDefault();
    e.stopPropagation();
    this.hideDraftMessageBar();
    this.editor.discardDraft();
    this.$el.find('.blogTitle').val('');
  },

  /**
     User clicks the X icon to dismiss the restored from draft message, keeping the draft.

     @param {object} e - JQuery event data.
   */
  dismissDraftMessageEvent: function(e) {
    e.preventDefault();
    this.hideDraftMessageBar();
  },

  hideDraftMessageBar: function() {
    this.$el.removeClass('restore-option');
  },

  /**
     Checks to see if the uploaded photos are ready to submit.

     @returns {Boolean} Whether the user should be allowed to submit the form yet or not.
   */
  isReadyToSubmit: function() {
    return this.editor.collection.checkAllPhotosComplete();
  },

  /**
     Event handler when the form is submitted.

     @param {object} e - JQuery event data.
   */
  formSubmitEvent: function (e) {
    e.preventDefault();

    this.syncInputValues();
    this.editor.removePhotosNotInEditor();

    if (this.editor.collection.hasUploadJobs()) {
      SG.userWarning('Your photos are still being processed. Please try again.');
      this.editor.collection.restartPollingUploadJobStatus();
      return;
    }

    this.save();
  },

  /**
     Syncs the value in the traditional text input with value from content editable input.
   */
  syncInputValues: function () {
    this.$el.find('[name="text"]').val(this.$el.find('[contenteditable]').text());
  },

  /**
     Callback when the post has been successfully saved.
   */
  saveSuccess: function(response, textStatus, jqXHR) {
    if (this.spinnerOnSave) {
      SG.clearSpinner();
    }
    SG.userMessage(this.msgSaveSuccess);
    this.editor.reset();
    this.refreshPage(response, textStatus, jqXHR);
  },

  /**
     Gets the Filepicker keys of the failed photos.
   */
  getFailedKeys: function (failedPhotos) {
    var failedKeys = [];
    var key;
    var idx;

    for (idx in failedPhotos) {
      key = failedPhotos[idx].get('key');
      key = key.split("/")[1].split('_')[0];
      failedKeys.push(key);
    }

    return failedKeys;
  }
});
