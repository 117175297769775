/*
  * jQuery Canvas Spinner Plugin
  * version: 1.1
  * Author: Ollie Relph
  * http://github.com/BBB/jquery-canvasspinner
  * Copyright: Copyright 2010 Ollie Relph
  */ 
 (function($) {
  $.fn.spinner = function(settings) {

    settings = $.extend({
      sqr: undefined,
      framerate : 10,
      spokeCount : 16,
      rotation : 0,
      spokeOffset : {
        inner : undefined,
        outer : undefined
      },
      spokeWidth : undefined,
      colour : '255,255,255',
      backup : 'images/spinner.gif',
      centered : true
    }, settings || {});

    return this.each(function () {

      var $this = $(this),
        width = $this.width(),
        height = $this.outerHeight(),
        ctx,
        hsqr,
        $wrap,
        $canv;
      width = width > 200 ? 200 : width;
      height = height > 200 ? 200 : height;
      settings.sqr = Math.round(width >= height ? height : width);
      hsqr = settings.sqr/2;
      // convert from deg to rad
      settings.rotation = settings.rotation/180 * Math.PI;
      settings.spokeOffset.inner = settings.spokeOffset.inner || hsqr * 0.3;
      settings.spokeOffset.outer = settings.spokeOffset.outer || hsqr * 0.6;


      $wrap = $('<div id="spinner-' + $.fn.spinner.count + '" class="spinner" />');
      if (settings.centered) {
        $wrap.css({'position' : 'absolute', 'z-index' : 999, 'left' : '50%', 'top' : '50%', 'margin' : hsqr /2 * -1 + 'px 0 0 ' + hsqr/2 * -1 + 'px', 'width' : settings.sqr /2, 'height' : settings.sqr /2 });
      }
        // $canv = $('<canvas />').attr({ 
        //     'width' : settings.sqr, 
        //     'height' : settings.sqr 
        //   }).css({
        //     width: settings.sqr / 2,
        //     height: settings.sqr / 2
        //   });

      if ( $this.css('position') === 'static' && settings.centered ) {
        $this.css({ 'position' : 'relative' });
      }


      if ($(this).prop('tagName') === 'BODY') {
        var $shield = $('<div id="spinner-container" />').appendTo($('<div id="shield" />').appendTo($(this)));
        //$canv.appendTo($wrap);
        $wrap.appendTo($shield);
      } else {
        //$canv.appendTo($wrap);
        $wrap.appendTo($this);
      }




      // if ( $canv[0].getContext ){  
      //   ctx = $canv[0].getContext('2d');
      //   ctx.translate(hsqr, hsqr);
      //   ctx.lineWidth = settings.spokeWidth || Math.ceil(settings.sqr * 0.025);
      //   ctx.lineCap = 'round'
      //   this.loop = setInterval(drawSpinner, 1000/ settings.framerate);
      // } else {
      //   // show a backup image...
      //   $canv.remove();
      //   $wrap.css({ 'background-image' : 'url(' + settings.backup + ')', 'background-position' : 'center center', 'background-repeat' : 'none'})
      // }

      // function drawSpinner () {
      //   ctx.clearRect(hsqr * -1, hsqr * -1, settings.sqr, settings.sqr);
      //   ctx.rotate(Math.PI * 2 / settings.spokeCount + settings.rotation );
      //   for (var i = 0; i < settings.spokeCount; i++) {
      //     ctx.rotate(Math.PI * 2 / settings.spokeCount);
      //     ctx.strokeStyle = 'rgba(' + settings.colour + ','+ i / settings.spokeCount +')';
      //     ctx.beginPath();
      //     ctx.moveTo(0, settings.spokeOffset.inner);
      //     ctx.lineTo(0, settings.spokeOffset.outer);
      //     ctx.stroke();
      //   }
      // }
      $.fn.spinner.count++;
    });
  };
  $.fn.spinner.count = 0;
  $.fn.spinner.loop;

  $.fn.clearSpinner = function() {
    return this.each(function () {
      clearTimeout($.fn.spinner.loop);
      $(this).find('div.spinner').fadeOut().remove().end();
      if ($(this).prop('tagName') === 'BODY') {
        $(this).find('#shield').remove();
      }
    });
  };
})(window.jQuery);

// Add spinner with message support
// Unlike the normal spinner, which returns jQuery, this returns a function for replacing the message.
$.fn.messageSpinner = function(settings) {
  // Only body/shield currently has styling setup for message
  settings = settings || {};
  $.fn.spinner.call(this, settings);
  var $shield = $(this).find('#shield'), $container = $shield.children("#spinner-container"),
    $spinner = $container.children(".spinner"), orig_pos;

  function message(txt) {
    if ($shield.length) {
      // Prepend setting will keep existing text until empty str passed
      if (!settings.prepend || !txt) {
        var $existing = $container.find('.message');
        if ($existing.length) {
          $container.find('.message').remove();
          $spinner.css("position", orig_pos);
        }
      }
      if (txt) {
        $container.prepend('<p class="message">' + txt + '</p></div>');
        orig_pos = $spinner.css("position");
        $spinner.css("position", "relative");
      }
    }
  }

  if (settings.message) {
    message(settings.message);
  }
  return message;
};
