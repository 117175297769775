/**
   Allows the user to change the group profile photo on the group admin pages.
 */
var ChangeGroupProfilePhotoView = Backbone.View.extend({
  /**
     Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {
    container: "modal",
    multiple: false
  },

  /**
     Event handlers for the view.
   */
  events: {
    'click .change-profile-pic': 'changePhoto'
  },

  /**
     Initialize the view.
   */
  initialize: function(options) {
    this.options = options;

    this.model = new AlbumModel({
      id: this.$el.data('album-id'),
      albumType: SG.ALBUM_TYPE_GROUP_PROFILE,
      type: 'group_profile',
      name: 'Untitled',
      description: '',
      allow_tagging: false,
      tags: '',
      privacy: 'public'
    });

    this.collection = new PhotoCollection([], {
      albumType: 'group_profile'
    });

    this.collection.on('update', this.render, this);
    this.collection.on('loaded', this.launchFilepicker, this);
    this.collection.on('fpfiles_added', this.fpfilesAddedHandler, this);
    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
  },

  /**
     Render the change group profile photo view.
   */
  render: function() {
    var i;
    var cover = null;
    var coverUrl;

    for (i = 0; i < this.collection.models.length; i++) {
      if (this.collection.models[i].get('isCover')) {
        cover = this.collection.models[i];
        break;
      }
    }

    coverUrl = cover ? cover.get('url') : $('body').attr('sg-gallery_placeholder');
    this.$el.find('img').attr('src', coverUrl);
  },

  /**
     Filepicker files added handler.

     Note: this means Filepicker has closed and they have been submitted to the SG server
     via the add photos endpoint, but this doesn't mean they are finished processing yet.
   */
  fpfilesAddedHandler: function() {
    this.$el.spinner();
  },

  /**
     Filepicker files successful handler.

     Note: this means the new profile photo has been added to SG and assigned a photo record,
     but it has not been saved to the profile album as an album photo at this point yet.
   */
  fpfilesSuccessfulHandler: function() {
    this.collection.setCoverToLastPhoto();
    this.model.set('photos', this.collection.models);

    this.model.save()
      .then(_.bind(this.reloadGroupProfilePhoto, this));
  },

  /**
     Reload the group profile photo after a new one has been uploaded.
   */
  reloadGroupProfilePhoto: function() {
    this.$el.clearSpinner();
    this.render();
  },

  /**
     Filepicker files failed, could not be processed by SG server.
   */
  fpfilesFailedHandler: function() {
    SG.userError('Could not process photo');
  },

  /**
     User clicks the change photo button.
   */
  changePhoto: function(e) {
    e.preventDefault();
    this.collection.loadFromAlbum(this.model.get('id'));
  },

  /**
     Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker: function() {
    SG.pickFile(null, this.filepickerArgs)
      .then(_.bind(this.collection.addFPFiles, this.collection));
  }
});
