var CreateBlogPostView = EditBlogPostView.extend({
  /**
     Active the editor.
   */
  activate: function() {
    this.$el.addClass('active');
    this.editor.load();

    if (this.editor.savedDraftExists()) {
      this.editor.restoreDraft();
    }

    this.setPlaceholderText();
    this.editor.startAutoSaving();
    this.editor.focus();
  },

  /**
     Sets placeholder text used in the title input.
   */
  setPlaceholderText: function() {
    var $title = this.$el.find('.blogTitle');

    if ($title.val().length == 0) {
      $title.attr("placeholder", "Blog Title");
    }
  }
});
