<nav class="horizontal-nav filter darker">
  <ul>
    <li class="dropdown">
      <div class="firefox-container">
        <a class="display">
          <b>
              {{ currentLabel }}
          </b>
        </a>
        <ul>
          <li>
            <a class="default option" href="#all" data-filter="all">All Cash Outs</a>
          </li>
          <li>
            <a class="option" href="#sg" data-filter="sg">SuicideGirls Only</a>
          </li>
          <li>
            <a class="option" href="#members" data-filter="members">Members Only</a>
          </li>
          <li>
            <a class="option" href="#approved" data-filter="approved">Recently Approved</a>
          </li>
          <li>
            <a class="option" href="#failed" data-filter="failed">Failed & Declined Requests</a>
          </li>
          <li>
            <a class="option" href="#paid" data-filter="paid">Recently Paid</a>
          </li>
          <li>
            <a class="option" href="#user" data-filter="user">Specific User</a>
          </li>
          <li>
            <a class="option" href="#user" data-filter="id">Specific ID</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>

<form name="filter-options">
  <div class="username-filter">
    <label for="username">Username:</label>
    <input type="text" name="username">
  </div>
  <div class="id-filter">
    <label for="cash_out_id">Cash Out ID:</label>
    <input type="number" name="cash_out_id" value="{{ id }}">
  </div>
  <button type="submit" class="call-to-action">Search</button>
</form>
