var CreateThreadView = EditThreadView.extend({
  events: {
    'click .createText': 'focusEvent'
  },

  /**
     Initialize the view.
   */
  initialize: function(options) {
    EditThreadView.prototype.initialize.apply(this, [options]);
    this.extendEvents(CreateThreadView);
  },

  /**
     User clicks on the textarea to start making a new thread.
   */
  focusEvent: function(e) {
    e.preventDefault();
    this.$el.addClass('active');
    this.editor.load();
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function(response, textStatus, jqXHR) {
    var redirUrl = $(response).find('.top-bar .title a').attr('href');
    window.location = redirUrl;
  }
});
