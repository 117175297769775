var EditCommentView = EditRichTextContentView.extend({
  msgSaveSuccess: 'Comment successfully saved.',

  events: {
    'keypress .comment:not(.testimonial), .redactor_box': 'textChangedEvent',
    'paste .comment:not(.testimonial), .redactor_box': 'textChangedEvent'
  },

  /**
     Initializes the view.
   */
  initialize: function (options) {
    this.model = new Comment();
    this.editor = new EditorView({
      el: this.$el.find('textarea'),
      albumType: 'group',
      thumbnailGeometries: ['508'],
      savedDraftPrefix: 'comment'
    });
    EditRichTextContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditCommentView);
  },

  /**
     Loads the edit thread interface on the edit thread pages.
  */
  loadEditInterface: function() {
    this.model.set('id', this.$el.data('post-id'));
    if (this.$el.data('album-id')) {
      this.editor.collection.loadFromAlbum(this.$el.data('album-id'));
    }
    this.activate();
    this.saveLoadedTime();
  },

  /**
     Activates the create comment form.
   */
  activate: function() {
    this.editor.load();
    SG.reLayout();
    this.$el.addClass('active');
  },

  /**
     Saves current timestamp when form was loaded.
   */
  saveLoadedTime: function() {
    var date = new Date();
    this.$el.data('loaded_time', date.getTime());
  },

  /**
     Validate the form.

     @returns {Boolean} Whether the form passes validation.
   */
  validate: function() {
    if (!this.isStillGracePeriod()) {
      SG.userError('Grace period is over');
      return false;
    }

    return true;
  },

  /**
     Checks whether it is still within the grace period for the comment.
  */
  isStillGracePeriod: function() {
    var $gracePeriodRemaining = this.$el.find('[name=grace_period_remaining]');
    var isGracePeriodRemainingSpecified = $gracePeriodRemaining.length;

    if (!isGracePeriodRemainingSpecified) {
      return true;
    }

    var date = new Date();
    var currentTime = date.getTime();
    var elapsed = (currentTime - this.$el.data('loaded_time')) / 1000;
    var gracePeriodRemaining = parseInt($gracePeriodRemaining.val());

    return elapsed < gracePeriodRemaining;
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function($form, model) {
    model.set('action', $form.attr('action'));
    if ($form.find('[name="comment_id"]').val()) {
      model.set('id', $form.find('[name="comment_id"]').val());
    }
    model.set('text', this.editor.getText());
    model.set('noteditable', $form.find('[name="noteditable"]').val());
    model.set('photos', this.editor.collection.models);
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function (response, textStatus, jqXHR) {
    var commentId = response['id'];
    var $commentText = $('div.comment-text[data-comment-id=' + commentId + ']');
    var $editCommentContainer = $('.edit-comment-container[data-comment-id=' + commentId +']');
    var $flexWrapper = $('.flex-wrapper[data-comment-id=' + commentId + ']');

    SG.clearSpinner();
    $commentText.empty();
    $commentText.html(response['text']);
    $editCommentContainer.empty();
    $editCommentContainer.hide();
    $flexWrapper.show();
  }
});
