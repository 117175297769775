var CreateStatusUpdateView = EditContentView.extend({
  /**
     Initializes the view.
   */
  initialize: function(options) {
    this.model = new BlogPost();
    EditContentView.prototype.initialize.apply(this, [options]);
    this.initializeMaxLengthPlugin();
  },

  /**
     Initializes the max length plugin.

     Note: this is used to switch the status update view to full blog
     view automatically when the user has typed more than 140 characters.
   */
  initializeMaxLengthPlugin: function() {
    this.$el.find(".blogTitle").maxlength({
      max: 140,
      showFeedback: false,
      truncate: true,
      onFull: _.bind(this.maxLengthOnFullHandler, this)
    });
  },

  /**
     Max Length On Full Callback

     Note: this is called when the user has typed 140 characters into the status update field.
   */
  maxLengthOnFullHandler: function(overflowing) {
    $('#full-blog').trigger('click');
    SG.Post.createBlogPostView.editor.setText(this.$el.find('.blogTitle').val());
    SG.Post.createBlogPostView.hideDraftMessageBar();
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function($form, model) {
    model.set('id', $form.data('post-id'));
    model.set('action', $form.attr('action'));
    model.set('title', $form.find('[name=title]').val());
    model.set('privacy', $form.find('[name=privacy]').val());
    model.set('user', SG.user.logged_in_user_id);
    model.set('post_type', null);
  }
});
