var ChangeProfilePhotoView = Backbone.View.extend({
  /**
   Event handlers for the view.
   */
  events: {
    'click .profile-owner img': 'showCropTool',
    'click .change-profile-pic': 'changePhoto'
  },

  /**
   Initialize the view.
   */
  initialize: function(options) {
    this.options = options;

    this.collection.on('update', this.render, this);
    this.collection.on('loaded', this.launchFilepicker, this);
    this.collection.on('fpfiles_added', this.fpfilesAddedHandler, this);
    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
  },

  /**
   Render the change profile photo view.

   Note: for now this is just setting the src attribute of the image from the server
   rendered template to the latest URL, either the preview URL from Filepicker,
   or the SG server URL after the crop dialog has closed.
   */
  render: function() {
    var i;
    var cover = null;
    var coverUrl;

    for (i = 0; i < this.collection.models.length; i++) {
      if (this.collection.models[i].get('isCover')) {
        cover = this.collection.models[i];
        break;
      }
    }

    coverUrl = cover ? cover.get('url') : $('body').attr('sg-gallery_placeholder');
    this.$el.find('img').attr('src', coverUrl);
  },

  /**
   Filepicker files added handler.

   Note: this means Filepicker has closed and they have been submitted to the SG server
   via the add photos endpoint, but this doesn't mean they are finished processing yet.
   */
  fpfilesAddedHandler: function() {
    this.$el.spinner();
  },

  /**
   Filepicker files successful handler.

   Note: this means the new profile photo has been added to SG and assigned a photo record,
   but it has not been saved to the profile album as an album photo at this point yet.
   */
  fpfilesSuccessfulHandler: function() {
    this.collection.setCoverToLastPhoto();
    this.model.set('photos', this.collection.models);

    this.model.save()
      .then(_.bind(this.showCropTool, this));
  },

  /**
   Shows the crop tool to the user after the profile photo album has been successfully
   saved with the newly uploaded photo in it.
   */
  showCropTool: function() {
    SG.crop.init({
      albumID: this.model.get('id'),
      carousel: true,
      stageRatio: 607 / 441
    });

    SG.crop.modal.done(_.bind(function(data) {
      this.collection.setURLOnCoverPhoto(data.cropped_photo_url);
      this.render();
      this.$el.clearSpinner();
    }, this));
  },

  /**
   Filepicker files failed, could not be processed by SG server.
   */
  fpfilesFailedHandler: function() {
    SG.userError('Could not process photo');
  },

  /**
   User clicks the change photo button.
   */
  changePhoto: function(e) {
    e.preventDefault();
    this.collection.loadFromAlbum(this.model.get('id'));
  },

  /**
   Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker: function() {
    var fpArgs = {
      container : "modal",
      multiple : false
    };

    SG.pickFile(this.$el.find('.filepicker-container'), fpArgs)
      .then(_.bind(this.collection.addFPFiles, this.collection));
  }
});
