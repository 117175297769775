var PhotoThumbnailView = Marionette.ItemView.extend({
  template: Handlebars.compile($("#photo_thumb_template").html()),

  modelEvents: {
    "change:fpfile change:urls": "render",
    "change:uploadProgress": "updateUploadProgress",
    "change:isCover": "updateCover"
  },

  /**
     Serializes the photo model.

     @returns {Object} Variables to pass to photo thumb template
   */
  serializeData: function() {
    var isFilepickerUrl = this.model.get('url') && this.model.get('url').indexOf('filepicker.io') > -1;
    var thumbUrl;
    var isPreview;

    if (isFilepickerUrl) {
      var width = this.options.multiple ? 170 : 550;
      var height = this.options.multiple ? 170 : 550;
      thumbUrl = this.model.get('url') + '/convert?w=' + width + '&h=' + height + '&fit=crop&policy=' + this.model.get('policy') + '&signature=' + this.model.get('signature');
      isPreview = true;
    } else {
      thumbUrl = this.model.getThumbnail(this.options.thumbnailGeometries);
      isPreview = false;
    }

    var data = {
      url: this.model.get('url'),
      thumb_url: thumbUrl,
      fpfile: this.model.get('fpfile'),
      fpfilekey: this.model.get('key') ? this.model.get('key').split("/")[1] : null,
      upload_job_number: null,
      filename: this.model.filename,
      cid: this.model.cid,
      isCover: this.model.get('isCover'),
      preview: isPreview,
      invalid: this.options.isPhotoset() && !this.options.hadNonHDPhotosWhenLoaded && !this.model.get('isHD') && this.model.get('id')
    };

    return data;
  },

  /**
     On Render Callback.

     Note: loads the spinner over the photo thumbnails.
   */
  onRender: function() {
    if (this.$el.find('img').hasClass('preview')) {
      this.$el.find('.photo-thumb-wrapper').spinner(SG.spinnerSettings);
    }
  },

  /**
     Updates the progress bar over the photo thumbnails based on current upload progress.
   */
  updateUploadProgress: function() {
    var COMPLETE = 100;
    var progress = this.model.get('uploadProgress');
    if (progress !== null && progress !== undefined) {
      if (progress === COMPLETE) {
        this.hideProgressBar();
      } else {
        this.showProgressBar();
      };
      this.setProgress(progress);
    }
  },

  /**
     Hides the progress bar on the photo thumbnail.
   */
  hideProgressBar: function() {
    this.$el.find('.progress').hide();
  },

  /**
     Shows the progress bar on the photo thumbnail.
   */
  showProgressBar: function() {
    this.$el.find('.progress').show();
  },

  /**
     Sets the progress bar to the given progress.

     @param {Number} progress - current upload progress, integer percentage
   */
  setProgress: function(progress) {
    this.$el.find('.progress .bar').width(''+progress+'%');
    this.$el.find('.progress .bar').attr('title', ''+progress+'%');
  },

  /**
     Updates the checked/unchecked status of the is cover star icon, without rerendering.
   */
  updateCover: function() {
    this.$el.find('input[name="isCover"]').prop('checked', this.model.get('isCover'));
  }
});
