var AlbumGridView = Backbone.View.extend({
  events: {
    'click .button.icon-crop': 'togglePhotoMetadata'
  },

  togglePhotoMetadata: function() {
    var $button = this.$el.find('.button.icon-crop');
    var $container = this.$el.find('.photo-metadata');

    if ($container.css('z-index') == '1') {
      $button.removeClass('active');
      $container.css('z-index', '-1000');
    } else {
      $button.addClass('active');
      $container.css('z-index', '1');
    }
  }
});
