var Photo = Backbone.Model.extend({
  /**
   Gets a thumbnail URL for the photo.

   @param {Array} preferredGeometries - the preferred thumbnail size, in order from most preferred to least.
   @returns {String} URL to the thumbnail of the first preferred geometry that is available for this photo.
   */
  getThumbnail: function(preferredGeometries) {
    var thumbUrl = null;
    var urls = this.get('urls');

    if (!urls) {
      thumbUrl = SG.getPlaceholder(preferredGeometries[0]);
    } else {
      for (var i = 0; i <= preferredGeometries.length; i++) {
        if (urls[preferredGeometries[i]] != undefined) {
          thumbUrl = this.get('urls')[preferredGeometries[i]];
          break;
        }
      }
    }

    return thumbUrl;
  },

  /**
     Gets the hash portion from the Filepicker key.

     @returns {String} The hash part of the Filepicker key.
   */
  getFilepickerHash: function() {
    var key = this.get('key');
    var hash = null;

    if (key) {
      hash = this.getHashFromTempFilepicker(key);
    }

    return hash;
  },

  /**
     Gets the hash embeded in a Filepicker key.

     @param {string} the Filepicker key for an image (in the format "temp/{hash}_{filename}.jpg")
     @returns {string} just the hash component from the filepicker key.
   */
  getHashFromTempFilepicker: function (key) {
    return key.split("/")[1].split('_')[0];
  }
});
