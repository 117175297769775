var PhotoListView = Marionette.CollectionView.extend({
  childView: PhotoThumbnailView,
  tagName: 'ul',

  childViewOptions: function(model, index) {
    return {
      isPhotoset: _.bind(this.collection.isPhotoset, this.collection),
      hadNonHDPhotosWhenLoaded: this.collection.hadNonHDPhotosWhenLoaded,
      thumbnailGeometries: this.options.thumbnailGeometries
    };
  },

  onRender: function() {
    this.reloadSortable();
  },

  onAddChild: function() {
    this.reloadSortable();
  },

  onRemoveChild: function() {
    this.reloadSortable();
  },

  reloadSortable: function() {
    this.$el.sortable('destroy');
    this.$el.sortable({
      helper: 'clone'
    });
  }
});
