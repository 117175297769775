<header class="header clearfix{{#if intervention_required }} intervention-required{{/if}}">
  <div class="top-bar">
    <img src="{{ user_avatar}}">
    {{#if is_first }}<aside>1st Cashout!</aside>{{/if}}
    <div>
      <a href="{{ profile }}">{{ username }}</a>
      <time class="time-ago">{{ time_ago }}</time>
    </div>
  </div>
  <div class="sub-header">
    <div class="meta-data"><span class="amount">${{ amount }}</span> via {{ payout_method }}</div>
    <a href="mailto:{{ email }}" class="button icon-messages"></a>
    <a href="#" class="button icon-transaction"></a>
  </div>
</header>
<section class="body">
  <div class="details">
    <p><span>Name:</span> {{ legal_name }}</p>
    <p><span>Address:</span> {{ account_address }}</p>
    <p><span>{{ payout_method }}:</span> {{ payout_method_details }}</p>
  </div>
  <div class="w9">
    <span class="icon-checkmark{{#if w9_verified_by }} verified{{/if}}"></span>
    {{#if w9_verified_by }}
      <p><a href="{{ w9_url }}" target="_blank">W9:</a> Verified by {{ w9_verified_by }}</p>
    {{else}}
      {{#if w9_uploaded }}
      <p><a href="{{ w9_url }}" target="_blank">W9:</a> Please verify...</p>
      {{ else }}
      <p><span>W9:</span> Not uploaded</p>
      {{/if}}
    {{/if}}
    <div>
      <a href="#" class="pick-document-file icon-cross button"></a>
    </div>
  </div>
  <div class="details">
    {{#if failed_reason }}
      <strong>* {{ failed_reason }}</strong>
    {{/if }}
  </div>
  <div class="actions">
    {{#if show_buttons }}
      {{#if supports_payout }}
        <button type="button" class="cor-payout"{{#unless is_payable }} disabled="disabled"{{/unless}}>Payout</button>
      {{else}}
        <button type="button" class="cor-approve"{{#unless is_approvable }} disabled="disabled"{{/unless}}>Approve</button>
      {{/if}}

      <button type="button" class="cor-decline">Decline</button>
    {{/if}}
    {{#if is_refundable }}
      <button type="button" class="cor-refund">Fail & Refund</button>
    {{/if}}
  </div>
</section>
