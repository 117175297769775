var EditorPhotoView = Marionette.ItemView.extend({
  template: Handlebars.compile($("#editor_photo_template").html()),

  modelEvents: {
    'change:fpfile change:urls': 'refreshImage',
    'change:uploadProgress': 'updateUploadProgress'
  },

  serializeData: function() {
    var thumbUrl = this.getThumbURL();
    var isUploading;

    if (this.model.get('urls') && this.model.get('urls')['original']) {
      originalUrl = this.model.get('urls')['original'];
    } else {
      originalUrl = '';
    };

    var data = {
      url: this.model.get('url'),
      thumb_url: thumbUrl,
      original_url: originalUrl,
      fpfile: this.model.get('fpfile'),
      fpfilekey: this.model.getFilepickerHash(),
      upload_job_number: null,
      filename: this.model.filename,
      cid: this.model.cid,
      isCover: this.model.get('isCover'),
      preview: this.isFilepickerURL(thumbUrl)
    };

    return data;
  },

  /**
     Gets the thumbnail URL to use.

     @returns {String} The URL to use for the photo inside the editor area.
   */
  getThumbURL: function() {
    var thumbUrl;

    if (this.isFilepickerURL(this.model.get('url'))) {
      thumbUrl = this.model.get('url');
    } else {
      thumbUrl = this.model.getThumbnail(this.options.thumbnailGeometries);
    }

    return thumbUrl;
  },

  /**
     Checks to see if a URL is for an image hosted at Filepicker.

     @returns {Boolean} Whether the URL is from Filepicker or not.
   */
  isFilepickerURL: function(url) {
    return url && url.indexOf('filepicker.io') > -1;
  },

  /**
     Updates the progress bar over the photo thumbnails based on current upload progress.
   */
  updateUploadProgress: function() {
    var COMPLETE = 100;
    var progress = this.model.get('uploadProgress');
    if (progress !== null && progress !== undefined) {
      if (progress === COMPLETE) {
        this.hideProgressBar();
	this.cleanUp();
	return;
      } else {
        this.showProgressBar();
      };
      this.setProgress(progress);
    }
  },

  /**
     Hides the progress bar on the photo thumbnail.
   */
  hideProgressBar: function() {
    this.$el.removeClass('uploading');
  },

  /**
     Shows the progress bar on the photo thumbnail.
   */
  showProgressBar: function() {
    this.$el.addClass('uploading');
  },

  /**
     Sets the progress bar to the given progress.

     @param {Number} progress - current upload progress, integer percentage
   */
  setProgress: function(progress) {
    var label = '' + Math.round(progress) + '%';
    this.$el.attr('data-upload-progress', label);
  },

  /**
     Refreshes the image in the DOM without rerendering the template.

     Note: setting attributes here rather than rerendering the template, which
     is the expected way, because it causes undesirable flickering and wierdness
     with the size of the spinner when the img is removed from the DOM before
     rerendering.
   */
  refreshImage: function() {
    var data = this.serializeData();
    var $img = this.$el.find('img.photo');

    $img.attr('src', data['thumb_url']);
    $img.attr('data-upload', data['upload_job_number']);
    $img.attr('id', 'fp-'+data['fpfilekey']);
    $img.attr('data-key', data['fpfilekey']);
    $img.attr('data-original', data['original_url']);

    if (this.isFilepickerURL(data['thumb_url'])) {
      $img.addClass('preview');
      this.$el.spinner(SG.spinnerSettings);
    } else {
      $img.removeClass('preview');
      this.$el.clearSpinner();
    }
  },

  cleanUp: function() {
    this.$el.removeAttr('data-fp-id');
    this.$el.removeAttr('class');
    this.$el.removeAttr('data-upload-progress');
    this.$el.removeAttr('style');
  }
});
