//====================== WIDGETS ============================================//


// ------ widget module dropSelect ---------
// use $().dropSelect({ menuaction: callbackFunction })
// callbackFunction is a function to do action based on your selection it is passed the li item that is selected
// By default, this looks in the (container) element passed into dropSelect(el) for an element with class
// "dropdown-menu" and adds all li elements within to the drop down. When one is selected, input element
// with class "drop-input" has it's value set to the select li's html. To use a different value from the
// displayed html, include a "drop-value" attribute on the li.

  $.widget( "SG.dropSelect", {
    // default options
    options: {
      isOpen : false,
      menuAction : function(e) { console.log(e); }, //a callback to a function that does the work.
      allow_multiple_selected_items: false,
      manage_selected_class_manually: false
    },

    // the constructor
    _create: function() {
      // this.element refers to the jquery element
      var $menuItems = $('.dropdown-menu li', this.element);
      this._on(this.element, {
        click: 'toggleMenu',
        mouseleave: '_closeMenu'
      });
      this._on($menuItems, {
        click: '_selectMenuItem'
      });
      // Wrapping the drop key and the value in a div to solve overflow issues
      if ($('.key_and_value_container', this.element).length === 0) {
        $('.key, .value', this.element).wrapAll('<div class="key_and_value_container"/>');
      }
    },

    toggleMenu : function(e) {
      //e.stopPropagation();
      e.preventDefault();
      if (this.options.isOpen) {
        this._closeMenu();
      }
      else {
        console.log('open!');
        $('.dropdown-menu', this.element).show();
        this.options.isOpen = true;
        this.element.addClass('open');
      }
    },

    _closeMenu : function() {
        if (this.options.isOpen) {
          console.log('clooose!');
          $('.dropdown-menu', this.element).hide();
          this.options.isOpen = false;
          this.element.removeClass('open');
        }
    },

    _selectMenuItem : function(event) {
      this.selected = $(event.target);
      this._submit();
      this._trigger( "change", event, event.target );
    },

    updateSelectState : function() {
      //to be called by ajax success set's the correct item to be highlighted and
      //set's the text to
      var $this = this;
      var display = $this.selected.html(), val;

      if (this.selected.hasClass('clear-select')) {
        display = "";
      }
      // Use attr drop-value to use something other than displayed html for the input value
      val = $this.selected.attr("drop-value") || display;
      if (!this.options.allow_multiple_selected_items) $('.dropdown-menu li', $this.element).removeClass('selected');
      if (!this.options.manage_selected_class_manually) $this.selected.addClass('selected');
      $('.value', $this.element).html(display);
      $('.drop-input', $this.element).val(val);
    },

    _submit : function() {
      var $this = this,
          url = this.options.url;
          if (this.selected.hasClass('clear-select')) {
            url = this.options.clear_url;
          }
      this.options.menuAction(this.selected);
      $this.updateSelectState();
      /*
      $.ajax({
        type: "GET",
        dataType: "json",
        url: url + this.selected.attr('optionid') + "/",
        success: function(data) {
            $this.updateSelectState();
        }
      });*/
    },

    // events bound via _on are removed automatically
    // revert other modifications here
    _destroy: function() {
      // remove generated elements
    },

    // _setOptions is called with a hash of all options that are changing
    // always refresh when changing options
    _setOptions: function() {
      // _super and _superApply handle keeping the right this-context
      this._superApply( arguments );
      //this._refresh();
    },

    // _setOption is called for each individual option that is changing
    _setOption: function( key, value ) {
      this._super( key, value );
    },

    _enableEvents: function(enable) {
      var
        that = this,
        events = {
          click: 'toggleMenu',
          mouseleave: '_closeMenu'
        };
      if (enable) {
        this._on(this.element, events);
      }
      else {
        $.each(events, function(event_name, event_handler){
          that._off(that.element, event_name);
        });
      }
    },

    enableDropdownEvents: function() {
      this._enableEvents(true);
    },

    disableDropdownEvents: function() {
      this._enableEvents(false);
      $('.dropdown-menu li', this.element).removeAttr('style');
    }

  });



////// end selectDropdown


//// brickize ///////
//// $('.selector').brickize() all child elements of your element will have their padding adjust so the rows fit 100% snugly
//// works with items of same height who float and arrange in rows
//// might not work with borders haven't tested yet
////

$(function() {
  $.widget( "SG.brickize", {

    // default options
    options: {
      paddingPerSide: 7
    },

    // the constructor
    _create: function() {
      this._buildBricks();
      $(this.element).addClass('ready').attr('data-brickize', true);
    },

    _buildBricks: function() {
      var $bricks = $('> *', this.element);
      var width = this.element.width();
      var row = 0;
      var mytop = 0;

      //give each brick a row number based on their top position
      $bricks.each(function(i) {
        if ($(this).position().top == mytop || i == 0) {
          $(this).addClass('brickrow' + row);
        } else {
          row++;
          $(this).addClass('brickrow' + row);
        }
        mytop = $(this).position().top;
       // $(this).css('visibility', 'hidden');
      });

      for (var i = 0; i <= row; i++) {
        var rowBlocks = $('.brickrow' + i, this.element);
        var blockWidths = 0;

        rowBlocks.each(function() {
          blockWidths += $(this).outerWidth(true);
        });

        var space = width - blockWidths;
        var newPadding = Math.floor(space/rowBlocks.length/2) + this.options.paddingPerSide;  // 7 is the minimum padding per side
        var remainder = ((space/rowBlocks.length % 2) * rowBlocks.length) - 1; // minus 1 for sanity.
        rowBlocks.each(function(i) {
          var rem = (i + 1) == rowBlocks.length ? remainder : 0;
          $(this).css({ 'padding-left': newPadding, 'padding-right': newPadding + rem });
        });
      }

    },

    recalculate: function() {
      var
        $_bricks = $('> *', this.element),
        classes_to_remove_regex = new RegExp('\\b' + 'brickrow' + '.+?\\b', 'g');
      $_bricks.each(function() {
        $(this)[0].className = $(this)[0].className.replace(classes_to_remove_regex, '');
        $(this).removeAttr('style');
      });
      this._buildBricks();
    }

  });

});

$(function() {
  $.widget( "SG.tagCollector", {
    // default options
    options: {
      $tagCollector : null,
      itemTag : 'button',
      itemClass : 'tag',
      valSeperator : '|',
      async: false,
      ajaxArgs : {},
      limit: 0  //0 means unlimited
    },
    // the constructor
    _create: function() {
      var that = this,
        $items = $(this.element).siblings(this.options.itemTag),
        $form = $(this.element).closest('form');

      //set input form
      this.options.$tagCollector = $('input[type="hidden"]', this.element.parent());

      //this is to keep from firing button events I captured it with _catchKeypress below
      // $(window).keydown(function(event){
      //   console.log($(event.target));
      //   if (event.keyCode == 13 && $(event.target).hasClass('tag-collector')) {
      //       event.preventDefault();
      //       return false;
      //   }
      // });

      this._on(this.element, {
        keyup: '_addItem',
        blur: '_addItem',
        keypress: '_catchKeypress'
      });

      $(this.element).parent().on('click', this.options.itemTag + '.' + this.options.itemClass, function(e) {
        e.preventDefault();
        that._removeItem(e);
      });

      //find all the tags already that we created on the back end
      if (this.options.$tagCollector.val()) {
        var initialTags = this.options.$tagCollector.val().split('|');
        for (var i in initialTags) {
          var newTag = initialTags[i].trim();
          if (newTag != "") {
            this._addTag(initialTags[i]);
          }
        }
      }
      this._setInput();

    },

    afterRemove: function() {

    },

    afterAdd: function(tag) {

    },
    augmentTag: function() {

    },

    afterInputRender: function() {

    },

    _catchKeypress: function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    _count: function() {
      var count = $('.' + this.options.itemClass, this.element.parent()).length;
      return count;
    },

    _checkLimit: function() {
        var count = this._count();
        if (this.options.limit) {
            if (count >= this.options.limit) {
                $(this).prop('disabled', true);
                return true;
            } else {
                $(this).prop('disabled', false);
                return false;
            }
        }
    },

    _getValueFromTag: function($el) {
      return $el.text();
    },

    _setInput: function() {
       var tag_values = '';
       var that = this;
        $('.' + this.options.itemClass, this.element.parent()).each(function(i) {
          if (!$(this).hasClass('existing'))
            tag_values += that._getValueFromTag($(this)) + '|';
        });
        tag_values = tag_values.substring(0, tag_values.length - 1);
      this.options.$tagCollector.val(tag_values);

      this.afterInputRender();

    },

    _addItem : function(e, createTagCallback) {
      e.preventDefault();
      e.stopPropagation();

      var newItemText = $(this.element).val().trim().toLowerCase(),
        valIsEmpty = this.options.$tagCollector.val() === "",
        newVal,
        deferred,
        that = this;

      if (e.type === 'keyup' && e.keyCode !== 13 ||
        newItemText === '' ||
        this._checkLimit()) return false;

      if (newItemText.length > 140) {
        SG.userError('you can not enter a tag longer than 140 characters');
        return false;
      }

      if (this.options.async) {
        deferred = $.ajax(that.options.ajaxArgs);
      } else {
        deferred = new $.Deferred();
        deferred.resolve();
      }

      deferred.done(function() {
        //clear field
        that.element.val('');
        //add tag
        that._addTag(newItemText);
        /*
        $newItem.addClass(that.options.itemClass)
          .text(newItemText);
        $(that.element).after($newItem);
        */
        //update input
        that._setInput();

      });
    },

    _createTag: function(tag) {
      var $newItem = $('<' + this.options.itemTag + ' />');
      $newItem.addClass(this.options.itemClass).text(tag);

      this.augmentTag($newItem);

      return $newItem;
    },

    _addTag : function(tag) {
      var $newItem = this._createTag(tag);

      if ($(this.element).siblings('.tag-container').index() != -1) {
        $newItem.appendTo($(this.element).siblings('.tag-container'));

      } else {
        $(this.element).after($newItem);
      }

      this.afterAdd(tag);
    },

    _removeItem : function(e) {

      if ($(e.target).closest('form').hasClass('album-container')) {
        e.preventDefault();
        var $that = $(e.target);
        $.ajax({
          type: 'POST',
          url : '/api/remove_tag_from_album/',
          data : JSON.stringify({
            album_id : $that.closest('.album-container').data('albumId'),
            tag : $that.text()
          }),
          contentType: 'application/json; charset=UTF-8'
        })
          .done(function(e) {
            $that.addClass('collapsed').removeClass('uncollapsed');
            setTimeout(function() {$that.remove();}, 500);
          })
          .fail(function() {
            SG.userError('Could not remove tag at this time. Please try again later.');
          });

      } else {
        $(e.target).remove();
      }

      this._setInput();
      this.afterRemove($(e.target));
    }
  });
});


$(function() {
  $.widget( "SG.tabizer", {
    // default options
    options: {

    },
    // the constructor
    _create: function() {
      var that = this,
        $tabs = this.element.find('.tabs').find('li'),
        $tabAnchors = $tabs.find('a'),
        $sections = this.element.find('.data-show').children();


      this.$elements = {
        $tabs : $tabs,
        $tabAnchors : $tabAnchors,
        $sections : $sections
      };

      this.$selectedSection = $sections.filter('.default');
      this.$selectedNav = $tabs.filter('.active');

      this._on($tabAnchors, {
        click : '_switchTab'
      });
    },
    _switchTab : function(e) {
      e.preventDefault();
      e.stopPropagation();

      var show = $(e.target).attr('data-show');
      this.$selectedNav.removeClass('active');
      this.$selectedNav = $(e.target).parent();
      this.$selectedNav.addClass('active');
      this.$elements.$sections.removeClass('active');
      this.$selectedSection = this.$elements.$sections.filter('.' + show);
      this.$selectedSection.show().addClass('active');
      this.element.find('.ios-picker').removeClass('active');
    }
  });
$('.tabbed').tabizer();
});
