/**
   View which handles creating a new video with the video icon in the
   top right of the site template.

   The user clicks the video icon, and the dialog is opened by the code
   in global.js and/or post.js which also opens the same dialog for blog
   or photo posts. They kick things off by calling launchFilepicker in
   this view, which shows the Filepicker dialog in the create content
   modal that was opened by the create content/blog code. When the user
   has selected a file, we hide Filepicker and show the form, while
   listening for the progress updated events. The view updates the progress
   bar on the video form, and when we see progress has reached 100%, we
   hide the progress bar and show the POST button.

   This view does not wait for the video to be processed on the server. The
   progress bar is displaying upload progress to Filepicker, which deposits
   the file into our own S3 bucket. Saving the video with this view calls
   the add_video endpoint through the model, which kicks off the transcoding.
   The user receives a notification when the video has succeeded or failed,
   so there is no polling for status involved with this view, like we are
   doing with photos.
 */
var CreateVideoView = Backbone.View.extend({
  /**
     Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {
    multiple: false,
    extensions: [
      '3g2','3gp','3gp2','3gpp','3gpp2','aac','ac3','eac3','ec3','f4a',
      'f4b','f4v','flv','highwinds','m4a','m4b','m4r','m4v','mkv','mov',
      'mp3','mp4','oga','ogg','ogv','ogx','ts','webm','wma','wmv', 'mts'
    ],
    services: ['COMPUTER', 'DROPBOX', 'GMAIL']
  },

  /**
     Event handlers for the view.
   */
  events: {
    'click .tag-toggle': 'toggleTagsHandler',
    'submit': 'submitForm'
  },

  /**
     Initialize the view.

     @param {Object} options - options for the view
   */
  initialize: function(options) {
    this.options = options;
    this.model = new VideoModel({});
  },

  /**
     Launch the Filepicker tool for the user to select a new video to upload.
   */
  launchFilepicker: function() {
    this.hideForm();
    SG.pickFile(this.options.$filepickerContainer, this.filepickerArgs, null, _.bind(this.progressUpdateHandler, this))
      .then(_.bind(this.addFPFiles, this));
  },

  /**
     Hides the edit video form.
   */
  hideForm: function() {
    this.$el.hide();
    this.$el.removeClass('active');
  },

  /**
     Gets file data from Filepicker after it is done uploading.

     @param {Array} fpfiles - file info returned from Filepicker
     @param {Object} fp_filepicker_policy - filepicker policy info
   */
  addFPFiles: function(fpfiles, fp_filepicker_policy) {
    console.log('got video files from filepicker');
    console.log('fpfiles');
    this.model.set('fpfiles', fpfiles);
  },

  /**
     Hides the progress bar on the edit video form.
   */
  hideProgressBar: function() {
    this.$el.find('.progress').hide();
  },

  /**
     Shows the progress bar on the edit video form.
   */
  showProgressBar: function() {
    this.$el.find('.progress').show();
  },

  /**
     Shows the full form after the selected new video starts uploading.
   */
  showForm: function() {
    this.options.$filepickerContainer.removeClass('active');
    this.$el.show();
    this.$el.addClass('active');
    this.$el.find('.save-video').show();
    this.$el.find('.tag-collector').tagCollector();
    this.$el.find('.button-wrapper').hide();
    this.setProgress(0);
    this.showProgressBar();
  },

  /**
     Progress update handler when uploading the file to Filepicker.

     @param {Object} fpfile - File info returned from Filepicker
   */
  progressUpdateHandler: function(fpfile) {
    var COMPLETE = 100;

    if (fpfile['progress'] === 0.5) {
      this.hideFilepicker();
      this.showForm();
    }

    if (fpfile['progress'] === COMPLETE) {
      this.hideProgressBar();
      this.$el.find('.button-wrapper').show();
    } else {
      this.setProgress(fpfile['progress']);
    }
  },

  /**
     Hides the Filepicker widget.
   */
  hideFilepicker: function() {
    // Hide for desktop
    $('.filepicker-container').hide();

    // Hide for mobile
    if ($(window).width() < 700) {
      $('#modal-container').removeClass('active');
      $('body').css('overflow', 'inherit');
    }
  },

  /**
     Sets the progress bar to the given progress.

     @param {Number} progress - current upload progress, integer percentage
   */
  setProgress: function(progress) {
    this.$el.find('.progress .bar').width('' + progress + '%');
  },

  /**
     User clicks the post button to save the video.

     @param {Object} e - event data
   */
  submitForm: function(e) {
    e.preventDefault();
    SG.showSpinner();
    this.syncFormToModel();
    this.saveForm();
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function() {
    this.model.set({
      'title': this.$el.find('#id_title').val(),
      'description': this.$el.find('#id_description').val(),
      'privacy': this.$el.find('#id_privacy').val(),
      'block_tagging': this.$el.find('#id_block_tagging').is(':checked'),
      'tags': this.$el.find('#id_tags').val()
    });
  },

  /**
     Saves the form itself.
   */
  saveForm: function() {
    this.model.save()
      .then(_.bind(this.saveVideoSuccessHandler, this))
      .fail(_.bind(this.saveVideoFailedHandler, this));
  },

  /**
     Save video success handler.

     @param {object} - result from jqXHR
   */
  saveVideoSuccessHandler: function(data) {
    SG.userMessage('Video submitted for processing');
    SG.clearSpinner();
    window.location.reload();
  },

  /**
     Save video failed handler.

     @param {object} - result from jqXHR
  */
  saveVideoFailedHandler: function(data) {
    SG.userError('Could not save video');
  },

  /**
     User toggles the block tagging button.

     @param {Object} e - event data
   */
  toggleTagsHandler: function(e) {
    e.preventDefault();

    $(e.target).toggleClass('active');

    if ($(e.target).hasClass('active')) {
      $form.find('.tag-collector').hide();
    } else {
      $form.find('.tag-collector').show();
    }
  }
});
