{% load i18n %}
{% verbatim %}

<li>
  <a href="{{absolute_url}}">
    <figure class="ratio-16-9 res-image">
      <noscript data-tablet="{{tablet}}" data-mobile="{{mobile}}" data-retina="{{srcretina}}" data-src="{{src}}">
        <img src="{{src}}" class="{{class}}" />
      </noscript>
    </figure>
  </a>
  <div class="slide-content">
    <h2 class="decorated smaller">{{name}}</h2>
    <time datetime="{{created}}">{{created}}</time>
    <p>{% trans 'Photo set of the day' %}</p>
    <p>{% trans 'By' %} <a href="{{photographer_url}}" rel="author">{{photographer}}</a></p>
  </div>
</li>

{% endverbatim %}
