/*global $ */

var SG = SG || {};

/**
   Handles creating comments on the site.

   This is used for both the plain text comments associated with various types of content posts,
   as well as group thread comments with the Redactor WYSIWYG editor.
 */

SG.Comment = {
  createPlainTextCommentViews: {},

  /**
     User focuses on a plain text comment input.
   */
  focusCommentEvent: function(e) {
    var $commentForm = $(e.target).closest('form');

    if ($commentForm.parent().hasClass('edit-comment-container')) {
      // Don't do anything if this is an edit
      return;
    };

    if (!$commentForm.data('view')) {
      $commentForm.data('view', new CreatePlainTextCommentView({el: $commentForm}));
    }
    $commentForm.data('view').activate();
  },

  /**
     Event handler when the user clicks the button to edit a comment.

     @param {object} e - JQuery event data.
  */
  editCommentEvent: function(e) {
    e.preventDefault();

    // Hide the admin tool bar
    $(e.target).closest('.admin-bar').removeClass('active-bar');
    $(e.target).closest('.admin-bar').prev().removeClass('active-bar');

    // Load edit comment view
    var commentId = $(e.target).data('comment-id');
    var editUrl = $(e.target).attr('href') + 'edit';

    var $flexWrapper = $('.flex-wrapper[data-comment-id=' + commentId + ']');
    var $editCommentContainer = $('.comments:visible .edit-comment-container[data-comment-id=' + commentId + ']');

    var self = this;

    $editCommentContainer.load(editUrl, function(response, status, xhr) {
      if (status == 'error') {
        SG.userError('This comment can no longer be edited.');
        return;
      }

      $flexWrapper.hide();
      $editCommentContainer.show();

      var $el = $('#content-column');
      var useRichEditor = $el.data('useRichEditor') || $el.hasClass('group');
      var view;

      if (useRichEditor) {
        view = new EditCommentView({el: $editCommentContainer.find('form')});
      } else {
        view = new EditPlainTextCommentView({el: $editCommentContainer.find('form')});
      }
      view.loadEditInterface();
    });
  },

  loadComments: function ($element) {
    var imagesLoaded = new $.Deferred,
      $contentBox = $element.closest('.content-box'),
      $viewingCount = $element.find('.viewing-count'),
      viewingCount = parseInt($element.find('.viewing-count').attr("data-viewing-count"));
      remainderCount = parseInt($element.find('.viewing-count').text());
      lastTimeStamp = $contentBox.find('div.comment').last().attr('data-timestamp'),
      commentCount = parseInt($element.find('.comment-count').text()),
      bundleSize = 25;

    $element.addClass('loading');

    var url = $contentBox.find('.commentForm').attr('action') ? $contentBox.find('.commentForm').attr('action') : $element.attr('data-url');

    var ajaxArgs = {
      url: url,
      data: {
        offset : viewingCount,
        count : bundleSize,
        before : lastTimeStamp
      }
    };

    var loadingComments = SG.apiGet(ajaxArgs);
    var chain = loadingComments.pipe(function(data) {
      $(data).imagesLoaded(function() {
        imagesLoaded.resolve(data);
      });
      return imagesLoaded;
    });

    $.when(chain).done(function(data) {
      var $temp = $('<div id="temp">' + data + '</div>'),
        $data = $temp.children();

      SG.initImages($data);
      $data.each(function() {
        $(this).addClass('collapsed');
      });
      $data.insertBefore($contentBox.find('.comment').first());
      //cascading collapse
      $data.each(function(i) {
        var $that = $(this);
        setTimeout(function() {
          $that.addClass('uncollapsed');
        }, i * 100);
        SG.createTextLinks($that.find('div.comment-text')); //convert @ mentions and urls to links
      });


      if (SG.settings.isotope) {
        SG.reLayout();
      }

      $element.clearSpinner().removeClass('loading');
      var newViewingCount = viewingCount + $data.length;
      if (remainderCount == commentCount){
        $element.remove();
      } else {
        $viewingCount.attr("data-viewing-count", newViewingCount);
        if(remainderCount + bundleSize > commentCount) {
          $viewingCount.text(commentCount);
        } else {
          $viewingCount.text(remainderCount + bundleSize);
        }
      }

      if (newViewingCount == commentCount) {
        $element.on('click', function (e) {
          //we're done here.
          //don't let the event bubble to the body.
          e.stopPropagation();
        });
      }
    });//chain done function

    return chain;
  },

  deleteComment: function ($this) {
    var url = $this.attr('href');
    console.log(url);
    var deleted = SG.apiDelete({
      url: url
    });

    deleted.done(function () {
      $this.closest('.comment').remove();
    });

    deleted.fail(function () {
      SG.userError('There was an error deleting this message');
    });
  },
};

$(function () {
  $('.commentForm').find('textarea').on('click', function (e) {
    console.log('Create comment view');
    var view = new CreateCommentView({
      el: $(this).closest('.commentForm')
    });
    view.activate();
  });
});

$('body').on('focus', 'p.comment:not(.testimonial)', _.bind(SG.Comment.focusCommentEvent, SG.Comment));
$('body').on('click', '.edit-comment', _.bind(SG.Comment.editCommentEvent, SG.Comment));
$('body').on('click', '.delete-comment', function (e) {
  e.preventDefault();
  console.log(this);
  SG.Comment.deleteComment($(this)); //pass the anchor link
});

// Event handler when user clicks the "view X of Y comments" buttons below content items
$('body').on('click', '.viewing', function (e) {
  e.preventDefault();
  if (!$(this).hasClass('loading')) {
    SG.Comment.loadComments($(this));
  }
});

// Event handler when user touches the "view X of Y comments" buttons below content items
// Note: the click handler on .viewing was not firing consistently in FF Android, but touchstart does.
$('body').on('touchstart', '.viewing', function(e) {
  e.preventDefault();
  if (!$(this).hasClass('loading')) {
    SG.Comment.loadComments($(this));
  }
});
