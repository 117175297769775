/**
   View which handles allowing the user to edit a video.

   This allows the user to edit the meta data about the video, but
   does not allow the user to upload the video itself again.
*/
var EditVideoView = Backbone.View.extend({
  /**
     Event handlers for the view.
   */
  events: {
    'submit': 'submitForm',
    'click .delete-video': 'deleteVideo'
  },

  /**
     Initialize the view.

     @param {Object} options - options for the view
   */
  initialize: function(options) {
    this.options = options;
    this.model = new VideoModel({});
    this.initializeAlbumView();
  },

  /**
     Initializes a sub-view for handling the cover image a/bum for the video.
   */
  initializeAlbumView: function() {
    var model = new AlbumModel();
    var collection = new PhotoCollection([], {albumType: 'video'});
    var view = new EditVideoAlbumView({
      el: $('#video-album-form'),
      model: model,
      collection: collection,
      type: 'video'
    });
    this.editVideoAlbumView = view;
  },

  /**
     Loads the data for a video into the view.

     @param {Number} videoId - id of the video to load
   */
  loadVideo: function(videoId, albumId) {
    this.model.set('id', videoId);
    this.editVideoAlbumView.collection.loadFromAlbum(albumId);
  },

  /**
     User clicks the update video button to save the edited video details.

     @param {Object} e - event data
  */
  submitForm: function(e) {
    e.preventDefault();

    if (this.editVideoAlbumView.collection.hasUploadJobs()) {
      SG.userWarning('Your photos are still being processed. Please try again.');
      this.editVideoAlbumView.collection.restartPollingUploadJobStatus();
      this.trigger('failed');
      return;
    }

    SG.showSpinner();

    this.syncFormToModel();
    this.saveForm();
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function() {
    this.model.set({
      'title': this.$el.find('#id_title').val(),
      'description': this.$el.find('#id_description').val(),
      'privacy': this.$el.find('[name="privacy"]').val(),
      'photos': this.editVideoAlbumView.collection.models
    });
  },

  /**
     Saves the form itself.
   */
  saveForm: function() {
    this.model.save()
      .then(_.bind(this.saveVideoSuccessHandler, this))
      .fail(_.bind(this.saveVideoFailedHandler, this));
  },

  /**
     Save video success handler.

     @param {object} - result from jqXHR
   */
  saveVideoSuccessHandler: function(data) {
    SG.userMessage('Video saved');
    SG.clearSpinner();
    window.location = $('body').attr('sg-user_absolute_url') + 'videos/';
  },

  /**
     Save video failed handler.

     @param {object} - result from jqXHR
  */
  saveVideoFailedHandler: function(data) {
    SG.userError('Could not save video');
  },

  /**
     User clicks the delete album button.

     @param {Object} e - event data
  */
  deleteVideo: function(e) {
    e.preventDefault();

    if (!this.$el.find("#id_verify").is(":checked")) {
      SG.userError("You must verify that you want to delete this video");
      return false;
    }

    this.model.destroy()
      .then(function() {
	SG.userMessage('Video deleted');
	window.location = $('body').attr('sg-user_absolute_url');
      });
  }
});
