/**
   Abstract base class for creating and editing content items.
*/
var EditContentView = Marionette.ItemView.extend({
  saveErrors: {
    '0': 'Network connection problem',
    '400': 'There was a problem with your post',
    '401': 'You are not logged in',
    '403': 'Not authorized to comment on this item',
    '500': 'Server error',
    '503': 'Site is in maintenance mode'
  },

  saveErrorDefault: 'Error saving post',

  events: {
    'submit': 'formSubmitEvent'
  },

  /**
     Extends the event for the view, with events from model.

     Note: this allows us to use events flrom both the current class
     we are working with, as well as its parent classes, merging them
     all into one big events dictionary using this recursive hack.

     @param model {Function} - A reference to the model, not instantiated.
   */
  extendEvents: function(model) {
    this.events = _.extend({}, this.events, model.prototype.events);
    this._ensureElement();
  },

  /**
     Event handler when the form is submitted.

     @param {object} e - JQuery event data.
   */
  formSubmitEvent: function (e) {
    e.preventDefault();
    this.save();
  },

  /**
     Saves the content item to the server.
   */
  save: function () {
    if (!this.validate()) {
      return;
    }

    if (!this.isReadyToSubmit()) {
      SG.userError('Not ready to submit');
      return;
    };

    this.syncFormToModel(this.$el, this.model);

    if (this.isEmpty()) {
      return;
    }

    if (this.spinnerOnSave) {
      SG.showSpinner();
    }

    var request = this.model.save();
    request.done(_.bind(this.saveSuccess, this));
    request.fail(_.bind(this.saveFailed, this));

    return request;
  },

  /**
     Validate the form.

     @returns {Boolean} Whether the form passes validation.
   */
  validate: function () {
    return true;
  },

  isEmpty: function () {
    var hasTitle = this.model.has('title');
    var hasText = this.model.has('text');
    if (!hasTitle && !hasText) {
      // Don't fail if no title or text (possible?)
      return false;
    }

    // If the model has a title or text, one must be non-empty.
    if (hasTitle && this.model.get('title').trim()) {
      return false;
    }
    if (hasText && this.model.get('text').trim()) {
      return false;
    }
    return true;
  },

  /**
     Checks to see if the form is ready to submit.

     @returns {Boolean} Whether the user should be allowed to submit the form yet or not.
   */
  isReadyToSubmit: function() {
    return true;
  },

  /**
     Callback when the content item has been successfully saved.
   */
  saveSuccess: function(response, textStatus, jqXHR) {
    SG.clearSpinner();
    this.refreshPage(response, textStatus, jqXHR);
  },

  /**
     Callback when the post could not be saved.
   */
  saveFailed: function(jqXHR, textStatus, errorThrown) {
    if (this.spinnerOnSave) {
      SG.clearSpinner();
    }
    var error_message = this.saveErrors[jqXHR.status] ? this.saveErrors[jqXHR.status] : this.saveErrorDefault;
    SG.userError(error_message);
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage: function () {
    setTimeout(function () {
      var redirectUrl = window.location.href.split('#')[0];
      window.location.replace(redirectUrl);
    }, 1000);
  }
});
