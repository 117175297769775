/* eslint-disable */
$(function() {
  $(document).on('click', '#load-more[data-load-more-method="offset"]', function(e) {
    e.preventDefault();

    var $container;
    if ($('#article-feed').length > 0) {
      $container = $('#article-feed');
    } else {
      $container = $('.feed');
    }

    //  Exit if already loading
    var isLoading = $(this).hasClass('loading');
    if (isLoading) {
      return;
    }

    SG.debug('offset load more');
    $(this).parent().addClass('loading');
    $(this).addClass('loading');
    $(this).spinner(SG.spinnerSettings);
    lastItem = $container.first().children().last();
    var action = $(this).attr("action"),
      nextPage = $(this).attr("nextPage"),
      ajaxArgs = {
        url: window.location,
        data: {partial: true,
               before: lastItem.attr("data-before"),
               offset: lastItem.attr("data-number"),
               count: $(this).attr("data-count")},
        dataType: 'html'
    };
    console.log('Offset load more', ajaxArgs);

    var loadMoreRequest = SG.apiGet(ajaxArgs);
    var $that = $(this);

    $(this).data('promise', loadMoreRequest);
    loadMoreRequest.always(function() {
      $that.removeClass('loading');
      $that.parent().removeClass('loading');
    });

    var finished = new $.Deferred;
    $(this).data('finished', finished); //to be resolved after everything is loaded

    loadMoreRequest.done(function(data) {
      SG.appendNode($container, $(data) );
      SG.createTextLinks($container.find(SG.textLinkContainers));
      SG.reLayout();
      $container.imagesLoaded(function() {
        var layout = SG.reLayout();
        layout.done(function() {
          finished.resolve(data);
        });

        $that.clearSpinner();
      });

      if ($container.children().last().attr("data-last-page") == "True") {
        $that.parent().hide();
      }
    });
  });
});
