/* eslint-disable */
var SG = SG || {};

(function () {
  var existing = SG;
  SG = {
    ALBUM_TYPE_PROFILE: '1',
    ALBUM_TYPE_GROUP_PROFILE: '5',
    ALBUM_TYPE_SG: '10',
    ALBUM_TYPE_HOPEFUL: '11',
    ALBUM_TYPE_APPLICANT: '12',
    ALBUM_TYPE_SHOP: '13',
    ALBUM_TYPE_SGMODEL_RELEASE: '16',
    ALBUM_TYPE_SGMODEL_PHOTO_RELEASE: '17',
    ALBUM_TYPE_VIDEO: '19',
    ALBUM_TYPE_AFFILIATE_W9: '25',

    albumDictionary: {
      '0': 'member',
      '1': 'profile',
      '2': 'blog',
      '3': 'group',
      '4': 'group',
      '5': 'group_profile',
      '6': 'group_single_photo',
      '7': 'group_multi_photo',
      '8': 'blog_single_photo',
      '9': 'blog_multi_photo',
      '10': 'sg',
      '11': 'hopeful',
      '12': 'applicant',
      '13': 'shop',
      '14': 'sgmodel_id',
      '15': 'sgmodel_w9',
      '16': 'sgmodel_release',
      '17': 'sgmodel_photo_release',
      '18': 'sgmodel_sotd_graphic',
      '19': 'video',
      '20': 'hopeful_cover_photo',
      '21': 'testimonial_photo',
      '22': 'blog_attachments',
      '23': 'instagram',
      '24': 'instagram_single_photo',
      '25': 'affiliate_w9'
    },

    reverseAlbumDictionary: {
      'member': '0',
      'profile': '1',
      'blog': '2',
      'group': '4',
      'group_profile': '5',
      'group_single_photo': '6',
      'group_multi_photo': '7',
      'blog_single_photo': '8',
      'blog_multi_photo': '9',
      'sg': '10',
      'hopeful': '11',
      'applicant': '12',
      'shop': '13',
      'sgmodel_id': '14',
      'sgmodel_w9': '15',
      'sgmodel_release': '16',
      'sgmodel_photo_release': '17',
      'sgmodel_sotd_graphic': '18',
      'video': '19',
      'hopeful_cover_photo': '20',
      'testimonial_photo': '21',
      'blog_attachments': '22',
      'instagram': '23',
      'instagram_single_photo': '24'
    },
    textLinkContainers: 'div.comment-text, div.list-content, div.truncated-text, section.commenter_sg, section.commenter_staff, section.commenter_photographer, section.commenter_hopeful, section.commenter_followee, section.content-box-content',
    activeEditable: null,
    user: {
      logged_in_user_id : $('body').attr('sg-user_id'),
      logged_in_user_email : $('body').attr('sg-user_email'),
      logged_in_user_url : $('body').attr('sg-user_absolute_url'),
      logged_in_username: $('body').attr('sg-user_name'),
      status: $('body').attr('sg-user_status'),
    },
    promises: {
      isotope : new $.Deferred(),
      windowLoad : new $.Deferred()
    },
    settings: {
      isotopeBreakPoint : 550, //devices window.width returns much lower than actual width which breaks nexus 7 etc. iphone returns 320 nexus 601
      DEBUG: window.location.port === '8000' || window.location.port === '8003' || window.location.port === '3000' || window.location.port === '3100' || window.location.hostname.indexOf('ngrok.io') !== -1 || window.debugFrontend,
      isotope: !$('body').hasClass('no-isotope') && $(window).width() > 550,
      pushState: window.history && window.history.pushState
    },
    $elements: {
      $articleFeed: $('.article-feed'),
      $articleFeedContainer: $('.article-feed-container'),
      $article: $('.article-feed').find('article'),
      $modalContainer: $('#modal-container'),
      $searchInput: $('#search-form').find('.search'),
      $filepickerPackage: $('#filepicker-package'),
      $scrollToTop: $('#scroll-to-top'),
      $userVideos: $('iframe.blog-embed'),
      $body: $('body')
    },

    publish: function (topic, args) {
      this.$elements.$body.trigger(topic, args);
    },

    subscribe: function (topic, action) {
      this.$elements.$body.on(topic, action);
    },

    unsubscribe: function (topic, action) {
      this.$elements.$body.off(topic, action);
    },

    handleQuery: function () {
      var searchString = window.location.search.substr(1),
        //split all the queries if multiple
        queries = searchString.split('&'),
        queryDict = {},
        splitQuery;

      $.each(queries, function (i) {
        //store queries in dictionary
        splitQuery = this.split('=');
        if (splitQuery[0]) {
          //only if the key exists
          queryDict[splitQuery[0]] = splitQuery[1];
        }
      });

      return queryDict;
    },

    videoJSPlayers : [],
    $handlebars: $('.handlebars-template'),
    log: [],
    transitionEnd : 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',

    detectTouch: function () {
      if (
        ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0)
      ) {
        document.querySelector('html').classList.add('touch');
      } else {
        document.querySelector('html').classList.add('no-touch');
      };
    },

    init: function () {
      var that = this;
      if (!window.console) {
        window.console = {};
      }

      this.detectTouch();

      // Disable all console logging functions.
      if (!this.settings.DEBUG) {
        console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function () {};
      }

      //if we have search parameters
      if (window.location.search) {
        //gather query object
        var queryObject = that.handleQuery();
        //check to see if all this stuff exists
        queryObject &&
        queryObject['show_login'] &&
        queryObject['show_login'] === 'true' &&
        //and login.
        this.login();
      }

      //we now always convert navs, but only show if mobile
      //if ($(window).width() < 500)
      SG.convertNavs();

      // Activate isotope
      SG.activateIsotope(SG.$elements.$articleFeed);

      // Initialize images
      SG.initImages($('body'));

      // Find @mentions and urls and convert them to links
      if (location.hash.substr(1) !== 'edit') {
        SG.createTextLinks($(SG.textLinkContainers).not('.no-autolink'));
      }

      // Initialize user videos, if there are any.
      SG.$elements.$userVideos = $('iframe.blog-embed');
      if (SG.$elements.$userVideos.length > 0) {
        function resizeUserVideos() {
          SG.$elements.$userVideos.each(function () {
            //16:9ify
            window.$this = $(this);
            var embedTextOffset = 0;
            var $parentWidth = $(this).parent().width(),
              sixteenByNine = $(this).parent().width() * 0.5625;
            if ($(this).hasClass('sg-embed')) {
              embedTextOffset = (sixteenByNine * 0.1);
            }
            console.log('resizing', $(this), 'width: ', $parentWidth, 'height: ', sixteenByNine + embedTextOffset);
            $(this).width($parentWidth).height(sixteenByNine + embedTextOffset);
          });
        }
        resizeUserVideos();
        $(window).on('resizeEnd', resizeUserVideos);
      }

      // Set Filepicker policy data
      SG.fp_policy_data = {
        api_key: SG.$elements.$filepickerPackage.data('fp'),
        policy: SG.$elements.$filepickerPackage.data('policy'),
        signature: SG.$elements.$filepickerPackage.data('signature')
      };

      // Bind global event handlers
      this.globalBind();

      // Bind modal
      SG.bindModal();
    },

    spinnerSettings: {
      framerate: 12, // The number of frames per second,
      rotation: 0, // The number of degrees the spinner
      spokeCount: 10, // The number of spokes in the spinner
      spokeWidth: 5, // The the width of each spoke in px
      colour: '255,255,255', // Must be an RGB string
      backup: 'images/spinner.gif', // The path to the backup image
      centered: true // Toggles auto centering of the spinner
    },

    debugGroup: function(group) {
      if (!this.settings.DEBUG || !window.console) {
        return;
      }

      console.group(group);
    },

    debugGroupEnd: function(group) {
      if (!this.settings.DEBUG || !window.console) {
        return;
      }

      console.groupEnd(group);
    },

    debug: function (msg, context) {
      if (!this.settings.DEBUG || !window.console) {
        return;
      }

      this.log.push({
        message: msg,
        context: context
      });

      var index = (this.log.length - 1).toString();

      if (typeof msg === 'object') {
        console.log(index + ': ');
        console.log(msg);
      } else {
        console.log(index + ': ' + msg);
      }
    },

    userError: function (message) {
      $('#message-bar').removeClass().addClass('error');
      $('#message-bar').addClass('active').find('.text').text(message);
      SG.clearMessageBarOnNextInteraction();
    },

    userMessage: function (message) {
      $('#message-bar').removeClass().addClass('message');
      $('#message-bar').addClass('active').find('.text').text(message);
      SG.clearMessageBarOnNextInteraction();
    },

    userSuccess: function(message) {
      $('#message-bar').removeClass().addClass('success');
      $('#message-bar').addClass('active').find('.text').text(message);
      SG.clearMessageBarOnNextInteraction();
    },

    userWarning: function (message) {
      $('#message-bar').removeClass().addClass('warning');
      $('#message-bar').addClass('active').find('.text').text(message);
      SG.clearMessageBarOnNextInteraction();
    },

    /**
       Clears the message bar across the top of the page on the next user interaction.
     */
    clearMessageBarOnNextInteraction: function() {
      var interactionEventList = 'mouseup mousedown click mousemove keypress touchstart';
      $('body').one(interactionEventList, function () {
        var timeout = SG.isPhone() ? 500 : 3000;
        setTimeout(function() {
          $('#message-bar').removeClass('active');
        }, timeout);
      });
    },

    login: function () {
      // This is called every time the login dialog is triggered
      // so be careful about duplicate binding.
      // As quick fix, unbinding (w/namespacing) before every bind.
      var $wrapper = $('#login-wrapper');
      $wrapper.show().data('showing', true);

      // Hide login form if click outside of form
      $('.login-form-wrapper')
        .off('click.hideLogin')
        .on('click.hideLogin', function (e) {
          if (e.target.tagName === 'DIV') {
            $wrapper.hide();
          }
        });

      // Ajax form data
      $('#login-form')
        .off('submit.login')
        .on('submit.login', function (e) {
          e.preventDefault();
          var that = this, $this = $(this);
          var button = $('.call-to-action', this).prop('disabled', true);
          var formData = $this.serializeArray();
          var csrftoken = document
            .querySelector('[name=csrfmiddlewaretoken]')
            .value;

          SG.apiPost({
            url: $this.attr('action'),
            data: $.param(formData),
            crossDomain: true,
            xhrFields: {
              withCredentials: true,
            },
            headers: {'X-CSRFToken': csrftoken},
          })
            .done(function (data) {
              if (data.redirect) {
                window.location = data.redirect;
              } else {
                location.reload();
              }
            })
            .fail(function (xhr, status, text) {
              var msg = 'A server error occurred';
              if (xhr.responseText) {
                var data = JSON.parse(xhr.responseText);
                msg = data.message || msg;
              }
              button.prop('disabled', false).clearSpinner();
              SG.userError(msg);
            });
        });
      $('#google-login')
        .off('click.google')
        .on('click.google', function (e) {
          if ($.cookie('social_auth_failed')) {
            e.preventDefault();
            // Force account selection prompt if prior auth failed
            window.location.assign(this.href + '?prompt=select_account');
          }
      });

    },

    appendNode: function ($container, $node) {
      var $appended;

      if ($container.data('isotope')) {
        $container.isotope('insert', $node);
      } else {
        $container.append($node);
      }
    },

    removeNode: function ($container, $node) {
      if (SG.settings.isotope) {
        $container.isotope('remove', $node);
      } else {
        $container.remove($node);
      }
    },

    reLayout: function () {
      var finishedLoading = new $.Deferred;

      if (!SG.settings.isotope) {
        finishedLoading.resolve();
        return finishedLoading;
      }

      console.time('Relayout');

      SG.promises.isotope.done(function () {
        var gutter = $(window).width() < 1025 ? 12 : 30;
          var columnWidth = SG.getColumnWidth();
          SG.isotope.isotope({
            itemSelector: 'article',
            resizable: false,
            masonry: {
              columnWidth: columnWidth,
              resizable: false,
              gutterWidth: gutter
            }
          }, function () {
          finishedLoading.resolve();
          console.timeEnd('Relayout');
        });
      });

      return finishedLoading;
    },

    apiGet: function (ajaxArgs, passThrough) {
      $.extend(ajaxArgs, {type: 'GET'});
      return $.ajax(ajaxArgs);
    },

    apiPost: function (ajaxArgs, passThrough) {
      $.extend(ajaxArgs, {type: 'POST'});
      return $.ajax(ajaxArgs);
    },

    apiDelete: function (ajaxArgs, passThrough) {
      if (SG.apiPrompt()) { // Prompt the user if they're sure
        $.extend(ajaxArgs, {type : 'DELETE'});
        return $.ajax(ajaxArgs);
      } else {
        return new $.Deferred; // Return an empty deferred object that never resolves so we don't get an error
      }
    },

    apiNoPromptDelete: function (ajaxArgs, passThrough) {
      $.extend(ajaxArgs, {type : 'DELETE'});
      return $.ajax(ajaxArgs);
    },

    apiPrompt: function (message, reload) {//the message and whether to refresh the browser
      if (!message) {                      //we want to refresh if it's ajax or else you get in a weird state sometimes.
        message = 'Are you sure you want to delete this?';
      }

      if (!window.confirm(message)) {
        if (reload) {
          window.location.reload(true);
        }
        return false;
      } else {
        return true;
      }
    },

    activateIsotope: function ($container) {
      if ($('.feed').length > 0 ) {
        SG.settings.isotope = false;
      }

      if (!SG.settings.isotope) {
        return SG.promises.isotope.resolve();
      }

      console.time('Layout');
      console.group('Activating Isotope...');

      SG.$elements.$articleFeed.addClass('loaded');

      var gutter = $(window).width() < 1025 ? 12 : 30;
      var columnWidth = SG.getColumnWidth();
      //isotope fails silently and behaves bad later if the container is empty
      if ($container.children('article').length) {
        $container.isotope({
          itemSelector: 'article',
          resizable: false,
          masonry: {
            columnWidth: columnWidth,
            gutterWidth: gutter
          }
        }, function () {
          console.log('Isotope activation done.');
          console.timeEnd('Layout');
          console.groupEnd();
          SG.isotope = $container;
          SG.promises.isotope.resolve();
          SG.$elements.$articleFeed.addClass('loaded');

        });
      }

      return SG.promises.isotope;
    },

    createContentClose: function (e) {
      e.preventDefault();
      $('.create-content-modal-close').trigger('click');
    },

    findPrev: function recurse(selector, element) {
      // Works backwards and upwards from el, working through DOM trying to match selector. STOPS at first match(es) found.
      // This may exist somewhere but I couldn't find it.
      var $el = $(element);

      // First see if el is itself a match
      var $matches = $el.filter(selector);
      if ($matches.length) {
        return $matches;
      }

      // Next see if any prev siblings match
      $matches = $el.prevAll(selector);
      if ($matches.length) {
        return $matches;
      }

      // No luck, try next level up in DOM (but stop if top)
      var $parent = $el.parent();
      if ($parent.length) {
        return recurse(selector, $parent);
      }

      return $matches;
    },

    mentionInit: function (attribs) {
      // Bind the mention autocompleter to each of the attribs, skipping any already bound
      var $attribs = $(attribs);
      $attribs.each(function() {
        var $attrib = $(this);
        if (!$attrib.data('textComplete')) {
          $attrib.textcomplete([
            {
              match: /\B@([\-+\w]+)$/,
              template: function (value) {
                return '<div class="autocomplete-avatar"><img src="' + value.thumbnails['40x40'] + '" retina-src="' + value.thumbnails['80x80'] + '" width="40">' + value.label + '</div>';
              },
              search: function (term, callback) {
                // Post info is attributes of the section header. Try to find it.
                var $postInfo = SG.findPrev('[posttype]', this.$el);
                $.getJSON('/relevant_username_comment_search/', { term: term, posttype: $postInfo.attr('posttype'), post_id: $postInfo.attr('post_id')})
                  .done(function (resp) { callback(resp); })
                  .fail(function () { callback([]); });
              },
              replace: function (value) { console.log(value); return '@' + value.label + ' '; },
              index: 1,
              cache: true
            }
          ]);
        }
      });
      return $attribs;
    },

    globalBind: function () {
      var self = this;

      // Bind Login Button
      $('body').on('click', '.login', SG.login);

      // iOS trick for letting things bubble to the body. HACKY!
      if (/ip(hone|od)|ipad/i.test(navigator.userAgent)) {
        $("body").css ("cursor", "pointer");
      }

      // Create content + sign
      $('#create-content, .create-content, .content-button').on('click', function (e) {
        e.preventDefault();

        if (SG.settings.pushState) {
          window.history.pushState({}, 'Gallery', '#create');
        }

        // Bind back button to exit for mobile.
        $(window).on('popstate.createContentModal', SG.createContentClose);

        // Put it after scroll to top for css reasons (#create-content-modal + #scroll-to-top {display: none})
        $('#scroll-to-top').before($('#create-content-markup').html());
        var $createContentModal = $('#create-content-modal');

        // Set correct form action.
        $createContentModal.find('form.createForm').attr('action', SG.user.logged_in_user_url + 'blog/');

        SG.Post.modalOpenedHandler($createContentModal);

        $createContentModal.on('click', function (e) {
          if (e.target != $createContentModal[0]) {
            // Ignore propagated events, only close if the background clicked.
            return;
          }
          // Close create content modal if user clicks outside user input or tags
          var ignoreTags = ['tag', '.user-input'];
          var hasIgnoreTag = false;

          for (var idx in ignoreTags) {
            if ($(e.target).hasClass(ignoreTags[idx])) {
              hasIgnoreTag = true;
              break;
            }
          }

          if (!hasIgnoreTag) {
            SG.createContentClose(e);
          }
        });

        $("html, body").scrollTop(0);

        // If launched from another button fire the correct tab.
        if ($(this).hasClass('photo-upload-start')) {
          $createContentModal.find('.photo-upload-start').trigger('click');
        }

        if ($(this).hasClass('pick-video')) {
          $createContentModal.find('.pick-video').trigger('click');
        }

        if ($(this).hasClass('single-ios')) {
          $createContentModal.find('.single-ios').trigger('click');
        }

        if($(this).hasClass('multiple-ios')) {
          $createContentModal.find('.multiple-ios').trigger('click');
        }
      });

      // Related to above launch the ios buttons for opening modal
      $('.blog-create .ios-picker').on('click', function () {
        $('.blog-create .ios-picker, .blog-create').addClass('active');
      });

      // Any .redactor will trigger an active class for the form.
      $('.redactor').on('click', function (e) {
        $(this).closest('form').addClass('active');
        e.preventDefault();
        $(this).closest('form').addClass('editing');
        $('body').trigger('reLayout');
      });

      // Dynamically add selected class to radio button labels for nice radio styling
      SG.prettifyInputs();

      // Click event for checkboxes
      $(document).on('click', '.checkbox', function(e) {
        var checkbox = $(this).find('input:checkbox');
        if ($(checkbox).attr('checked') === 'checked') {
          $(checkbox).removeAttr('checked');
        } else {
          $(checkbox).attr('checked', 'checked');
        }
        checkbox.change();
      });
      // Bind read more tags
      $('#content-container').on('click', 'a.more-tag', function (e) {
        var contentId = $(this).attr('content-id');

        if (SG.settings.isotope) {
          return true; // Go to the single page if we have an isotope layout.
        }

        e.preventDefault();
        var $fullText = $('#full-text-' + contentId);

        var html = $fullText.find('script').html();
        $fullText.closest('.content-box-content').html(html);
        $fullText.closest('.content-box-content').addClass('full').closest('.content-box').addClass('full');
        SG.createTextLinks($(SG.textLinkContainers));
      });

      // Add ready state to each time Filepicker loads.
      $('#filepicker-frame').load(function () {
        console.log('Filepicker frame load event fired.');
        $(this).parent().addClass('ready');
      });

      // Share link binding
      $('body').on('click', '.has-bar', function (e) {
        e.preventDefault();
        var id = $(this).attr('id'),
            barId = id.split('-')[1],
            action = ($(this).hasClass('active-bar')) ? 'removeClass' : 'addClass';

        $('.active-bar').removeClass('active-bar');
        $('#' + barId)[action]('active-bar');
        $(this)[action]('active-bar');
      });

      // Global window load access
      $(window).load(function () {
        SG.promises.windowLoad.resolve();
        SG.reLayout();
        $('html').removeClass('loading');
      });

      // Smartresize is a throttled resize event from the isotope library
      $(window).smartresize(function () {
        SG.reLayout();
        if ($(window).width() < SG.settings.isotopeBreakPoint) {
          SG.convertNavs();
          if (SG.settings.isotope) { //kill isotope
            SG.settings.isotope = false;
            try {
              SG.isotope.isotope('destroy');
            } catch (err) {
              SG.debug('No Isotope to destroy');
            }
          }
        } else if ($(window).width() > SG.settings.isotopeBreakPoint && !SG.settings.isotope) {
          if (!$('body').hasClass('no-isotope')) { //initialize isotope
            SG.settings.isotope = true;
            SG.activateIsotope(SG.$elements.$articleFeed);
          }
        }
      });

      // Global load more
      $(document).on('click', '#load-more:not([data-load-more-method="offset"])', function (e) {
        var $that = $(this);
        e.preventDefault();
        SG.debug('Global Load more.');
        $(this).parent().addClass('loading');
        $(this).addClass('loading');
        $(this).spinner(SG.spinnerSettings);
        var action = $(this).attr("action"),
          nextPage = $(this).attr("nextPage"),
          ajaxArgs = {
            url: action + "?page=" + nextPage
          };
        var promise = SG.apiGet(ajaxArgs);

        $(this).data('promise', promise);
        promise.always(function () {
          $that.removeClass('loading');
          $that.parent().removeClass('loading');
          $that.clearSpinner();
        });
      });

      // Follow event handler.
      $(document).on('click', '.follow, .block', function (e) {
        //this got complicated when I double used it for blocking... tread lightly
        e.preventDefault();

        //if the action is disabled just exit
        if ($(this).hasClass('unfollow-disabled')) {
          return false;
        }

        var wasFollowing;

        //this makes block events always fire 'action'
        if ($(this).hasClass('block')) {
          $('body').spinner(SG.spinnerSettings);
          wasFollowing = false;
        } else {
          wasFollowing = $(this).attr('following') === 'true';
        }

        var $that = $(this),
          promise,
          url =  wasFollowing ? $(this).attr('anti-action') : $(this).attr('action');
        promise = SG.apiPost({
          url : url,
          data : { "user_id" : parseInt($(this).attr('user_id'))},
          dataType: 'json',
          crossDomain: true,
          xhrFields: { withCredentials: true },
        });
        promise.done(function (data_) {
            // Transform a REST success into expected kludge
          var data = data_ || { success: promise.status === 204 };
          if (wasFollowing) {
            if (data.success) {
              SG.debug('Unfollowed.');
              $that.attr('following', 'false');
              $that.find('.button-text').text('Follow');
              $that.removeClass('following');
              if ($that.hasClass('icon-checkmark')) {
                $that.removeClass('icon-checkmark');
                $that.addClass('icon-cross');
              }
            } else {
              SG.debug(data.error);
            }
          } else {
            if (data.success) {
              //if this is a notification update delete the notification
              if ($that.hasClass('request')) {
                $($that).closest('.content-box ').remove();
              }

              if (data.is_request) {
                $that.addClass('pending');
                  $that.replaceWith('<span class="follow button unfollow-disabled disabled pending private" title="pending"><span class="button-icon"></span><span class="button-text">Pending</span></span>');
                    SG.debug('Pending.');
              } else {
                if ($that.hasClass('block')) {
                  location.reload(true); // Just reload the page if you block or unblock someone
                } else {
                  $that.attr('following', 'true');
                  $that.addClass('following');
                  $that.find('.button-text').text('Following');
                  SG.debug('Followed.');
                  if ($that.hasClass('icon-cross')) {
                    $that.removeClass('icon-cross');
                    $that.addClass('icon-checkmark');
                  }
                }
              }
            } else {
              SG.debug(data.error);
              if (data.error == 'already friended') {
                SG.debug('Followed.');
                $that.addClass('following');
                $that.attr('following', 'true');
                $that.find('.button-text').text('Following');
              }
            }
          }

          localStorage.removeItem('followees');
        });

        promise.fail(function () {
          SG.userError('There was an error processing your request. Please try again later.');
        });
      });//follow

      //sitewide call-to-action style binding.
      $(document).on('click', '.call-to-action', function () {
        var $this = $(this);
        if ($this.hasClass("no-action") == false ) {
          var oldText = $this.text();

          if ($this.attr('sg-call-text')) {
            $this.toggleClass('called');
            $this.text($this.attr('sg-call-text'));
            $this.attr('sg-call-text', oldText);
          }
          if (this.dataset.spinner) {
            SG.showSpinner();
          }
        }
      });

      //dropdown nav default functionality
      $('.dropdown').each(function () {
        var $that = $(this),
            $defaultOption = $(this).find('.default'),
            $options = $(this).find('ul').find('a'),
            $display = $(this).find('.display');

        var doNotUpdateLabel = $display.is('[do-not-update]');

        $display.on('click', function (e) {
          e.preventDefault();
          $that.toggleClass('active');
        });

        $options.on('click', function (e) {
          e.preventDefault();
          var text = $(this).text();
          if (!doNotUpdateLabel) {
            var label = '';
            if ($display.data('prefix')) {
              label += $display.data('prefix');
            }
            label += text;
            $display.text(label);
          }

         $that.removeClass('active');
          if (!$that.hasClass('ajax')) {
              var href = $(this).attr("href");
              window.location = href;
          }
          return true;
        });

        $that.on('mouseleave', function () {
          $that.removeClass('active');
        });
      });

      // Close any modal on escape key
      $(document).on('keyup', function (e) {
        if (e.keyCode === 27 && $('#modal-container').hasClass('active')) {
          $('#modal-close').trigger('click');
        }

        if (e.keyCode === 27 && $('#login-wrapper').data('showing')) {
          $('#login-wrapper').data('showing', false).hide();
        }
      });

      //activator class for "flip" panes
      $('.activator').on('click', function (e) {
        e.preventDefault();
        var $activate = $($(this).attr('data-activate')),
          $deactivate = $($(this).attr('data-deactivate'));

        if ($deactivate.length > 0) {
          $deactivate.find('.active').removeClass('active');
        }

        $activate.addClass('active');
      });

      // Put Filepicker away when it's closed by the user
      $(document).on('click', '#filepicker-close', function (e) {
        $('#filepicker-package').appendTo('body');
      });

      // Hide and show the scroll to top thing
      var scrollevent = $('html').hasClass('touch') ? 'touchmove scroll' : 'scroll';
      $(window).on(scrollevent, function () {
        if ($(window).scrollTop() > 200) {

          if (!SG.$elements.$scrollToTop.data('shown')) {
            SG.$elements.$scrollToTop.addClass('shown');
            SG.$elements.$scrollToTop.data('shown', true);
          }
        } else {
          if (SG.$elements.$scrollToTop.data('shown')) {
            SG.$elements.$scrollToTop.removeClass('shown');
            SG.$elements.$scrollToTop.data('shown', false);
          }
        }

        //floating menubar... we can feel free to adjust this later it's only on the home feed.
        //there are some things hard coded like width we can make dynamic
        if (typeof SG.$elements.floater === 'undefined') {//first run
          SG.$elements.floater = $('.floater');
          if (SG.$elements.floater.length) { SG.$elements.floater.data('topOffset', SG.$elements.floater.offset()['top']);}
        }

        var offset = 10; //the distance from the top of the screen.
        if (SG.$elements.floater.length > 0) { // is there a floater
          if ( SG.$elements.floater.data('topOffset') < $(window).scrollTop() + offset) { //is the window scrolled past it
            if ($(window).scrollTop() + offset + SG.$elements.floater.height() > $('footer').offset()['top'] ) { //is it before the footer
              SG.$elements.floater.css({
                position: 'absolute',
                'top': $('#content-column').height() - SG.$elements.floater.height(),
                width: '190px'
              });
            } else {
              SG.$elements.floater.css({
                position: 'fixed',
                top: offset + 'px',
                width: '190px'
              });
            }
          } else {
            SG.$elements.floater.css({
              position: 'static',
              top: 'auto'
            });
          }
        }
      });

      // Bind the scroll to top thing to scroll to top
      SG.$elements.$scrollToTop.on('click', function () {
        var time;
        time = ($('html').hasClass('touch')) ? 0 : 150;
        //animations are terrible on mobile.
        $("html, body").animate({ scrollTop: 0 }, time);
        SG.$elements.$scrollToTop.removeClass('shown');
        SG.$elements.$scrollToTop.data('shown', false);
      });

      // Bind something for flipping of vital stats
      $(document).on('click', '.unflip', function (e) {
        $(this).closest('.card-container').removeClass('flipped');
      });

      $('#content-container').on('click', '.vital-stats .more-link', function (e) {
        if ($(this).hasClass('show')) {
          $(this).removeClass('show');
          $('.more-content').removeClass('show');
        } else {
          $(this).addClass('show');
          $('.more-content').addClass('show');
        }
      });
      //end vital stats

      $(document).on('click', '#account-closing-reminder .close', function (e) {
        e.preventDefault();
        SG.apiNoPromptDelete({url: '/member/account/closing_reminder'});
        $('#account-closing-reminder').hide();
      });


      // Bind inline mention autocompletion for all contenteditable comments
      SG.mentionInit('.comment[contenteditable]');

      console.timeEnd('globalBind');
    },

    /**
     Makes form inputs prettier by adding a selected class to the labels
    */
    prettifyInputs: function() {
      $('input[type=radio]:checked').closest('label').addClass('selected');
      $('label input[type=radio]').on('change', function (e) {
        $('input[type=radio]:checked').closest('label').addClass('selected');
        $('input[type=radio]').not(':checked').closest('label').removeClass('selected');
      });
    },

    parseQueryString: function (name) {
      if (name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location.search)) {
        return decodeURIComponent(name[1]);
      }
    },

    bindCreateContent: function ($scope) {
      console.log('Binding create content for:', $scope);
      SG.mentionInit($scope.find('.blogTitle'));
      $scope.find('.tabbed').tabizer();
      $scope.find('.pick-video').on('click', function (e) {
        var view = new CreateVideoView({
          el: $('.video-form'),
          $filepickerContainer: $('.tab-content.video-input .filepicker-container')
        });
        view.launchFilepicker();
      });

      $('.create-content-modal-close').on('click', function (e) {
        SG.Post.modalClosedHandler($scope);
      });

      $('.create-content-modal-close').find('.activator').on('click', function (e) {
        e.preventDefault();
        var $activate = $($(this).attr('data-activate'));
        var $deactivate = $($(this).attr('data-deactivate'));

        if ($deactivate.length > 0) {
          $deactivate.find('.active').removeClass('active');
        }

        $activate.addClass('active');
      });

      $scope.find('.ios-picker').on('click', function (e) {
        console.log('IOS PICKER');
        $(this).closest('.tabs').find('.active').removeClass('active');
        $(this).toggleClass('active');
        e.stopPropagation();
        var $that = $(this);
      });

      $scope.find('.single-ios').on('click', function (e) {
        e.stopPropagation();
        var filepickerArgs = {
          services: [
            'COMPUTER'
          ],
          multiple: false
        };

        console.log($scope.find('.iospicker'));
        $scope.find('.ios-picker').removeClass('active');

        var $photoUpload = $scope.find('.photo-upload-start');
        $photoUpload.trigger('click');
        var blogAlbum = new Album();
        var creatingNewAlbum = blogAlbum.createNewAlbumSuite($photoUpload, filepickerArgs),
          $that = $(this);

        creatingNewAlbum.done(function (data) {
          location.reload();
        });
        $('body, html').scrollTop(0);
      });

      $scope.find('.multiple-ios').on('click', function (e) {
        var $photoUpload = $scope.find('.photo-upload-start');
        e.stopPropagation();
        var blogAlbum = new Album();
        var filepickerArgs = {
          multiple: true
        };

        $scope.find('.ios-picker').removeClass('active');

        console.log('New Album Suite...');
        var creatingNewAlbum = blogAlbum.createNewAlbumSuite($photoUpload, filepickerArgs);

        $photoUpload.trigger('click');
        creatingNewAlbum.done(function (data) {
          location.reload();
        });

        $('body, html').scrollTop(0);
      });
    },

    bindModal: function () {
      var $modalContainer = SG.$elements.$modalContainer;

      $('body').one('click', '#modal-container', function () {
        console.log('modal-container click');
        $modalContainer.data('deferred').resolve();
      });

      // Don't propagate unless it's the close within a modal
      $modalContainer.on('click', '*', function (e) {
        if (e.currentTarget.id === 'modal-close') {
          return true;
        } else {
          e.stopPropagation();
        }
      });

      $modalContainer.find('#modal-close').on('click', function (e) {
        console.log('modal close click...');
        e.preventDefault();
        $modalContainer.data('deferred').resolve();
      });
    },


    getRealScreenWidth: function() {
      var width = window.screen.availWidth;
      var pixelRatio = window.devicePixelRatio;
      if (pixelRatio > 1) {
        return width * pixelRatio;
      }
      return width;
    },

    getScreenType: function() {
      var width = SG.getRealScreenWidth();
      var pixelRatio = window.devicePixelRatio;
      var pixelForgiveness= 1.3;

      // Only change screen type for devices with
      // devicePixelRatio > 1 or 1 with a small/mobile screen size
      if (pixelRatio > 1 || (pixelRatio == 1 && (width <= (592 * pixelForgiveness)))) {
        if (width <= (320 * pixelForgiveness)) {
          return 'small';
        }
        else if (width <= (592 * pixelForgiveness)) {
          return 'mobile';
        }
        else if (width > (1216 * pixelForgiveness)) {
          return 'retina';
        }
      }
      return 'src';
    },

    /**
     * Determine the src attribute for an image based on screen
     * size and available image sizes contained in the data object.
     * data can have any of the following image URLs set as
     * properties: small, mobile, src, retina
     */
    getImageSRC: function (data) {
      var size = SG.getScreenType();
      if (size == 'small' && data.small) {
        return data.small;
      }
      if ((size == 'small' || size == 'mobile') && data.mobile) {
        return data.mobile;
      }
      if (size == 'retina' && data.retina) {
        return data.retina;
      }
      // Fallback to src or biggest available
      return data.src || data.retina || data.mobile || data.small;
    },

    initImages: function ($container) {
      $($container).find('figure.res-image').each(function () {
        if ($(this).find('img.added').length > 0) {
          return;
        }
        var $that = $(this);

        if ($('html').hasClass('no-touch')) {
          $(this).spinner(SG.spinnerSettings);
        }

        var data = $(this).find('noscript').data();
        var image_src = SG.getImageSRC(data);

        var $newImage = $('<img class="added" />');

        $newImage.load(function () {
          $(this).addClass('loaded');
          if ($('html').hasClass('no-touch')) {
            $that.clearSpinner();
          }
        });
        $newImage.attr('src', image_src);
        $newImage.appendTo($(this));
      });
    },

    lazyInitImages: function ($container) {
      $($container).find('figure.res-image').each(function () {
        if ($(this).find('img.added').length > 0) {
          return;
        }

        var data = $(this).find('noscript').data();
        var src = SG.getImageSRC(data);
        var $newImage = $('<img class="added loaded lazy" data-lazy="' + src + '"/>');
        $newImage.appendTo($(this));
      });
    },

    newInitImages: function ($container) {
      var $figures = $($container).find('figure.res-image'),
        loaded,
        $w = $(window),
        th = 0;

      $figures.each(function () {
        if ($(this).find('img.added').length > 0) {
          return;
        }

        var $that = $(this);

        $(this).one('unveil', function () {
          console.log('unveiling');

          if ($('html').hasClass('no-touch')) {
            $that.spinner(SG.spinnerSettings);
          }

          var data = $that.find('noscript').data();
          var image_src = SG.getImageSRC(data);
          console.log($that);

          var $newImage = $('<img class="added" />').appendTo($that);
          $newImage.attr('src', image_src);
          $newImage.on('load', function () {
            $that.addClass('loaded');
            if ($('html').hasClass('no-touch')) {
              $that.clearSpinner();
            }
          });
        });

        function unveil() {
          inview = $figures.filter(function () {
            var $e = $(this),
                wt = $w.scrollTop(),
                wb = wt + $w.height(),
                et = $e.offset().top,
                eb = et + $e.height();

            return eb >= wt - th && et <= wb + th;
          });
          console.log('unveil function');
          loaded = inview.trigger("unveil");

          $figures = $figures.not(loaded);
        }

        $(window).scroll(unveil);
        $(window).resize(unveil);

        unveil();
      });
    },

    getColumnWidth: function () {
      if ($(window).width() < 650) {
        columnWidth = SG.$elements.$articleFeed.width();
        SG.$elements.$articleFeed.find('article').width('100%');
      } else if ($(window).width() < 1025) {
        columnWidth = Math.round(SG.$elements.$articleFeed.width() / 2) - 7;
        SG.$elements.$articleFeed.find('article').width(Math.floor(columnWidth - 2));
        //$articleFeed.data('isotope').$allAtoms.width(columnWidth);
      } else if ($(window).width() < 1350) {
        columnWidth = Math.round(SG.$elements.$articleFeed.width() / 2) - 16;
        SG.$elements.$articleFeed.find('article').width(Math.floor(columnWidth));
      } else {
        columnWidth = Math.round(SG.$elements.$articleFeed.width() / 3) - 21;
        SG.$elements.$articleFeed.find('article').width(Math.floor(columnWidth));
      }
      console.log('Columnwidth = ', columnWidth);
      return columnWidth;
    },

    convertNavs: function () {
      if (! $('.mobile-nav:not(.advanced-search)').length > 0) {
        //we have several kinds of navs let's grab the anchor links in the differnt types then create the navs on each one
        var navs = [$('.horizontal-nav > ul:not(.filter_inline) > li:not(.dropdown) a'), $('.join-header a, .join-header .selected'), $('.floater a')];
        //above -- the first one is the main secondary navs (not dropdowns)
        // second is the join headers
        //third is homepage sidebar
        //below is any dropdowns within the secondary nav
        $('.horizontal-nav li.dropdown:not(.add-criteria,.desktop-only)').each(function () { //add the dropdown navs to our list
          navs.push($('a',this));
        });
        // Adding inline filters as their own single drop-down
        $('.horizontal-nav ul.filter_inline').each(function () {
            navs.push($('a', this));
        });
        $.each(navs, function () { //this section creates the navs
          if ($(this).length > 0) { //sometimes it all dropdowns so our object is empty
            var tinyNav = $('<select class="mobile-nav"></select>');

            $(this).each(function (index) {
              var el = $(this);
              var active = false;

              if (el.hasClass('active')||el.hasClass('selected')||location.pathname == el.attr("href")) { active = true; }

              if (!el.hasClass('do-not-include-mobile-nav')) {
                $("<option />", {
                  "value"   : el.attr("href"),
                  "text"    : el.data('mobile_text') ? el.data('mobile_text') : el.text(),
                  "selected": active
                }).appendTo(tinyNav);
              }
            });
            //insert nav
            if ($(this).eq(0).data('horizontal-nav')) {
              $($(this).eq(0).data('horizontal-nav')).eq(0).before(tinyNav);
            } else if ($(this).eq(0).parents('.horizontal-nav').length > 0) {
              $('.horizontal-nav').eq(0).before(tinyNav);
            } else if ($(this).eq(0).closest('.join-header').length > 0) {
              $('.join-header').eq(0).before(tinyNav);
            } else { //sidebar
              $('#content-column').prepend(tinyNav);
              tinyNav.addClass('sidebar-mobile');
            }

            var parentHorizNav = $(this).eq(0).parents('.horizontal-nav');
            //bind select
            tinyNav.on('change', function () {
              if (parentHorizNav.hasClass('ajax')) {
                var url = $(this).find("option:selected").val();
                $.each(parentHorizNav.find('.option'), function(index) {
                  if ($(this).attr('href') == url) {
                    $(this).trigger('click');
                  }
                });
              } else {
                window.location = $(this).find("option:selected").val();
              }
            });
            tinyNav.wrap( "<div class='mobile-nav-wrapper'></div>" );
          }
        });

      }
    },

    /**
     Hyperlinks URLs, email addresses and mentions in the DOM element.
    */
    createTextLinks: function ($wrapper) {
      $wrapper.each(function() {
          var content = $(this).html();
          if (!content) {
            return;
          }

          var autolinkedContent = Autolinker.link(content, {
            twitter: false,
            phone: false,
            mention: 'twitter',
            replaceFn: function(match) {
              if (match.getType() == 'mention') {
                return (
                  '<a href="/members/' +
                  match.getMention() + '/">@' + match.getMention() + '</a>'
                );
              }
            }
          });

          if (autolinkedContent != content) {
            $(this).html(autolinkedContent);
          }
      });
    },

    getFilepickerPolicy: function () {
      return SG.fp_policy_data;
    },

    /**
       Launches the Filepicker widget which allows the user
       to upload a file.

       @param {JQuery} $destination - JQuery selector for destination DOM element.
       @param {Object} args - Arguments to override defaults passed to Filepicker.
       @param {Function} fileUploaded - Callback when individual file is complete.
     */
    pickFile: function ($destination, args, fileUploaded, progressUpdated) {
      args = args || {};
      var combo = new $.Deferred;
      args.mobile = false;
      var ourCustomModal = false;
      console.group('Filepicker package engaged');

      if ($destination && $destination.length > 0) {
        $destination.addClass('active');
      }

      if (!$destination || $destination.width() < 700) { //anything less than 700 let's show the mobile UI
        args.container = 'filepicker-frame';
        ourCustomModal = true;
        SG.$elements.$modalContainer.appendTo('body');
        SG.$elements.$modalContainer.append(SG.$elements.$filepickerPackage);
        $destination = SG.$elements.$modalContainer.addClass('active');
      }

      if ($(window).width() < 700) {
        args.mobile = true;
        ourCustomModal = true;
      }

      var promise = new $.Deferred;

      try {
        //filepicker may have 404'd or 500'ddd...
        var combo = filePicker(SG.fp_policy_data);
      } catch(e) {
        console.log(e);
        promise.reject();
      }

      function filePicker(fp_policy_data) {
        var deferred = new $.Deferred;
        var store_options = {
          location: 's3',
          path: '/temp/',
          policy: fp_policy_data.policy,
          signature: fp_policy_data.signature
        },
        //some defaults
        picker_options = {
          backgroundUpload: true,
          hide: true,
          policy: fp_policy_data.policy,
          signature: fp_policy_data.signature,
          container: 'filepicker-frame', //forces 'inline'. our modal is inline as far as filepicker's API is concerned
          multiple: true,
          openTo : 'COMPUTER',
          maxFiles : 75,
          services: [
            'COMPUTER',
            'FACEBOOK',
            'INSTAGRAM',
            'FLICKR',
            'DROPBOX',
            'GMAIL',
            'URL'
          ]
        };
        var lang = SG.userLang();
        if (lang === 'es') {
          picker_options.language = lang;
        }
        //extend defaults
        $.extend(picker_options, args);

        //adjust iframe height depending on situation

        //583 is filepickers media query breakpoint.
        if ($destination && ($destination.width() < 583) && !args.mobile) {
          console.log('$destination', $destination);
          console.log('$destination.width()', $destination.width());

          if (picker_options.services.length === 1) { //special case for iOS
            $('#filepicker-frame').attr('height', '306px');
          } else {
            console.log('Resizing filepicker...');
            //for each service, add 51 pixels to height if the width is less than 500.
            $('#filepicker-frame').attr('height', 318 + picker_options.services.length * 51 + 'px');
          }
        }
        if (args.mobile) {
          //max 600 height.
          $('#filepicker-frame').attr('height', window.innerHeight > 600 ? 600 : window.innerHeight - 30);
        }

        if (!('extensions' in picker_options) && !('mimetype' in picker_options)) {
          // set default mimetype if extensions and mimetype are not specified
          picker_options['mimetype'] = 'image/*';
        }

        var filesUploading = {};
        var filesDone = {};
        var finishedFpFiles = [];

        var fpApi;

        var success = function (fpfiles) {
                deferred.resolve(fpfiles, fp_policy_data);
                console.groupEnd('Filepicker package engaged');
              };

        var error = function (fperror) {
          SG.userError('Error uploading files');
        };

        var progress = function (fpfile) {
          var COMPLETE = 100;

          filesUploading[fpfile.id] = fpfile;

          // Invoke the progressUpdated callback if it was passed to SG.pickFile.
          if (progressUpdated) {
            progressUpdated(fpfile);
          }

          if (fpfile['progress'] === COMPLETE) {
            filesDone[fpfile['id']] = true;
            finishedFpFiles.push(fpfile);

            // Invoke the fileUploaded callback if it was passed to SG.pickFile.
            if (fileUploaded) {
              fileUploaded(fpfile, fp_policy_data);
            }
          }

          if (Object.keys(filesDone).length === Object.keys(filesUploading).length) {
            success(finishedFpFiles);
          }
        };

        if (filepicker) {
          filepicker.setKey(fp_policy_data.api_key);
          fpApi = filepicker.pickAndStore(picker_options, store_options, success, error, progress);
        } else {
          deferred.reject();
        }
        return deferred;
      }

      combo.done(function (files, fp_policy_data) {
        files.sort(SG.naturalSorter);
        promise.resolve(files, fp_policy_data);
      });

      if (ourCustomModal) {
        var modal = SG.initModal($('#filepicker-package').removeClass('ready'), 'filepicker');
        modal.done(function () {
          //put filepicker back if user cancels modal.
          $('body').append($('#filepicker-package'));
          if (args['done']) {
            args['done']();
          }
        });
      } else {
        $destination.append($('#filepicker-package').removeClass('ready'));
      }

      promise.always(function () {
        if (ourCustomModal) {
          SG.modalClose();
        }
       $('body').append($('#filepicker-package'));
      });

      return promise;
    },
    isEmptyObject: function (obj) {
      var name;
      for (name in obj) {
        return false;
      }
      return true;
    },

    /**
     Comparator to sort using a natural sort algorithm.
     */
    naturalSorter: function (as, bs) {
      var a, b, a1, b1, i = 0, n, L,
      rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;

      as = as.filename;
      bs = bs.filename;

      if (as === bs)
        return 0;

      a = as.toLowerCase().match(rx);
      b = bs.toLowerCase().match(rx);
      L = a.length;

      while (i < L) {
        if ( ! b[i])
          return 1;

        a1 = a[i],
        b1 = b[i++];

        if (a1 !== b1) {
          n = a1-b1;

          if ( ! isNaN(n))
            return n;

          return a1 > b1? 1:-1;
        }
      }

      return b[i]? -1:0;
    },

    /**
     Shows a lightbox telling the user which of their photos failed to upload.

     @param {array} filenames that failed
     */
    showPhotoErrorLightbox: function (failedFilenames) {
      var source = $("#photo_upload_error_template").html();
      var template = Handlebars.compile(source);
      var templateData = {
        failedFilenames: failedFilenames
      };

      $.fancybox({
        type: 'inline',
        content: template(templateData),
        fitToView: false,
        closeClick: false,
        openEffect: 'none',
        closeEffect: 'none',
        closeBtn: false,
        width: 575,
        padding: 10,
        margin: 10
      });
    },

    /**
       Shows the spinner on the page body.
     */
    showSpinner: function() {
      $('body').spinner(SG.spinnerSettings);
    },

    /**
       Clears the spinner from the page body.
     */
    clearSpinner: function() {
      $('body').clearSpinner();
    },

    initModal: function ($contents, extraClasses, remove) {
      var $modalContainer = SG.$elements.$modalContainer;
      var modalDeferred = new $.Deferred();

      $modalContainer.data('deferred', modalDeferred); // Store deferred in node

      $('#scroll-to-top').addClass('hide-always');

      $modalContainer.addClass(extraClasses).addClass('active');
      $('body').css('overflow', 'hidden');

      if (extraClasses === 'filepicker' || extraClasses === 'cropper') {
        $modalContainer.find('.modal-inner').append($contents);
      } else {
        $modalContainer.append($contents);
      }

      modalDeferred.done(function () {
        $modalContainer.removeClass(extraClasses);
        SG.modalClose();
      });

      return modalDeferred;
    },

    modalClose: function (modalDeferred, $contents) {
      $('#scroll-to-top').removeClass('hide-always');
      var $that = $('#modal-close'),
          $siblings = $that.siblings();

      $('#modal-container').removeClass();
      $('body').css('overflow', 'inherit');
    },

    /**
       Generates a UUID number.
     */
    generateUUID: function() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d+Math.random()*16) % 16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ?  r : (r&0x3|0x8)).toString(16);
      });
      return uuid;
    },

    /**
       Gets the user id of the user who loaded the page.
     */
    getRequestUserId: function() {
      return parseInt($('body').attr('sg-user_id'), 10);
    },

    /**
       Gets a SG logo placeholder image.
     */
    getPlaceholder: function(geometry) {
      var placeholder;
      var attrName = 'sg-placeholder_' + geometry;
      if ($('body').attr(attrName) != undefined) {
        placeholder = $('body').attr(attrName);
      } else {
        placeholder = $('body').attr('sg-placeholder');
      }
      console.log('returning placeholder ' + placeholder);
      return placeholder;
    },

    userLang: function() {
      return $('body').attr('sg-lang') || 'en';
    },

    /**
       Determine if being shown on a phone.

       The 600 px matches the phone-only media query in the CSS.
    */
    isPhone: function() {
      return $(window).width() <= 600;
    },

    /**
       Determine if being shown on a touch device.
     */
    isTouch: function() {
      return $('html').hasClass('touch');
    },
  };////END SG object
  $.extend(SG, existing);
})();

// Evaluate an array of promises.
$.whenall = function (arr) {
  return $.when.apply($, arr);
};

$(function () {
  console.time('globalBind');
  SG.init(); // Initialize app
});

// Null out console commands for older browsers
if (typeof console.time === 'undefined') console.time = function () {};
if (typeof console.timeEnd === 'undefined') console.timeEnd = function () {};
if (typeof console.group === 'undefined') console.group = function () {};
if (typeof console.groupEnd === 'undefined') console.groupEnd = function () {};

function onGAPIClientLoad() {
  gapi.client.load('youtube', 'v3', onYouTubeApiLoad);
}

function onYouTubeApiLoad() {
  gapi.client.setApiKey('AIzaSyB66jZpkyCpAFn8fs2Da8l2stYovvdaVKA');
}
