/*global console */
/*global SG */

var photos = [];

(function() {
  var Album = function($element) {
    this.config = {
      album_id: null,
      photos: [], // {fpfile, caption, number, delete, isCover, album_photo_id}
      timer: null,
      $progressBar: $(),
      album_type: null,
      privacy: null,
      name: null,
      description: null,
      type: null,
      status: null,
      sg_album: null,
      photographer: '',
      photographer_url: '',
      contributors: '',
      created_by: null,
      upload_job_number: null,
      upload_job_numbers: [],
      fpfiles: [],
      photos_to_add: {},
      multiple: null,
      $form: $(),
      photos_added: {},
      redirect: true,
      admin_created: false,
      fpKeys: {},
      photos_to_replace: [],
      failed_photos: {},
      $photosContainer: null
    };

    if ($element) {
     $element.data('Album', this);
    }
  };

  Album.prototype = {
    init: function(config) {
      $.extend(this.config, config);
    },

    createNewAlbumSuite: function($node, filepickerArgs) {
      var isModal = $('#create-content-modal').length > 0;
      var $form = isModal ? $node.closest('.user-input').find($node.data('context')) : $($node.attr('data-context'));

      var masterDeferred = new $.Deferred,
        self = this,
        args = filepickerArgs || {},
        files,
        policy_data;

      this.config.$progressBar = $form.find('.bar');
      this.config.$form = $form;
      this.config.type = $node.attr('data-album-type');

      if (args.multiple == null) {
        this.config.multiple = args.multiple = !(this.config.type.indexOf('single') > -1);
      }

      var $filepicker_container = $node.closest('.photo-input').find('.filepicker-container');

      if ($filepicker_container.length === 0) {
        $filepicker_container = $('.' + $node.data('show')).find('.filepicker-container'); // If it's not in the node context, it's in near form.
      }

      var gettingFiles = SG.pickFile($filepicker_container, args);

      var populatingForm = gettingFiles.pipe(function(fpfiles, fpPolicyData) {
        var base = $node.data('album-type-base');

        if (self.config.type === 'variable') {
          if (fpfiles.length > 1) {
            self.config.multiple = true;
            self.config.type = base + '_multi_photo';
          } else {
            self.config.multiple = false;
            self.config.type = base + '_single_photo';
            self.config.$form = $('.single-album-form');
          }
        }

        var model = new AlbumModel({
          type: SG.reverseAlbumDictionary[self.config.type]
        });

        var collection = new PhotoCollection([], {
          album: self,
          albumType: self.config.type
        });

        var editAlbumView = new EditAlbumView({
          el: self.config.$form,
          album: self,
          filepickerArgs: filepickerArgs,
          multiple: self.config.multiple,
          type: self.config.type,
          masterDeferred: masterDeferred,
          model: model,
          collection: collection
        });

        return collection.addFPFiles(fpfiles, fpPolicyData);
      });

      populatingForm.done(function(data) {
        // Photos have been injected into form, let's bind the form;
        bindForm(self.config.$form);
      });

      function bindForm($form) {
        // Activate tags
        $form.find('.tag-collector').tagCollector();
        $form.find('.add-more').show();
      }

      masterDeferred.always(function() {
        $form.clearSpinner();
      });

      return masterDeferred;
    },

    editAlbumSuite: function($form) {
      var self = this;
      var masterDeferred = new $.Deferred();

      this.init({
        album_id: $form.attr('data-album-id'),
        type: $form.attr('data-album-type'),
        album_type: $form.attr('data-album-type'),
        $form: $form,
        multiple: true,
        $progressBar: $form.find('.bar')
      });

      var model = new AlbumModel({
        id: this.config.album_id,
        type: $form.attr('data-album-type')
      });

      var collection = new PhotoCollection([], {
        album: this,
        albumType: SG.albumDictionary[$form.attr('data-album-type')]
      });

      collection.loadFromAlbum(this.config.album_id);

      if (!isNaN(parseInt(this.config.type))) { // If the type is a number or a string of a number, let's use the dictionary.
        this.config.type = SG.albumDictionary[this.config.type];
      }

      $form.data('Album', this);

      $form.find('.photos-container').sortable('destroy').sortable();
      $form.find('.tag-collector').tagCollector();

      var editAlbumView = new EditAlbumView({
        el: this.config.$form,
        album: this,
        masterDeferred: masterDeferred,
        model: model,
        collection: collection
      });

      editAlbumView.renderPhotoList();

      masterDeferred.done(function() {
        // Redirecting except when the the album is for profile pictures
        if (self.config.type !== SG.ALBUM_TYPE_PROFILE && self.config.type !== SG.ALBUM_TYPE_APPLICANT) {
          var url_to_redirect_to = $form.find('#url_successful').val();
          if (!url_to_redirect_to) {
            return;
          }
          setTimeout(function() {
            window.location.replace(url_to_redirect_to);
          }, 1000);
        }
      });

      return masterDeferred;
    },

    removeFailedImages: function(imageArray) {
      for (var i = 0; i < imageArray.length; i++) {
        var key = this.getHashFromTempFilepicker(imageArray[i].fpfilekey);
        var photo_target = $('#fp-' + key);
        photo_target.remove();
      }
    },

    removeFailedImagesByUpJob: function(upjob) {
      console.log('removing failed images by upjob', upjob);
      var photo_target = $('[data-upload="' + upjob + '"]').remove();
    },

    getHashFromTempFilepicker: function(key) {
      // Filepicker keys could have spaces in them because they contain filenames.
      // We're using this hash for object keys
      return key.split("/")[1].split('_')[0];
    },

    processFailedPhotos: function (uploadJobNumber, failedPhotos) {
      var idx;
      var hash;

      for (idx in failedPhotos) {
        hash = this.getHashFromTempFilepicker(failedPhotos[idx].fpfilekey);
        this.config.failed_photos[hash] = this.config.photos_to_add[uploadJobNumber][hash];
      }
    },

    removeFailedPhotos: function () {
      this.config.failed_photos = {};
    }
  };

  if (typeof exports !== 'undefined') {
    exports.Album = Album;
  } else {
    window.Album = Album;
  }

  var albumGridView = new AlbumGridView({
    el: $('.album-view')
  });

})();

$(function() {
  var album = new Album();
  var $albumContainer = $('form.album-container');

  if ($albumContainer.length > 0) {
    album.editAlbumSuite($albumContainer);
  }
});
