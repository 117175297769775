//============================================ jquery functions here ==========================================
$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

//add resizeEnd event
(function ($, window) {
    var jqre = {};

    // Settings
    // eventName: the special event name.
    jqre.eventName = "resizeEnd";

    // Settings
    // delay: The numeric interval (in milliseconds)
    // at which the resizeEnd event polling loop executes.
    jqre.delay = 250;

    // Poll function
    // triggers the special event jqre.eventName when resize ends.
    // Executed every jqre.delay milliseconds while resizing.
    jqre.poll = function () {
        var elem = $(this),
            data = elem.data(jqre.eventName);

        // Clear the timer if we are still resizing
        // so that the delayed function is not exectued
        if (data.timeoutId) {
            window.clearTimeout(data.timeoutId);
        }

        // triggers the special event
        // after jqre.delay milliseconds of delay
        data.timeoutId = window.setTimeout(function () {
            elem.trigger(jqre.eventName);
        }, jqre.delay);
    };

    // Special Event definition
    $.event.special[ jqre.eventName ] = {

        // setup:
        // Called when an event handler function
        // for jqre.eventName is attached to an element
        setup: function () {
            var elem = $(this);
            elem.data(jqre.eventName, {});

            elem.on("resize", jqre.poll);
        },

        // teardown:
        // Called when the event handler function is unbound
        teardown: function () {
            var elem = $(this),
                data = elem.data(jqre.eventName);

            if (data.timeoutId) {
                window.clearTimeout(data.timeoutId);
            }

            elem.removeData(jqre.eventName);
        }
    };

    // Creates an alias function
    $.fn[ jqre.eventName ] = function (data, fn) {
        return arguments.length > 0 ?
            this.on(jqre.eventName, null, data, fn) :
            this.trigger(jqre.eventName);
    };

}(jQuery, this));

//scrollstart and scrollend events
(function(){

    var special = jQuery.event.special,
        uid1 = 'D' + (+new Date()),
        uid2 = 'D' + (+new Date() + 1);

    special.scrollstart = {
        setup: function() {

            var timer,
                handler =  function(evt) {

                    var _self = this,
                        _args = arguments;

                    if (timer) {
                        clearTimeout(timer);
                    } else {
                        evt.type = 'scrollstart';
                        jQuery.event.handle.apply(_self, _args);
                    }

                    timer = setTimeout( function(){
                        timer = null;
                    }, special.scrollstop.latency);

                };

            jQuery(this).bind('scroll', handler).data(uid1, handler);

        },
        teardown: function(){
            jQuery(this).unbind( 'scroll', jQuery(this).data(uid1) );
        }
    };

    special.scrollstop = {
        latency: 300,
        setup: function() {

            var timer,
                    handler = function(evt) {

                    var _self = this,
                        _args = arguments;

                    if (timer) {
                        clearTimeout(timer);
                    }

                    timer = setTimeout( function(){

                        timer = null;
                        evt.type = 'scrollstop';
                        jQuery.event.handle.apply(_self, _args);

                    }, special.scrollstop.latency);

                };

            jQuery(this).bind('scroll', handler).data(uid2, handler);

        },
        teardown: function() {
            jQuery(this).unbind( 'scroll', jQuery(this).data(uid2) );
        }
    };

})();


//when we append content, we may have to intialize images.
SG.oldAppend = $.fn.append;

$.fn.append = function() {
  var ret = SG.oldAppend.apply(this,arguments);
  var auto_init = $(this).data('auto-init') == undefined ? true : !!$(this).data('auto-init');

  if (!auto_init) {
    return;
  }

  try {
    if ($(this).find('figure.res-image').length > 0) {
      SG.debug('$().append adaptive images...');
      $('body').trigger('append', $(this));
    }

  } catch(e) {
    SG.debug(e);
  }

  try {

    $userEmbeds = $(this).find('iframe.blog-embed');
    if ($userEmbeds.length > 0) {
      console.log('Appending iframe...');
      window.yo = $userEmbeds;
      SG.$elements.$userVideos = SG.$elements.$userVideos.add($userEmbeds);
      $(window).trigger('resizeEnd');
    }
  } catch(e) {
    SG.debug(e);
  }
  // try {
  //   if ($(this).find('video').length > 0) {
  //     SG.debug('Appending videos and initializing/resizing.');
  //     SG.initVideos($(this).find('video'));
  //   }
  // } catch(e) {
  //   SG.debug(e);
  // }
  return ret;
};

$('body').on('append', function(e, $context) {
  SG.initImages($context);
});


//Modified masonry layout to accept gutters
$.Isotope.prototype._getMasonryGutterColumns = function() {
  var gutter = this.options.masonry && this.options.masonry.gutterWidth || 0;
  containerWidth = this.element.width();

  this.masonry.columnWidth = this.options.masonry && this.options.masonry.columnWidth ||
  // or use the size of the first item
  this.$filteredAtoms.outerWidth(true) ||
  // if there's no items, use size of container
  containerWidth;

  this.masonry.columnWidth += gutter;

  this.masonry.cols = Math.floor((containerWidth + gutter) / this.masonry.columnWidth);
  this.masonry.cols = Math.max(this.masonry.cols, 1);
};

$.Isotope.prototype._masonryReset = function() {
  // layout-specific props
  this.masonry = {};
  // FIXME shouldn't have to call this again
  this._getMasonryGutterColumns();
  var i = this.masonry.cols;
  this.masonry.colYs = [];
  while (i--) {
    this.masonry.colYs.push(0);
  }
};
$.Isotope.prototype._masonryResizeChanged = function() {
  var prevSegments = this.masonry.cols;
  // update cols/rows
  this._getMasonryGutterColumns();
  // return if updated cols/rows is not equal to previous
  return (this.masonry.cols !== prevSegments);
};
