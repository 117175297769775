/*global $ */

/**
   Provides a WYSIWYG Editor.
 */
var EditorView = Marionette.View.extend({
  filepickerArgs: {},
  autoSaveDraftInterval: 15000,

  events: {
  },

  /**
     Initialize the editor view.
   */
  initialize: function(options) {
    this.options = options;
    this.progressUpdates = {};
    this.collection = new PhotoCollection([], {albumType: this.options.albumType});
    this.lastNumberWhenFPLaunched = 0;
    this.redactorSettings = $.extend(true, {}, SG.redactorSettings);
    this.redactorSettings.buttonsCustom.filepicker.callback = _.bind(this.buttonAttach, this);
    this.collection.on('fpfiles_added', this.fpfilesAddedHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);
  },

  load: function() {
    SG.activateRedactor(this.$el, {settings: this.redactorSettings});
  },

  buttonAttach: function(obj, event, key) {
    this.launchFilepicker();
  },

  /**
   Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker: function() {
    var args = _.extend({}, this.filepickerArgs, this.options.filepicker);
    this.progressUpdates = {};
    this.lastNumberWhenFPLaunched = this.collection.models.length ? this.collection.models[this.collection.models.length-1].get('number') : 0;
    SG.pickFile(null, args, _.bind(this.fpFileDone, this), _.bind(this.progressUpdateHandler, this));
  },

  /**
     File done handler when Filepicker background upload is complete for a single file.
   */
  fpFileDone: function(fpfile, policyData) {
    this.collection.updateFinishedFPFile(fpfile, policyData);
  },

  /**
     Progress update handler when uploading the file to Filepicker.

     @param {Object} fpfile - File info returned from Filepicker
   */
  progressUpdateHandler: function(fpfile) {
    var COMPLETE = 100;
    var self = this;
    var raceConditionDelay;

    this.progressUpdates[fpfile['id']] = fpfile;

    if (this.isFirstProgressUpdate(fpfile)) {
      this.hideFilepicker();
      raceConditionDelay = Object.keys(this.progressUpdates).length * 50; // Initial progress events fire in parallel, give time for sorting when adding to collection.
      setTimeout(function() {
	if (!self.isFirstProgressUpdate(fpfile)) {
          return;
        }
        var photo = self.collection.addFPFile(fpfile, self.lastNumberWhenFPLaunched);
        self.insertPhoto(photo);
        //self.updateSubmitButtonStatus();
      }, raceConditionDelay);
    } else {
      this.collection.updateFPFile(fpfile);
    };

    if (this.checkUploadsComplete()) {
      //this.$el.find('.add-more').show();
      //this.$el.find('.button-wrapper').show();
    }

    this.updateProgress();
  },

  /**
     Checks to see if this is the first progress update for the fpfile.

     @param fpfile {Object} - The file details passed to the progress update callback by Filepicker.
   */
  isFirstProgressUpdate: function(fpfile) {
    return !this.collection.findByFPId(fpfile['id']);
  },

  /**
     Checks if all progress updates from Filepicker are reporting 100%.

     @returns {Boolean} Whether all files uploaded with Filepicker have reported a progress update with progress COMPLETE.
   */
  checkUploadsComplete: function() {
    var COMPLETE = 100;
    var key;
    var hasIncomplete = false;

    for (key in this.progressUpdates) {
      if (this.progressUpdates[key]['progress'] != COMPLETE && this.collection.checkStillHasFPFile(this.progressUpdates[key])) {
        hasIncomplete = true;
        break;
      }
    }

    return !hasIncomplete;
  },

  /**
     Updates the progress in the UI.
   */
  updateProgress: function() {
    this.setProgress(this.calculateProgress());
  },

  /**
     Calculates the overall progress across all files being uploaded.

     @returns {Number} average of individual progress for each photo.
   */
  calculateProgress: function() {
    var sum = 0;
    var count = 0;
    var key;

    if (!this.progressUpdates) {
      return 0;
    }

    for (key in this.progressUpdates) {
      sum += this.progressUpdates[key]['progress'];
      count += 1;
    }

    return Math.round(sum/count);
  },

  /**
     Sets the overall progress across all uploads.

     @param progress {Number} - the progress percentage as integer
   */
  setProgress: function(progress) {
    var COMPLETE = 100;
    var title = progress === COMPLETE ? '' : '' + progress + '%';
    $('.submit-album').attr('title', title); // TODO: move into this.$el
  },

  /**
     Hides the Filepicker widget.
   */
  hideFilepicker: function() {
    $('#modal-container').removeClass('active');
    $('body').css('overflow', 'inherit');
  },

  /**
     Event handler when files added to the collection from Filepicker have
     had their thumbnails created successfully by the server.
   */
  fpfilesSuccessfulHandler: function() {
  },

  /**
   Event handler when files added to the collection from Filepicker
   have been rejected by the server.
   */
  fpfilesFailedHandler: function() {
  },

  /**
     Sets the text in the editor area.
  */
  setText: function(text) {
    this.$el.redactor('set', text);
    this.$el.redactor('sync');
  },

  /**
     Gets the text from the editor area.
   */
  getText: function() {
    var text = '';

    try {
      this.$el.redactor('sync');
      text = this.$el.redactor('get');
    } catch (err) {
      text = this.$el.value;
    }

    return text;
  },

  /**
     Sets the focus to the editor area.
   */
  focus: function() {
    this.$el.parent().find('.redactor_editor').get(0).focus();
  },

  insertPhoto: function(photo) {
    var containerHtml = '<p class="editor-photo-wrapper" data-fp-id="' + photo.get('fpId') + '"></p>';
    this.$el.redactor('insertHtml', containerHtml);

    var view = new EditorPhotoView({
      el: $('.editor-photo-wrapper[data-fp-id="' + photo.get('fpId') + '"]'),
      model: photo,
      thumbnailGeometries: this.options.thumbnailGeometries
    });

    view.render();
  },

  /**
     Starts saving a draft of the blog post automatically.
   */
  startAutoSaving: function () {
    this.draftTimer = setInterval(_.bind(this.saveDraft, this), this.autoSaveDraftInterval);
  },

  /**
     Saves a copy of the current blog post draft to local storage.
   */
  saveDraft: function () {
    if (!localStorage) {
      return;
    }

    if (this.draftIsEmpty()) {
      this.deleteDraft();
      return;
    }

    localStorage.setItem(this.getSavedDraftKey(), this.serializeDraft());
  },

  /**
     Checks to see if the current draft in the blog post form is empty.
   */
  draftIsEmpty: function () {
    var hasTitle = !!this.$el.parent().parent().find('.title').val();
    var hasText = !!this.getText();
    return !(hasTitle || hasText);
  },

  /**
     Serializes the current draft in the editor.
   */
  serializeDraft: function () {
    var draft = {
      text: this.getText(),
      title: this.$el.parent().parent().find('.title').val(),
      collection: this.collection
    };

    return JSON.stringify(draft);
  },

  /**
     Stops the automatic saving of the blog post draft.
   */
  stopAutoSaving: function () {
    if (this.draftTimer) {
      clearInterval(this.draftTimer);
    }
  },
  /**
     Checks if there is an auto-saved blog post draft available.
   */
  savedDraftExists: function () {
    if (!localStorage) {
      return false;
    }

    return !!localStorage.getItem(this.getSavedDraftKey());
  },

  /**
     Restores the most recently saved draft to the editor.
   */
  restoreDraft: function () {
    var draft = JSON.parse(localStorage.getItem(this.getSavedDraftKey()));
    var $form = this.$el.closest('form');

    if ($form.attr('id') === 'group-input') { // TODO: this does not belong here.
      return;
    }

    $form.addClass('restore-option');
    $form.find('.blogTitle').val(draft.title);
    this.setText(draft.text);

    if (draft.collection) {
      this.collection.set(draft.collection);
      if (this.collection.hasUploadJobs()) {
	this.collection.startPollingUploadJobStatus();
      }
    }
  },

  /**
     Discards the current blog post draft that the user is editing.
   */
  discardDraft: function () {
    this.setText('');
    this.deleteDraft();
  },

  /**
     Gets the key to use when saving and retrieving the draft from local storage.
   */
  getSavedDraftKey: function() {
    return this.options.savedDraftPrefix + 'Draft';
  },

  /**
     Deletes the auto-saved draft of the post.
   */
  deleteDraft: function () {
    if (localStorage) {
      localStorage.removeItem(this.getSavedDraftKey());
    }
  },

  /**
     Removes photos from the collection that are not in DOM.

     Note: handles the case where the user has deleted an image
     using the WYSIWYG editor, and we need to remove it from the photo collection.
   */
  removePhotosNotInEditor: function() {
    var i;
    var fpId;
    var hash;
    var photosToRemove = [];
    var $redactor = this.$el.parent().find('.redactor_editor');

    for (i = 0; i < this.collection.models.length; i++) {
      // First check for image using fpId, used during uploading only.
      fpId = this.collection.models[i].get('fpId');

      if (fpId) {
        if ($redactor.find('[data-fp-id='+fpId+']').length) {
          continue;
        }
      }

      // Second check using the Filepicker hash, used once file has been received.
      hash = this.collection.models[i].getFilepickerHash();

      if (hash) {
        if ($redactor.find('[data-key="'+hash+'"]').length) {
          continue;
        }
      }

      // An IMG in the editor area was not found for this model, so add it to remove list.
      photosToRemove.push(this.collection.models[i]);
    }

    // Remove the photos we did not find in the editor area.
    for (i = 0; i < photosToRemove.length; i++) {
      this.collection.removeAndRenumber(photosToRemove[i]);
    }
  },

  /**
     Resets the editor after post is saved.
   */
  reset: function() {
    this.stopAutoSaving();
    this.deleteDraft();
    this.setText('');
  }
});
