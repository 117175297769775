var EditBlogPostView = EditRichTextContentView.extend({
  msgSaveSuccess: 'Post successfully saved.',

  /**
     Initializes the view.
   */
  initialize: function (options) {
    this.model = new BlogPost({});
    this.editor = new EditorView({
      el: this.$el.find('textarea'),
      albumType: 'blog',
      thumbnailGeometries: ['508'],
      savedDraftPrefix: 'post'
    });
    EditRichTextContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditBlogPostView);
  },

  /**
     Loads the edit post interface on the view blog post page.
  */
  loadEditInterface: function () {
    var editContent = $('.content-box-content:not(.list)').html();

    $('.status-update-content').show();
    $('.content-box-content:not(.list)').empty().append($('#editForm'));
    $('.commentForm').remove();

    this.model.set('id', this.$el.data('post-id'));
    if (this.$el.data('album-id')) {
      this.editor.collection.loadFromAlbum(this.$el.data('album-id'));
    }

    this.editor.load();
    this.editor.setText(editContent);
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel: function($form, model) {
    model.set('id', $form.data('post-id'));
    model.set('action', $form.attr('action'));
    model.set('title', $form.find('[name=title]').val());
    model.set('text', this.editor.getText());
    model.set('privacy', $form.find('[name=privacy]').val());
    model.set('user', SG.user.logged_in_user_id);
    model.set('post_type', null);
    model.set('photos', this.editor.collection.models);
  },

  /**
     Event handler when the view is destroyed.
   */
  onBeforeDestroy: function() {
    if (this.editor.collection.pollingUploadJobStatus) {
      this.editor.collection.stopPollingUploadJobStatus();
    }
    this.editor.stopAutoSaving();
    this.editor.saveDraft();
  }
});
