/**
   Allows the user to submit a request for a new group
   to be added to the site.
 */
var RequestGroupView = Backbone.View.extend({
  /**
     Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {
    multiple: false
  },

  /**
     Event handlers for the view.
   */
  events: {
    'click button#group-photo-upload': 'uploadPhoto',
    'click #group-request-submit': 'requestGroup'
  },

  /**
     Initialize the view.
   */
  initialize: function(options) {
    this.options = options;

    this.collection = new PhotoCollection([], {
      album: new Album(),
      albumType: 'group_profile'
    });

    this.album = new AlbumModel({
      type: 'group_profile',
      privacy: 'public',
      status: 'open',
      admin_created: false,
      photos: this.collection.models
    });

    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
    this.collection.on('loaded', this.fpfilesSuccessfulHandler, this);

    this.initializeUI();

    if (this.getAlbumIdFromForm()) {
      this.album.set('id', this.getAlbumIdFromForm());
      this.collection.loadFromAlbum(this.getAlbumIdFromForm());
    }
  },

  /**
     Initialize user interface for the view.
   */
  initializeUI: function() {
    var self = this;
 
    this.$el.find('.drop-select').dropSelect();

    $(window).keydown(function(event){
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });

    $('.tag-collector').tagCollector();
  },

  /**
     Gets the Album ID from the HTML form variables.
   */
  getAlbumIdFromForm: function() {
    return this.$el.find('input[name="album_id"]').val();
  },

  /**
     User clicks the upload photo button for the request group profile image.

     @param {object} e - JQuery event data.
   */
  uploadPhoto: function(e) {
    e.preventDefault();
    this.launchFilepicker();
  },

  /**
     Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker: function() {
    var args = _.extend({}, this.filepickerArgs, this.options.filepicker);
    SG.pickFile(null, args)
      .then(_.bind(this.collection.addFPFiles, this.collection));
  },

  /**
     Event handler triggered when new photos from Filepicker
     have been added to the collection.
   */
  fpfilesSuccessfulHandler: function() {
    this.renderPhotoList();
    this.hideUploadPhotoButton();
  },

  /**
     Renders the list of photos from the models in the collection.
   */
  renderPhotoList: function() {
    $photosContainer = this.$el.find('.column-1 .photos-container');
    $photosContainer.empty();

    for (var i = 0; i < this.collection.models.length; i++) {
      var img = $('<img id="group-photo-thumb" class="photo-thumb" />');
      img.attr('src', this.collection.models[i].get('url'));
      $photosContainer.append(img);
    }
  },

  /**
     Hides the upload photo button that appears below the profile group
     for the requested group.
   */
  hideUploadPhotoButton: function() {
    this.$el.find('.column-1 .call-to-action').hide();
  },

  /**
     Event handler when files added to the collection from Filepicker
     have been rejected by the server.
   */
  fpfilesFailedHandler: function() {
    SG.userError('Error saving profile photo');
  },

  /**
     User clicks the request group (save) button.

     Note: saves the album first, then submits the form.

     @param {object} e - JQuery event data.
   */
  requestGroup: function(e) {
    e.preventDefault();
    var saveAlbum = this.album.get('id') ? new $.Deferred().resolve() : this.album.save();
    saveAlbum.then(_.bind(this.submitForm, this));
  },

  /**
     Submits the actual HTML form for the group request.
   */
  submitForm: function() {
    this.$el.find('input[name="album_id"]').val(this.album.get('id'));
    $('#group-request-form').submit();
  }
});
