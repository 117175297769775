/* eslint-disable */
var EditAlbumView = Backbone.View.extend({
  /**
   Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {},

  /**
   Thumbnail geometry size to use for showing the drag & drop thumbnails, in order of preference.
   */
  thumbnailGeometries: ['230x230', '320', '508', 'original'],

  /**
   Event handlers for the view.
   */
  events: {
    'click .add-more': 'addMorePhotos',
    'click .icon-star': 'changeCoverPhoto',
    'click .delete': 'deletePhoto',
    'click .remove-all-photos': 'removeAllPhotos',
    'click .tag-toggle': 'toggleTags',
    'click #delete-album': 'deleteAlbum',
    'click .disable-hd-check .icon-checkmark': 'toggleHDOverride',
    'submit': 'submitForm',
    'sortupdate .photos-container': 'sortUpdateHandler'
  },

  /**
   Initialize the view.
   */
  initialize: function(options) {
    this.options = options;
    this.progressUpdates = {};
    this.lastNumberWhenFPLaunched = 0;

    this.photoListView = new PhotoListView({
      el: this.$el.find('.photos-container'),
      collection: this.collection,
      thumbnailGeometries: this.thumbnailGeometries
    });

    this.collection.isPhotoset = _.bind(this.model.isPhotoset, this.model); // Hack

    this.collection.on('fpfiles_added', this.fpfilesAddedHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);

    this.photoListView.render();
    this.updateSubmitButtonStatus();
  },

  /**
   Event handler triggered when new photos from File Picker
   have been added to the collection.
   */
  fpfilesAddedHandler: function() {
    this.$el.addClass('active');
    this.$el.closest('.photo-input').find('.active').removeClass('active');
    this.$el.siblings().removeClass('active');
  },

  /**
     Event handler when files added to the collection from Filepicker have
     had their thumbnails created successfully by the server.
   */
  fpfilesSuccessfulHandler: function() {
    this.updateSubmitButtonStatus();

    if (this.isReadyToSubmit()) {
      SG.userMessage('Photos ready to upload!');
    }
  },

  /**
   Event handler when files added to the collection from Filepicker
   have been rejected by the server.
   */
  fpfilesFailedHandler: function() {
    var i;
    var failedFilenames = [];

    for (i = 0; i < this.collection.failedPhotos.length; i++) {
      failedFilenames.push(this.collection.failedPhotos[i].get('filename'));
      this.collection.removeAndRenumber(this.collection.failedPhotos[i]);
    }

    SG.showPhotoErrorLightbox(failedFilenames);
  },

  /**
   Renders the list of photos from the models in the collection.
   */
  renderPhotoList: function() {
    this.photoListView.render();
  },

  /**
   User clicks button to add more photos.
   */
  addMorePhotos: function(e) {
    e.preventDefault();
    this.launchFilepicker();
  },

  /**
   Launch the Filepicker tool, and add the photos when done.
   */
  launchFilepicker: function() {
    var args = _.extend({}, this.filepickerArgs, this.options.filepicker);
    this.progressUpdates = {};
    this.lastNumberWhenFPLaunched = this.collection.models.length ? this.collection.models[this.collection.models.length-1].get('number') : 0;
    SG.pickFile(null, args, _.bind(this.fpFileDone, this), _.bind(this.progressUpdateHandler, this));
  },

  /**
     File done handler when Filepicker background upload is complete for a single file.
   */
  fpFileDone: function(fpfile, policyData) {
    this.collection.updateFinishedFPFile(fpfile, policyData);
  },

  /**
     Progress update handler when uploading the file to Filepicker.

     @param {Object} fpfile - File info returned from Filepicker
   */
  progressUpdateHandler: function(fpfile) {
    var COMPLETE = 100;
    var self = this;
    var raceConditionDelay;

    this.progressUpdates[fpfile['id']] = fpfile;

    if (this.isFirstProgressUpdate(fpfile)) {
      this.hideFilepicker();
      this.$el.find('.add-more').hide();
      raceConditionDelay = Object.keys(this.progressUpdates).length * 50; // Initial progress events fire in parallel, give time for sorting when adding to collection.
      setTimeout(function() {
	if (!self.isFirstProgressUpdate(fpfile)) {
	  return;
	}
        self.collection.addFPFile(fpfile, self.lastNumberWhenFPLaunched);
        self.updateSubmitButtonStatus();
      }, raceConditionDelay);
    } else {
      this.collection.updateFPFile(fpfile);
    };

    if (this.checkUploadsComplete()) {
      this.$el.find('.add-more').show();
      this.$el.find('.button-wrapper').show();
    }

    this.updateProgress();
  },

  /**
     Checks to see if this is the first progress update for the fpfile.

     @param fpfile {Object} - The file details passed to the progress update callback by Filepicker.
   */
  isFirstProgressUpdate: function(fpfile) {
    return !this.collection.findByFPId(fpfile['id']);
  },

  /**
     Checks if all progress updates from Filepicker are reporting 100%.

     @returns {Boolean} Whether all files uploaded with Filepicker have reported a progress update with progress COMPLETE.
   */
  checkUploadsComplete: function() {
    var COMPLETE = 100;
    var key;
    var hasIncomplete = false;

    for (key in this.progressUpdates) {
      if (this.progressUpdates[key]['progress'] != COMPLETE && this.collection.checkStillHasFPFile(this.progressUpdates[key])) {
        hasIncomplete = true;
        break;
      }
    }

    return !hasIncomplete;
  },

  /**
     Updates the progress in the UI.
   */
  updateProgress: function() {
    this.setProgress(this.calculateProgress());
  },

  /**
     Calculates the overall progress across all files being uploaded.

     @returns {Number} average of individual progress for each photo.
   */
  calculateProgress: function() {
    var sum = 0;
    var count = 0;
    var key;

    if (!this.progressUpdates) {
      return 0;
    }

    for (key in this.progressUpdates) {
      sum += this.progressUpdates[key]['progress'];
      count += 1;
    }

    return Math.round(sum/count);
  },

  /**
     Sets the overall progress across all uploads.

     @param progress {Number} - the progress percentage as integer
   */
  setProgress: function(progress) {
    var COMPLETE = 100;
    var title = progress === COMPLETE ? '' : '' + progress + '%';
    $('.submit-album').attr('title', title); // TODO: move into this.$el
  },

  /**
     Hides the Filepicker widget.
   */
  hideFilepicker: function() {
    $('#modal-container').removeClass('active');
    $('body').css('overflow', 'inherit');
  },

  /**
     Updates the enabled or disabled status of the submit button based
     on whether all photos in the collection have been processed successuflly.
   */
  updateSubmitButtonStatus: function() {
    $('.submit-album').prop('disabled', !this.isReadyToSubmit()); // TODO: use this.$el. #save-photoset is not in this.$el.
  },

  /**
     Checks to see if the uploaded photos are ready to submit.

     @returns {Boolean} Whether the user should be allowed to submit the form yet or not.
   */
  isReadyToSubmit: function() {
    return this.collection.checkAllPhotosComplete() && this.checkPhotoRequirements();
  },

  /**
     Adds the Filepicker files to the collection.

     Note: this is function is being used instead of the one on the collection
     directly in order to pass in the argument to not add photos to the server,
     due to the optimization in preprocess FP File, which adds the files
     individually to the server as they complete.
   */
  addFPFiles: function(fpfiles, policyData) {
    this.collection.addFPFiles(fpfiles, policyData, false);
  },

  /**
   User clicks the star icon to change the cover photo.
   */
  changeCoverPhoto: function(e) {
    e.preventDefault();
    e.stopPropagation(); // Stop create content dialog from closing TODO: Find a better way to do this.

    var cid = $(e.target).data('cid');

    for (var i = 0; i < this.collection.models.length; i++) {
      this.collection.models[i].set('isCover', this.collection.models[i].cid == cid);
    }
  },

  /**
   User clicks the trashcan icon.
  */
  deletePhoto: function(e) {
    var cid = $(e.target).parent().parent().find('.photo').data('cid');
    var model = this.collection.get({cid: cid});

    if (model.get('isCover') && this.collection.length > 1) {
      SG.userWarning('Please choose a new cover first');
      return;
    }

    var self = this;

    $(e.target).closest('.photo-container').addClass('hiding');

    setTimeout(function() {
      self.collection.removeAndRenumber(model);
      self.renderPhotoList();
      self.updateSubmitButtonStatus();
      self.updateProgress();
    }, 250);
  },

  /**
   User clicks the button to remove all photos from the album.
   */
  removeAllPhotos: function(e) {
    e.preventDefault();

    if (!confirm('Are you sure you want delete all the photos from this album?')) {
      return;
    }

    this.collection.reset();
  },

  /**
   User clicks the delete album button.
   */
  deleteAlbum: function(e) {
    e.preventDefault();

    if (!$('#delete').is(':checked')) {
      SG.userError('Please verify you are sure with the checkbox.');
      return;
    }

    this.model.destroy()
      .then(function() {
        window.location = $('body').attr('sg-user_absolute_url');
      });
  },

  /**
   User clicks the block tagging button.
   */
  toggleTags: function(e) {
    e.preventDefault();
    $(e.target).toggleClass('active');

    if ($(e.target).hasClass('active')) {
      this.$el.find('.tag-collector').hide();
    } else {
      this.$el.find('.tag-collector').show();
    }
  },

  toggleHDOverride: function(e) {
    e.preventDefault();
    this.$el.find('.disable-hd-check .icon-checkmark').toggleClass('override');
    this.updateSubmitButtonStatus();
  },

  /**
   User clicks the save button.
   */
  submitForm: function(e) {
    e.preventDefault();

    if (!this.collection.checkAllPhotosComplete()) {
      SG.userWarning('Your photos are still being processed. Please try again.');
      this.collection.restartPollingUploadJobStatus();
      this.trigger('failed');
      return;
    }

    if (this.collection.models.length < 1) {
      SG.userError('Please upload an image.');
      this.trigger('failed');
      return;
    }

    if (!this.checkPhotoRequirements()) {
      SG.userError('Uploaded photos do not meet requirements');
      this.trigger('failed');
      return;
    }

    this.$el.spinner(SG.spinnerSettings);

    this.syncFormToModel();
    this.options.album.init(this.model.attributes);
    this.saveForm();
  },

  /**
     Checks to see if the photos in the collection meet the requirements.

     Definition:
     -All photos must be HD if editing/uploading a photoset.

     @returns {Boolean} Whether the photos in the collection meet the requirements for this album type or not.
   */
  checkPhotoRequirements: function() {
    if (this.model.isPhotoset() && this.collection.hasNonHDPhotos()) {
       if (!this.collection.hadNonHDPhotosWhenLoaded && !this.checkStaffOverride()) {
         return false;
       }
     }
    return true;
  },

  /**
   Checks if the staff override option is selected.
   */
  checkStaffOverride: function() {
    return this.$el.find('.disable-hd-check .icon-checkmark').hasClass('override');
  },

  /**
   Syncs the current form values to the model.
   */
  syncFormToModel: function() {
    this.model.set({
      name: this.$el.find('input.title').val(),
      type: this.options.type || this.$el.data('album-type'),
      privacy: this.$el.find('.privacy').val() || 'public',
      description: this.$el.find('.description').val(),
      allow_tagging: !this.$el.find('.tag-toggle').hasClass('active'),
      tags: this.$el.find('input[name="tags"]').val(),
      photographer: this.$el.find('.photographer').val(),
      photographer_url: this.$el.find('.photographer_url').val(),
      photos: this.collection.models
    });
  },

  /**
   Saves the form itself - either creating or updating it.
   */
  saveForm: function() {
    this.model.save()
      .then(_.bind(this.saveAlbumSuccessHandler, this))
      .fail(_.bind(this.saveAlbumFailedHandler, this));
  },

  /**
   Save album success handler.
   */
  saveAlbumSuccessHandler: function(data) {
    this.saveTags(data);
  },

  /**
   Save album failed handler.
   */
  saveAlbumFailedHandler: function() {
    SG.userError('Error saving album.');
    this.trigger('failed');
  },

  /**
   Save the tags to the album.
   */
  saveTags: function(data) {
    var deferred;

    if (this.options.album.config.tags) {
      this.model.set('tags', this.options.album.config.tags);
      deferred = this.model.saveTags();
    } else {
      deferred = new $.Deferred();
      deferred.resolve(data);
    }

    deferred.done(_.bind(this.saveTagsSuccessHandler, this))
            .fail(_.bind(this.saveTagsFailedHandler, this));
  },

  /**
   Save tags success handler.
   */
  saveTagsSuccessHandler: function(data) {
    this.finishSuccessfully(data);
  },

  /**
   Save tags failed error handler.
   */
  saveTagsFailedHandler: function(data) {
    SG.userError('Failed to add tags to the album, please try again.');
    this.trigger('failed');
  },

  /**
   Event handler when user drag and drops a photo to new position.
   */
  sortUpdateHandler: function(event, ui) {
    var $photosContainer = this.$el.find('.photos-container');
    var self = this;

    $photosContainer.find('.photo-container:not(.hiding) a').each(function (idx, el) {
      self.collection.get({cid: el.getAttribute('data-cid')}).attributes['number'] = idx; // Silent set to prevent rendering
    });

    this.collection.sort();
  },

  /**
   Marks everything finished successfully.

   Note: code from other javascript files may be waiting for masterDeferred to resolve,
   such as the code that pops open the crop tool after saving a photoset.
   */
  finishSuccessfully: function(data) {
    this.$el.clearSpinner();
    this.options.album.config.$progressBar.parent().hide();
    SG.userMessage('Album successfully saved.');
    this.trigger('saved');
    this.options.masterDeferred.resolve(data);
  }
});
