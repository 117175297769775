/* exported EditPlainTextCommentView */
const EditPlainTextCommentView = EditContentView.extend({
  msgSaveSuccess: 'Comment successfully saved.',

  events: {
    'keypress .comment:not(.testimonial)': 'textChangedEvent',
    'paste .comment:not(.testimonial)': 'textChangedEvent',
  },

  /**
     Initializes the view.
   */
  initialize(options) {
    this.model = new Comment();
    EditContentView.prototype.initialize.apply(this, [options]);
    this.extendEvents(EditContentView);
  },

  /**
     Loads the edit thread interface on the edit thread pages.
  */
  loadEditInterface() {
    this.model.set('id', this.$el.data('post-id'));
    this.saveLoadedTime();
    this.activate();
  },

  /**
     Activates the create comment form.
   */
  activate() {
    SG.reLayout();
    this.$el.addClass('active');
  },

  /**
     Saves current timestamp when form was loaded.
   */
  saveLoadedTime() {
    const date = new Date();
    this.$el.data('loaded_time', date.getTime());
  },

  /**
     Event handler when the user types or pastes text into the editable area.

     @param {object} e - JQuery event data.
  */
  textChangedEvent(e) {
    if (SG.settings.isotope) {
      if ($(e.target).data('lastHeight') !== undefined && $(e.target).data('lastHeight') !== $(e.target).height()) {
        SG.reLayout();
      }
      if (e.type === 'paste') {
        setTimeout(SG.reLayout, 10); // Needs a timer to fire after the text is in the box.
      }
      $(e.target).data('lastHeight', $(e.target).height());
    }

    if (e.which === 13) {
      e.preventDefault();
      this.$el.find('button[type="submit"]').trigger('click');
    }
  },

  /**
     Validate the form.

     @returns {Boolean} Whether the form passes validation.
   */
  validate() {
    if (!this.isStillGracePeriod()) {
      SG.userError('Grace period is over');
      return false;
    }
    return true;
  },

  /**
     Checks whether it is still within the grace period for the comment.
  */
  isStillGracePeriod() {
    const $gracePeriodRemaining = this.$el.find('[name=grace_period_remaining]');
    const isGracePeriodRemainingSpecified = $gracePeriodRemaining.length;

    if (!isGracePeriodRemainingSpecified) {
      return true;
    }

    const date = new Date();
    const currentTime = date.getTime();
    const elapsed = (currentTime - this.$el.data('loaded_time')) / 1000;
    const gracePeriodRemaining = parseInt($gracePeriodRemaining.val(), 10);

    return elapsed < gracePeriodRemaining;
  },

  /**
     Syncs the current form values to the model.
   */
  syncFormToModel($form, model) {
    model.set('action', $form.attr('action'));
    if ($form.find('[name="comment_id"]').val()) {
      model.set('id', $form.find('[name="comment_id"]').val());
    }
    model.set('text', $.trim(this.$el.find('[contenteditable]').text()));
    model.set('noteditable', $form.find('[name="noteditable"]').val());
  },

  /**
     Refreshes the page after successful save.
   */
  refreshPage(response) {
    const commentId = response.id;
    const $commentText = $(`div.comment-text[data-comment-id=${commentId}]`);
    const $editCommentContainer = $(`.edit-comment-container[data-comment-id=${commentId}]`);
    const $flexWrapper = $(`.flex-wrapper[data-comment-id=${commentId}]`);

    SG.clearSpinner();
    $commentText.empty();
    $commentText.html(response.text);
    $editCommentContainer.empty();
    $editCommentContainer.hide();
    $flexWrapper.show();
  },
});
