var SG = SG || {};

SG.activateRedactor = function($node, options) {
  var $form = $node.closest('form');
  if ($form.width() < 500) $form.addClass('skinny');
  if ($form.hasClass('commentForm')) {
    $form.addClass('active');
    $form.find('[type="submit"]').text('submit');
  }

  var settings = options && options['settings'] ? options['settings'] : SG.redactorSettings;

  if ($node.hasClass('comment') && SG.isotope) {
    $node.addClass();
    var $activeRedactor = $node.redactor(settings);
    SG.reLayout();
  } else {
    var $activeRedactor = $node.redactor(settings);
  }

  var redactorObject = $activeRedactor.data('redactor');

  // Focus, if we're a comment.
  if ($activeRedactor.hasClass('comment')) {
    $activeRedactor.redactor('focus');
  }

  // Start with a paragraph.
  $activeRedactor.redactor('execCommand', 'insertHtml', '<p></p>');
  SG.$activeRedactor = $activeRedactor;

  // Initialize @mentions and deactivate the dialog if the modal is clicked
  var textComplete = SG.mentionInit(SG.findPrev('.redactor_editor', $node)).data('textComplete');
  $('#blog-post-create').on('click', $.proxy(textComplete.onClickDocument, textComplete));
};

// Custom language strings
$.Redactor.opts.langs['en']['video_html_code'] = 'video url or embed code';
$.Redactor.opts.langs['en']['video'] = 'video';

SG.redactorSettings = {
  focus : false,
  emptyHtml: '<p> </p>', // Redactor default is '<p>&#x200b;</p>', but this causes focus problems in Chrome.
  convertLinks: false, // There is custom SG logic to do this
  deniedTags: ['html', 'head', 'link', 'body', 'meta', 'script', 'style', 'applet', 'span'],
  buttons: ['bold', 'italic','teaser', 'quoteToggle', 'link_toggle', 'video',
    'html', 'filepicker' ],
  convertDivs : false,
  observeImages : false,
  buttonsCustom: {
    filepicker: {
      title: 'attach',
      callback: function() {},
    },
    link_toggle : {
      title : 'link',
      callback : function() {

        var modal_link = String()
          + '<section>'
          + '<form id="redactorInsertLinkForm" method="post" action="">'

          + '<input type="hidden" id="redactor_tab_selected" value="1" />'
          + '<div class="redactor_tab" id="redactor_tab1">'
          + '<label>Copy and Paste URL</label>'
          + '<input type="text" id="redactor_link_url" class="redactor_input"  />'
          + '<label>' + this.opts.curLang.text + '</label>'
          + '<input type="text" class="redactor_input redactor_link_text" id="redactor_link_url_text" />'
          + '</div>'

          + '</form>'
          + '</section>'
          + '<footer>'
          + '<button type="button" class="redactor_modal_btn" id="redactor_insert_link_btn" value="' + this.opts.curLang.insert + '">'
          + 'Insert Link'
          + '</button>'
          + '</footer>';

        if ($(this.getElement()).closest('a').length > 0) {
          this.execCommand('unlink');
          return;
        }

        this.selectionSave();

        var callback = $.proxy(function() {
          this.insert_link_node = false;

          var sel = this.getSelection();
          var url = '', text = '', target = '';
          var par = sel.anchorNode.parentNode;
          var elem = this.getParent();

          if (elem && elem.tagName === 'A') {
            url = elem.href;
            text = $(elem).text();
            target = elem.target;

            if (sel.toString() === '') {
              this.insert_link_node = elem;
            }
          } else {
            text = sel.toString();
          }

          $('.redactor_link_text').val(text);

          var thref = self.location.href.replace(/\/$/i, '');
          var turl = url.replace(thref, '');
          var tabs = $('#redactor_tabs').find('a');

          if (this.opts.linkEmail === false) tabs.eq(1).remove();
          if (this.opts.linkAnchor === false) tabs.eq(2).remove();
          if (this.opts.linkEmail === false && this.opts.linkAnchor === false) {
            $('#redactor_tabs').remove();
            $('#redactor_link_url').val(turl);
          } else {
            if (url.search('mailto:') === 0) {
              this.modalSetTab.call(this, 2);
              $('#redactor_tab_selected').val(2);
              $('#redactor_link_mailto').val(url.replace('mailto:', ''));
            } else if (turl.search(/^#/gi) === 0) {
              this.modalSetTab.call(this, 3);
              $('#redactor_tab_selected').val(3);
              $('#redactor_link_anchor').val(turl.replace(/^#/gi, '' ));
            } else {
              $('#redactor_link_url').val(turl);
            }
          }

          if (target === '_blank') {
            $('#redactor_link_blank').prop('checked', true);
          }

          $('#redactor_insert_link_btn').click($.proxy(this.linkProcess, this));

          setTimeout(function() {
            $('#redactor_link_url').focus();
          }, 200);

        }, this);

        this.modalInit(this.opts.curLang.link, modal_link, $(window).width(), callback);
      }

    },//link toggle
    teaser: {
      title: 'spoiler',
      callback: function(obj, event, key) {
        var newHtml,
          obj = this,
          html = this.getSelectionHtml(),
          node = html,
          isInTeaser = $(this.getBlocks()).closest('.teaser').length > 0;

        if (isInTeaser) {
          console.group();
          console.log('inserting', html);
          console.log('this.getBlock()', this.getBlock());
          console.log('this.Nodes()', this.getNodes());
          console.log('this.blocks()', this.getBlocks());
          console.groupEnd();

          $(this.getBlocks()).closest('.teaser').replaceWith($(this.getBlocks()).closest('.teaser').html());
        } else if (!html) {
          SG.userError('Please select the content you want made into a spoiler.');
          return;
        } else {
          console.group();
          console.log('inserting', html);
          console.log('this.getBlock()', this.getBlock());
          console.log('this.Nodes()', this.getNodes());
          console.log('this.blocks()', this.getBlocks());
          console.groupEnd();
          obj.insertHtmlAdvanced('<div class="teaser"><p>' + html + '</p></div><br /><p></p>');
          console.log('formating block p');
        }

        obj.sync();
      }

    },//teaser
    quoteToggle : {
      title: 'quote',
      callback: function(obj, event, key) {
        console.log(this);
        this.formatQuote();

        if ($(this.getCurrent()).closest('blockquote').length > 0) {
          this.$toolbar.find('.redactor_btn_quoteToggle').addClass('redactor_act');
        } else {
          this.$toolbar.find('.redactor_btn_quoteToggle').removeClass('redactor_act');
        }
      }
    }//quoteToggle
  }//buttons custom
};

SG.redactorHelper = {
  getEmbedHTML: function(obj, url, callback) {
    supported_urls = {
      '.*youtu(\.be|be\.com).*': SG.redactorHelper.getYouTubeEmbedCode,
      '.*vimeo.*': SG.redactorHelper.getVimeoEmbedCode,
      '(?!staging)*(staging.suicidegirls.com|suicidegirls.com|127.0.0.1.*)\/videos\/([0-9]*)\/.*': SG.redactorHelper.getSGEmbedCode
    };

    function successCallback(val) {
      return (callback(obj, val));
    }

    function errorCallback(val) {
      console.error(val);
      return (SG.userError("Could not find the video, please check the url or use an embed code"));
    }

    for (var re_str in supported_urls) {
      var re = new RegExp(re_str);
      if (url.match(re)) {
        return (supported_urls[re_str](url, successCallback, errorCallback));
      }
    }
  },

  getSGEmbedCode: function(url, successCallback, errorCallback) {
    var regExp = /(?!staging)*(staging.suicidegirls.com|suicidegirls.com|127.0.0.1.*)\/videos\/([0-9]*)\/.*/;
    var match = url.match(regExp);

    try {
      var embedCode = '<iframe class="sg-embed" src="//' + match[1] + '/videos/embed/' + match[2] + '/" frameborder="0" allowfullscreen></iframe>';
      return (successCallback(embedCode));
    } catch (err) {
      return (errorCallback("SG: invalid url"));
    }
  },

  getYouTubeEmbedCode: function(url, successCallback, errorCallback) {
    var url_id = SG.redactorHelper.getYoutubeID(url);

    if (url_id === false) {
      return (errorCallback("YouTube invalid ID"));
    }

    var request = gapi.client.youtube.videos.list({
      fields: 'items',
      part: 'player',
      id: url_id
    });

    request.execute(function(response) {
      if (response === undefined) {
        return (errorCallback("YouTube error"));
      } else {
        try {
          var embedCode = response.result.items[0].player.embedHtml;
          embedCode = SG.redactorHelper.cleanEmbedCode(embedCode);
        } catch (err) {
          return (errorCallback("YouTube: " + err));
        }
        return (successCallback(embedCode));
      }
    });
  },

  getVimeoEmbedCode: function(url, successCallback, errorCallback) {
    var vimeoURL = '//vimeo.com/api/oembed.json?url=' + encodeURIComponent(url);

    $.ajax(vimeoURL)
      .done(function(video) {
        embedCode = SG.redactorHelper.cleanEmbedCode(video.html);
        successCallback(embedCode);
      })
      .fail(function() {
        errorCallback('Vimeo error');
      });
  },

  cleanEmbedCode: function(embedCode) {
    embedCode = embedCode.replace('http:', '');
    embedCode = embedCode.replace(/width=[\"\'][0-9]+[\"\']/i,'');
    embedCode = embedCode.replace(/height=[\"\'][0-9]+[\"\']/i,'');
    return embedCode;
  },

  getYoutubeID: function(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[7].length == 11) {
      return match[7];
    } else {
      return false;
    }
  },

  finishVideoInsert: function(obj, data) {
    $data = $(data).addClass('blog-embed');
    data = $data[0].outerHTML;
    obj.selectionRestore();
    var current = obj.getBlock() || obj.getCurrent();

    if (current) {
      $(current).after(data);
      obj.sync();
    } else {
      obj.insertHtml(data);
    }
    SG.$elements.$userVideos = SG.$elements.$userVideos.add(SG.$activeRedactor.data('redactor').$editor.find('iframe'));
    $(window).trigger('resizeEnd');

    obj.modalClose();
  }
};

$.Redactor.prototype.videoInsert = function() {
  var data = $('#redactor_insert_video_area').val();
  data = this.cleanStripTags(data);

  if (data.match('<iframe')) {
    SG.redactorHelper.finishVideoInsert(this, data);
  } else {
    SG.redactorHelper.getEmbedHTML(this, data, SG.redactorHelper.finishVideoInsert);
  }
};
