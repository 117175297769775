var GroupAdminView = Backbone.View.extend({
  /**
     Event handlers for the view.
   */
  events: {
    'click .pending-member-accept': 'acceptPendingMember',
    'click .pending-member-decline': 'declinePendingMember'
  },

  /**
     Initialize the view.
   */
  initialize: function(options) {
    this.options = options;
    this.initializeUI();
  },

  /**
     Initialize user interface for the view.
   */
  initializeUI: function() {
    this.$el.find('.tag-collector').tagCollector();
    this.$el.find('.tag-collector.moderators').parent().on('click', '.tag', _.bind(this.bindModeratorsTags, this));
    this.bindModeratorsTags();
  },

  /**
     Bind moderators tags.

     Note: There must always be at least one moderator. this is weird because you have to
     override the onclick of the widget -- we catch it before it bubbles up, but we have
     to rebind the events with new items added.
   */
  bindModeratorsTags: function() {
    this.$el.find('.tag-collector.moderators').parent().find('.tag').on('click', function(e) {
      if ($(e.target).parent().find('.tag').length < 2) {
        e.stopImmediatePropagation();
        e.preventDefault();
        SG.userError('There must be at least one moderator at all times.');
      }
    });
  },

  /**
     User clicks button to accept a pending member into the group.

     @param {object} e - JQuery event data.
   */
  acceptPendingMember: function(e) {
    e.preventDefault();
    var member_id = $(e.target).closest('.pending-member-container').find('input').val();
    console.log("accept pending member"+member_id);
    var acceptingMember = SG.apiPost({
      url: "/api/group/accept_pending_member/",
      data: JSON.stringify({member_id: member_id}),
      contentType: 'application/json; charset=utf-8'
    });
    $(e.target).closest('.pending-member-container').remove();
  },

  /**
     User clicks button to decline a pending member from entry into the group.

     @param {object} e - JQuery event data.
   */
  declinePendingMember: function(e) {
    e.preventDefault();
    var member_id = $(e.target).closest('.pending-member-container').find('input').val();
    console.log("decline pending member " + member_id);
    var decliningMember = SG.apiPost({
      url: '/api/group/decline_pending_member/',
      data: JSON.stringify({member_id: member_id}),
      contentType: 'application/json; charset=utf-8'
    });
    $(e.target).closest('.pending-member-container').remove();
  }
});
