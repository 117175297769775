var ApplicantSubmitPhotosetView = SubmitPhotosetView.extend({
  /**
   User clicks the submit photoset button.

   Note: this overrides the parent method to remove validation
   for contributors and model name.
   */
  submitPhotoset: function(e) {
    e.preventDefault();

    var self = this;

    this.savePhotoRelease(e)
      .then(function() {
	self.submitForm(e);
      });
  }
});
