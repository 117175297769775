/**
   Allows the user to upload a single document and save it to the server.

   The document uploaded with this view would be a photo or scan of physical
   document, in a standard image format. The uploaded image is saved to a
   new album on the server.
*/

var UploadDocumentView = Backbone.View.extend({
  /**
     Arguments passed to SG.pickFile when opening Filepicker.
   */
  filepickerArgs: {
    multiple: false
  },

  /**
     Event handlers for the view.
   */
  events: {
    'click .pick-file': 'pickFile'
  },

  /**
     Initialize the view.
   */
  initialize: function(options) {
    this.options = options;

    this.collection = new PhotoCollection([], {
      album: new Album(),
      albumType: options.albumType
    });

    this.model = new AlbumModel({
      type: options.albumType,
      privacy: 'private',
      status: 'open',
      admin_created: false,
      photos: this.collection.models
    });

    this.collection.on('fpfiles_successful', this.fpfilesSuccessfulHandler, this);
    this.collection.on('fpfiles_failed', this.fpfilesFailedHandler, this);
  },

  /**
     User clicks button to upload a document.
   */
  pickFile: function(e) {
    e.preventDefault();
    this.launchFilepicker();
  },

  /**
     Launch the Filepicker tool, and add the photos when done.
  */
  launchFilepicker: function() {
    var args = _.extend({}, this.filepickerArgs, this.options.filepicker);
    SG.pickFile(null, args)
      .then(_.bind(this.collection.addFPFiles, this.collection));
  },

  /**
     Event handler triggered when new photos from File Picker
     have been added to the collection.
   */
  fpfilesSuccessfulHandler: function() {
    this.model.save()
      .then(_.bind(this.saveAlbumSuccessHandler, this))
      .fail(_.bind(this.saveAlbumFailedHandler, this));
  },

  /**
     Save abum success handler.
   */
  saveAlbumSuccessHandler: function(data) {
    this.renderPhotoList();
    this.hidePickFileButton();
  },

  /**
   Renders the list of photos from the models in the collection.
   */
  renderPhotoList: function() {
    $photosContainer = this.$el.find('.filepicker-done');
    $photosContainer.empty();

    for (var i = 0; i < this.collection.models.length; i++) {
      var img = $('<img width="110" height="56" />');
      img.attr('src', this.collection.models[i].get('fpfile').url);
      $photosContainer.append(img);
    }
  },

  /**
     Hides the button that the user clicks to upload a file.
   */
  hidePickFileButton: function() {
    this.$el.find('.pick-file').hide();
  },

  /**
     Save album failed handler.
   */
  saveAlbumFailedHandler: function() {
    SG.userError('Error saving album.');
    this.trigger('failed');
  },

  /**
   Event handler when files added to the collection from Filepicker
   have been rejected by the server.
   */
  fpfilesFailedHandler: function() {
    SG.userError('Error saving document');
  }
});
