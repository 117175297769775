var AlbumModel = Backbone.Model.extend({
  /**
   Sync the model data to the server.

   Note: overrides Backbone's default sync method.
   */
  sync: function(method, model, options) {
    var request;

    switch(method) {
      case 'create':
        request = this.createAlbum();
        break;
      case 'update':
        request = this.editAlbum();
        break;
      case 'delete':
        request = this.deleteAlbum();
        break;
      case 'read':
      default:
        request = $.Deferred();
        request.reject();
    }

    request.then(_.bind(this.loadResponseValues, this));

    return request;
  },

  /**
   Send a create album request to the server.
   */
  createAlbum: function() {
    var data = this.assembleFormData();

    return $.ajax({
      type: "POST",
      url: '/api/create_album/',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8'
    });
  },

  /**
   Send an edit album request to the server.
   */
  editAlbum: function() {
    var data = this.assembleFormData();

    return $.ajax({
      type: 'POST',
      url: '/api/edit_album/',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8'
    });
  },

  /**
   Assemble the form data into the object sent to server.
   */
  assembleFormData: function() {
    var data = {
      id: this.attributes.id,
      name: this.attributes.name,
      description: this.attributes.description,
      privacy: this.attributes.privacy,
      type_of_album: this.attributes.type,
      allow_tagging: this.attributes.allow_tagging,
      tags: this.attributes.tags,
      photographer: this.attributes.photographer,
      photographer_url: this.attributes.photographer_url,
      photos: [],

      status: this.attributes.status,
      contributors: this.attributes.contributors,
      photo_release_id: this.attributes.photo_release_id,
      created_by: this.attributes.created_by,
      admin_created: this.attributes.admin_created
    };
    if (this.attributes.sg_album) {
      data.sg_album = this.attributes.sg_album;
    }

    if (!isNaN(parseInt(this.attributes.type))) { //if the type is a number or a string of a number, let's use the dictionary.
      data.type_of_album = SG.albumDictionary[this.attributes.type];
    }

    var self = this;

    _.each(this.attributes.photos, function(photo) {
      data['photos'].push({
        id: photo.get('id'),
        album_photo_id: photo.get('album_photo_id'),
        caption: photo.get('caption'),
        number: photo.get('number'),
        isCover: photo.get('isCover'),
        delete: photo.get('delete'),
        fpfilekey: photo.get('key')
      });
    });

    return data;
  },

  /**
   Load server sync response values back into the model.
   */
  loadResponseValues: function(data) {
    if (!data.id) {
      return;
    }

    this.set({id: data.id});
  },

  /**
   Save the album tags to the server.
   */
  saveTags: function() {
    return $.ajax({
      type: "POST",
      url: '/api/add_tags_to_album/',
      dataType: "json",
      data: JSON.stringify({
        album_id: this.get('id'),
        tags: this.get('tags')
      }),
      contentType: 'application/json; charset=utf-8'
    });
  },

  /**
   Delete the album on the server.
   */
  deleteAlbum: function() {
    var data = {
      album_id: this.get('id')
    };

    return $.ajax({
      type: "POST",
      url: '/api/delete_album/',
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8'
    });
  },

  /**
   Check whether this album is a photoset.
   */
  isPhotoset: function() {
    return this.attributes.type == SG.ALBUM_TYPE_SG || this.attributes.type == SG.ALBUM_TYPE_HOPEFUL;
  }
});
